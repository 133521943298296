import { AxiosResponse } from 'axios'
import { GetResponse } from '../models/Response'
import { getInstance } from './instance'
import { Timezone } from '../models/Timezone'
import { OptionID } from '../models/Option'

export const Timezones = async () => {
  try {
    const response: AxiosResponse<GetResponse<OptionID<string>[]>> =
      await getInstance().get('/v2/adv/static/timezones')
    return response.data.responseObject.map(one => ({
      id: one.id,
      label: one.label,
      value: one.name,
    })) as Timezone[]
  } catch (e) {
    return []
  }
}
