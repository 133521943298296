import * as React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  LoadingButton,
  Checkbox,
  FormControlLabel,
} from '@applift/factor'

import { RUM_ACTION_ATTRIBUTE } from '../../../services/RUMLogger/constants'
import styles from './styles.module.scss'

interface SpendRatioSwitchConfirmationDialogProps {
  closeDialog: () => void
  onConfirmation: (doNotAskSelected: boolean) => void
  dimensionName: string
}

export const SpendRatioSwitchConfirmationDialog = (
  props: SpendRatioSwitchConfirmationDialogProps
) => {
  const { closeDialog, onConfirmation, dimensionName } = props

  const [doNotAskSelected, toggleDoNotAskSelection] = React.useState(false)

  return (
    <Dialog open onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle onClose={closeDialog}>
        <Typography weight="demi">
          Switch to Strict Spend Ratio Method
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          Upon switching spend ratio method to STRICT, the spend ratio and
          priorities will have the following values:
        </Box>
        <Box className={styles.info} sx={{ ml: 16 }}>
          {` Spend Ratio values will reflect the actual spent % of ${dimensionName}.`}
        </Box>
        <Box className={styles.info} sx={{ ml: 16 }}>
          Priorities will become ‘-’ and are not applicable in STRICT method.
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'between' }}>
        <Box>
          <FormControlLabel
            onChange={() => toggleDoNotAskSelection(prev => !prev)}
            checked={doNotAskSelected}
            control={<Checkbox />}
            label={`Don't ask me again`}
            labelPlacement="end"
            sx={{ mr: 16 }}
          />
        </Box>
        <Box sx={{ gap: 12, display: 'flex' }}>
          <Button color="secondary" onClick={closeDialog}>
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => onConfirmation(doNotAskSelected)}
            {...{
              [RUM_ACTION_ATTRIBUTE]:
                'Confirm Switch Spend Ratio Method Strict',
            }}
          >
            Switch
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
