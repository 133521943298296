import React from 'react'
import { Box, CircularProgress, Tooltip } from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'

import { IncludeExcludeSwitch } from '../../../../components/IncludeExcludeSwitch'
import { ACTION } from '../CreativeGrid/action'

interface ListActionCellType {
  action?: (args: GridActionCellParams) => any
  rowData: any
  meta: any
}

export const CreativeListActionCell = ({
  rowData,
  action,
  meta,
}: ListActionCellType) => {
  const includeExclude = (isExcluded: 0 | 1) => {
    const ids = String(rowData.id)
    action?.({
      actionName: ACTION.TOGGLE_INCLUDE_EXCLUDE_INLINE,
      metaData: {
        data: rowData,
        id: String(rowData.id),
        params: {
          campaignId: meta.campaignId,
          ids: ids,
          isExcluded: isExcluded,
        },
      },
    })
  }

  const includeExcludeLoading = React.useMemo(() => {
    const campaignId = rowData?.id
    return meta.loadingIncludeExclude.includes(String(campaignId))
  }, [meta, rowData])

  const isExcluded = rowData.isExcluded
  let tooltipMsg = ''

  switch (isExcluded) {
    case 0:
      tooltipMsg = 'Add to blocklist'
      break
    case 1:
      tooltipMsg = 'Remove from blocklist'
      break
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        gap: 8,
      }}
    >
      {includeExcludeLoading ? (
        <CircularProgress size={16} thickness={5} />
      ) : (
        <Tooltip title={tooltipMsg} placement="top" arrow>
          <Box>
            <IncludeExcludeSwitch
              disabled={meta.disableActions}
              checked={!rowData.isExcluded}
              onChange={value => {
                includeExclude(value.target.checked ? 0 : 1)
              }}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}
