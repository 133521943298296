import React from 'react'
import { List, ListItemButton, MenuItemProps } from '@applift/factor'
import { ChevronRight } from '@applift/icons'
import styles from './index.module.scss'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { SidebarItem as SidebarItemType } from '../../layout/Navigation/navItems'
export interface SidebarItemProps extends MenuItemProps {
  startIcon?: JSX.Element
  title: string
  count: number
  to: string
  currentPath: string
  setIsOpen: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Boolean
    }>
  >
  parentKey: string
}

export const SidebarItem = (props: SidebarItemProps) => {
  const {
    startIcon,
    title,
    count,
    to,
    currentPath,
    setIsOpen,
    parentKey,
    ...others
  } = props

  return (
    <ListItemButton
      Component={Link}
      to={to}
      size="large"
      className={styles.navItem}
      classes={{ selected: styles.selected }}
      selected={currentPath.indexOf(to!) >= 0}
      startIcon={
        startIcon &&
        React.cloneElement(startIcon, {
          className: clsx(styles.startIcon, startIcon?.props?.className),
        })
      }
      onClick={() => {
        if (parentKey) {
          setIsOpen({ [parentKey]: true })
        } else {
          setIsOpen({})
        }
      }}
      {...others}
    >
      {title} {typeof count === 'number' ? `(${count})` : ''}
    </ListItemButton>
  )
}

export interface NestedSidebarItemProps extends MenuItemProps {
  startIcon?: JSX.Element
  title: string
  isOpen: boolean
  subItems: SidebarItemType[]
  currentPath: string
  setIsOpen: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Boolean
    }>
  >
  myKey: string
}

export const NestedSidebarItem = (props: NestedSidebarItemProps) => {
  const {
    startIcon,
    title,
    isOpen,
    subItems,
    currentPath = '',
    myKey,
    setIsOpen,
    ...others
  } = props

  const renderChildren = () => {
    if (Array.isArray(subItems) && subItems.length > 0) {
      const result = subItems.map(one => (
        <SidebarItem
          id="discard_enable_field"
          key={one.name}
          startIcon={one.startIcon}
          count={one.count!}
          to={one.to!}
          title={one.label}
          currentPath={currentPath}
          setIsOpen={setIsOpen}
          parentKey={myKey}
        />
      ))
      return (
        <List sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {result}
        </List>
      )
    }
    return null
  }

  return (
    <>
      <ListItemButton
        id="discard_enable_field"
        size="large"
        className={clsx(styles.navItem, isOpen && styles.open)}
        startIcon={
          startIcon &&
          React.cloneElement(startIcon, {
            className: clsx(styles.startIcon, startIcon?.props?.className),
          })
        }
        endIcon={
          <ChevronRight
            sx={{ textColor: 'neutral-400' }}
            className={styles.endIcon}
          />
        }
        {...others}
      >
        {title}
      </ListItemButton>
      {isOpen ? renderChildren() : null}
    </>
  )
}
