import { QueryFunctionContext } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

import {
  TableResponseWrapper,
  WithResponse,
  WithResponseSuccess,
} from '../models/Response'
import { getInstance } from './instance'
import { getListKey } from './QueryKeys'
import { GlobalBlockPayload, OpenExchangeList } from '../models/OpenExchange'

export const getOpenExchangeList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getListKey)['keys']>>): Promise<
  TableResponseWrapper<OpenExchangeList>
> => {
  const {
    searchField,
    campaignId,
    endDate,
    startDate,
    order,
    sortBy,
    timeZoneId,
    pageSize,
    isExcluded,
    dimensionId,
  } = queryKey[0] || {}
  try {
    const params = {
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(timeZoneId ? { timeZoneId: String(timeZoneId) } : {}),
      ...(searchField ? { searchField } : {}),
      sortBy: `${order === 'DESC' ? '-' : '+'}${sortBy}`,
      pageNo: String(pageParam),
      ...(typeof pageSize === 'number' ? { pageSize: String(pageSize) } : {}),
    }
    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<
      WithResponseSuccess<TableResponseWrapper<OpenExchangeList>>
    > = await getInstance().post(
      `/v3/bm/campaigns/${campaignId}/reports/${dimensionId}?${paramString}`,
      {
        ...(typeof isExcluded === 'number'
          ? { isExcluded: String(isExcluded) }
          : {}),
      }
    )

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}

export const blockCampaignInventory = async (
  blockPayload: GlobalBlockPayload
): Promise<WithResponse<string>> => {
  try {
    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().post('/v3/inv/inventories/block', blockPayload)
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
