import axios, { AxiosInstance } from 'axios'
import { getOwId, ORIGIN_URL } from 'iqm-framework'
import { attachInstance as attachPlatformInstance } from '@applift/platform'
import { attachInstance as attachCampaignDetailsInstance } from '@applift/platform-campaign-details'
import { attachInstance as attachCreativePreviewInstance } from '@applift/platform-creatives'

import { localStorageService } from '../services/localStorage'

export type Params = { [key: string]: string | number }

let IQMInstance: AxiosInstance

const getLatency = (response: any, responseTimestamp: number) => {
  const requestTimestamp =
    response?.config?.meta?.requestTimestamp ||
    response?.response?.config?.meta?.requestTimestamp
  return requestTimestamp ? responseTimestamp - requestTimestamp : null
}

export function createIqmInstance(token: string) {
  const instance = axios.create({
    baseURL: `${ORIGIN_URL}/api`,
    headers: {
      authorization: `Bearer ${token}`,
      'x-iaa-ow-id': getOwId(),
    },
  })

  instance.interceptors.request.use((request: any) => {
    request.meta = {
      ...(request.meta || {}),
      requestTimestamp: Date.now(),
    }
    return request
  })

  instance.interceptors.response.use(
    // @ts-ignore
    (response: any) => {
      return {
        ...response,
        latency: getLatency(response, Date.now()),
      }
    },
    (response: any) => {
      if (response.response && typeof response.response === 'object') {
        response.response.latency = getLatency(response.response, Date.now())
      }

      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: response,
          },
        }

        throw res
      }
      if (response.response.status === 401) {
        localStorageService.removeBaseInfo()
        window.location.reload()
      } else if (response.response.status === 403) {
        window.location.href = '/#/404'
      }
      return Promise.reject(response)
    }
  )
  attachPlatformInstance(instance)
  attachCampaignDetailsInstance(instance)
  attachCreativePreviewInstance(instance)
  IQMInstance = instance
}

export function attachInstance(instance: AxiosInstance) {
  IQMInstance = instance
}

export function getInstance(): AxiosInstance {
  return IQMInstance || axios
}
