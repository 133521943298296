import { Container } from '@applift/factor'

import { PublisherCategoryGridWrapper } from './component/PublisherCategoryGrid/PublisherCategoryGridWrapper'

import styles from './styles.module.scss'

export const PublisherCategoryPage = () => {
  return (
    <Container className={styles.container}>
      <PublisherCategoryGridWrapper />
    </Container>
  )
}
