import { Menu, sx, MenuItem, Box, Typography, SxProps } from '@applift/factor'

interface ButtonMenuPropsType {
  buttons: {
    label: string
    icon?: JSX.Element
    disabled?: boolean
    onClick: () => void
  }[]
  open: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  menuItemSxProps?: SxProps
}

export const ButtonMenu = (props: ButtonMenuPropsType) => {
  const { anchorEl, buttons, onClose, open, menuItemSxProps } = props

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'ColumnMenuButton',
      }}
      sx={{ width: 100 }}
      classes={{
        list: sx({
          border: 1,
          borderColor: 'primary-600',
          borderRadius: 4,
          marginTop: 8,
        }),
      }}
    >
      {buttons.map(val => (
        <MenuItem
          key={val.label}
          onClick={val?.onClick}
          disabled={val?.disabled}
          sx={menuItemSxProps ?? undefined}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'between',
            }}
          >
            {val?.icon ? val.icon : null}
            <Typography
              component="p"
              variant="p"
              gutterBottom={false}
              sx={{ width: 100, height: 100, mx: 8 }}
            >
              {val.label}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  )
}
