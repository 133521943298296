import { Container } from '@applift/factor'

import { TrafficTypeGridWrapper } from './component/TrafficTypeGrid/TrafficTypeGridWrapper'

import styles from './styles.module.scss'

export const TrafficTypePage = () => {
  return (
    <Container className={styles.container}>
      <TrafficTypeGridWrapper />
    </Container>
  )
}
