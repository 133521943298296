import { Route } from '../models/Route'
import { NoMatchPage } from '../pages/NoMatchPage'
import { CreativePageWrapper } from '../pages/CreativePage'
import { DealIdPage } from '../pages/DealIdPage'
import { OpenExchangePage } from '../pages/OpenExchangePage'
import { PublisherCategoryPage } from '../pages/PublisherCategoryPage'
import { TrafficTypePage } from '../pages/TrafficTypePage'
import { DeviceTypePage } from '../pages/DeviceTypePage'
import { StatePage } from '../pages/StatePage'
import { CityPage } from '../pages/CityPage'
import { ZipPage } from '../pages/ZipPage'
import { HomePage } from '../pages/HomePage'
import { ExchangePage } from '../pages/ExchangePage'

export const PATH = {
  HOME: '/',
  CREATIVE_WithID: '/creative/:id',
  CREATIVE: '/creative',
  // INVENTORY
  DEAL_ID_WithId: '/inventory/deal-id/:id',
  DEAL_ID: '/inventory/deal-id',
  OPEN_EXCHANGE_WithId: '/inventory/open-exchange/:id',
  OPEN_EXCHANGE: '/inventory/open-exchange',
  PUBLISHER_CATEGORY_WithId: '/inventory/publisher-category/:id',
  PUBLISHER_CATEGORY: '/inventory/publisher-category',
  TRAFFIC_TYPE_WithId: '/technology/traffic-type/:id',
  TRAFFIC_TYPE: '/technology/traffic-type',
  DEVICE_TYPE_WithId: '/technology/device-type/:id',
  DEVICE_TYPE: '/technology/device-type',
  // LOCATION
  STATE_WithId: '/location/state/:id',
  STATE: '/location/state',
  CITY_WithId: '/location/city/:id',
  CITY: '/location/city',
  ZIP_WithId: '/location/zip/:id',
  ZIP: '/location/zip',
  // EXCHANGE
  EXCHANGE_WithId: '/exchange/:id',
  EXCHANGE: '/exchange',
  NOTFOUND: '/404',
}

export const getCurrentView = (location: string) => {
  const paths = [
    PATH.CREATIVE,
    PATH.DEAL_ID,
    PATH.OPEN_EXCHANGE,
    PATH.PUBLISHER_CATEGORY,
    PATH.TRAFFIC_TYPE,
    PATH.DEVICE_TYPE,
    PATH.STATE,
    PATH.CITY,
    PATH.ZIP,
    PATH.EXCHANGE,
  ]

  const result = paths.find(one => {
    return location.indexOf(one) >= 0
  })
  return result ?? PATH.CREATIVE
}

export const routes: Route[] = [
  {
    path: PATH.HOME,
    exact: true,
    component: HomePage,
  },
  {
    path: PATH.CREATIVE_WithID,
    exact: true,
    component: CreativePageWrapper,
  },
  {
    path: PATH.CREATIVE,
    exact: true,
    component: CreativePageWrapper,
  },
  {
    path: PATH.DEAL_ID_WithId,
    component: DealIdPage,
  },
  {
    path: PATH.DEAL_ID,
    component: DealIdPage,
  },
  {
    path: PATH.OPEN_EXCHANGE_WithId,
    component: OpenExchangePage,
  },
  {
    path: PATH.OPEN_EXCHANGE,
    component: OpenExchangePage,
  },
  {
    path: PATH.PUBLISHER_CATEGORY_WithId,
    component: PublisherCategoryPage,
  },
  {
    path: PATH.PUBLISHER_CATEGORY,
    component: PublisherCategoryPage,
  },
  {
    path: PATH.TRAFFIC_TYPE_WithId,
    component: TrafficTypePage,
  },
  {
    path: PATH.TRAFFIC_TYPE,
    component: TrafficTypePage,
  },
  {
    path: PATH.DEVICE_TYPE_WithId,
    component: DeviceTypePage,
  },
  {
    path: PATH.DEVICE_TYPE,
    component: DeviceTypePage,
  },
  {
    path: PATH.STATE_WithId,
    component: StatePage,
  },
  {
    path: PATH.STATE,
    component: StatePage,
  },
  {
    path: PATH.CITY_WithId,
    component: CityPage,
  },
  {
    path: PATH.CITY,
    component: CityPage,
  },
  {
    path: PATH.ZIP_WithId,
    component: ZipPage,
  },
  {
    path: PATH.ZIP,
    component: ZipPage,
  },
  {
    path: PATH.EXCHANGE_WithId,
    component: ExchangePage,
  },
  {
    path: PATH.EXCHANGE,
    component: ExchangePage,
  },
  {
    path: PATH.NOTFOUND,
    exact: true,
    component: NoMatchPage,
  },
]
