export const DATADOG_SERVICE = 'bidmodel'
export const SUCCESS = 'succeeded'
export const FAILED = 'failed'
export const RUM_ACTION_ATTRIBUTE = 'data-dd-action-name'

export enum OptimizationType {
  CREATIVE = 'creative',
  DEAL_ID = 'deal_id',
  OPEN_EXCHANGE = 'open_exchange',
  PUBLISHER_CATEGORY = 'publisher_category',
  TRAFFIC_TYPE = 'traffic_type',
  DEVICE_TYPE = 'device_type',
  STATE = 'state',
  CITY = 'city',
  ZIP = 'zip',
  EXCHANGE = 'exchange',
}
