import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@applift/factor'

interface DiscardModellingDialogProps {
  onClose: () => void
  onDiscard: () => void
}

export const DiscardModellingDialog = (props: DiscardModellingDialogProps) => {
  const { onClose, onDiscard } = props

  return (
    <Dialog open onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        <Typography weight="demi"> Discard Unsaved Changes </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          You have unsaved changes, are you sure you want to cancel?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={onDiscard}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  )
}
