import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { StoreFilter } from '../models/Store'
import { createAppStore } from './app'
import { createAuthStore } from './auth'
import { createFilterStore } from './filter'
import { createModellingStore } from './modelling'

export const useStore = create<StoreFilter>()(
  devtools((...a) => ({
    ...createAppStore(...a),
    ...createAuthStore(...a),
    ...createFilterStore(...a),
    ...createModellingStore(...a),
  }))
)
