import { AllDimensionListPayload, ModelledListPayload } from './dimensions'
import { scopeTypes } from './ScopeNames'

export const getNavbarCountKey = {
  keys: (
    scope: scopeTypes,
    countParam: {
      id: number
      startDate: number
      endDate: number
    }
  ) => [{ scope, countParam } as const],
}

export const getCampaignDetailKey = {
  keys: (scope: scopeTypes, id: number) => [{ scope, id } as const],
}

export const getCustomerConfigKey = {
  keys: (scope: scopeTypes, customerOwId: string) => [
    { scope, customerOwId } as const,
  ],
}

export const getModelledDimensionsCountKey = {
  keys: (scope: scopeTypes, campaignId: number, dimensionId?: number) => [
    { scope, campaignId, dimensionId } as const,
  ],
}

export const getAllDimensionListKey = {
  keys: (args: AllDimensionListPayload & { scope: scopeTypes }) => [args],
}

export const getModelledDimensionListKey = {
  keys: (args: ModelledListPayload & { scope: scopeTypes }) => [args],
}

export const getListKey = {
  keys: (args: {
    scope: scopeTypes
    searchField?: string
    campaignId: string
    endDate: string
    startDate: string
    noOfEntries: number
    order?: string // 'DESC' | 'ASC'
    sortBy?: string
    timeZoneId: number
    pageSize?: number
    pageNo?: number
    isExcluded?: 0 | 1
    dimensionId: number
  }) => [args],
}

export const getCreativeInfoKey = {
  keys: (scope: scopeTypes, creativeId: number) => [
    { scope, creativeId } as const,
  ],
}
