import { TypoTooltip, TypographyProps } from '@applift/factor'

interface CurrencyCellType {
  value: number
  isFooterCell?: boolean
  startAdornment?: React.ReactNode
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const FormattedNumberCell = ({
  value,
  isFooterCell = false,
  startAdornment,
  textTypographyProps,
}: CurrencyCellType) => {
  return (
    (
      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
          weight: isFooterCell ? 'demi' : 'normal',
        }}
      >
        {startAdornment}
        {value?.toLocaleString()}
      </TypoTooltip>
    ) ?? <>—</>
  )
}
