import { AxiosError, AxiosResponse } from 'axios'
import { QueryFunctionContext } from '@tanstack/react-query'

import { getCustomerConfigKey } from './QueryKeys'
import { WithResponse } from '../models/Response'
import { CustomerConfig } from '../models/App'
import { getInstance } from './instance'

export const getCustomerConfig = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getCustomerConfigKey)['keys']>>) => {
  try {
    const response: AxiosResponse<WithResponse<CustomerConfig>> =
      await getInstance().get(
        `/v3/ua/customer/config/${queryKey[0]?.customerOwId}`
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response || e)
  }
}
