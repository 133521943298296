import { Container } from '@applift/factor'

import { StateGridWrapper } from './component/StateGrid/StateGridWrapper'

import styles from './styles.module.scss'

export const StatePage = () => {
  return (
    <Container className={styles.container}>
      <StateGridWrapper />
    </Container>
  )
}
