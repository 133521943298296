import {
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeNative,
  CreativeVideo,
} from '@applift/icons'

export const creativeIconMap = {
  11: CreativeImage,
  13: CreativeHTML,
  14: CreativeVideo,
  15: CreativeNative,
  17: CreativeAudio,
}

export const CAMPAIGN_STATUSES = {
  DRAFT: 'draft',
  PENDING: 'pending',
  RUNNING: 'running',
  EXPIRED: 'expired',
  DELETED: 'deleted',
  PAUSED: 'paused',
  REJECTED: 'rejected',
}

export const CAMPAIGN_BUDGET_TYPE = {
  DOLLAR: 1,
  IMPRESSIONS: 2,
}

export const CAMPAIGN_TYPE = {
  ADVANCED: 1,
  PG: 2,
}
