import { Container } from '@applift/factor'

import { DealIdGridWrapper } from './component/DealIdGrid/DealIdGridWrapper'

import styles from './styles.module.scss'

export const DealIdPage = () => {
  return (
    <Container className={styles.container}>
      <DealIdGridWrapper />
    </Container>
  )
}
