import { AxiosError, AxiosResponse } from 'axios'
import { datadogRum } from 'iqm-framework'
import { upperFirst } from 'lodash'

import { DATADOG_SERVICE, OptimizationType } from './constants'

export const getActionName = (actionId: string) =>
  `${DATADOG_SERVICE}/${actionId}`

const getEndpoint = (url?: string) => {
  const endpoint = url?.split?.('/api/')?.[1]
  return endpoint ? `/api/${endpoint}` : null
}

export const getApiErrorMsg = (
  response: AxiosResponse<any>,
  withDefaultError = true
) => {
  return (
    response?.data?.errorObjects?.[0]?.error ||
    response?.data?.responseObject?.errorMsg ||
    (withDefaultError
      ? `API Error: ${response?.status || 'No Status Code Found'}`
      : null)
  )
}

export const getApiErrorContext = (err: AxiosError<any>) => ({
  errorMsg: getApiErrorMsg(err.response as AxiosResponse),
  statusCode: err?.response?.status,
  url:
    getEndpoint(err?.response?.config?.url) ||
    getEndpoint(
      `${err.response?.config?.baseURL || ''}/${
        err.response?.config?.url || ''
      }`
    ),
})

export const getActionNamespace = () => {
  return (
    datadogRum.getRumGlobalContext?.()?.iframeParent ||
    datadogRum.getRumGlobalContext?.().service ||
    DATADOG_SERVICE
  )
}

export const optimizationTypeToLabel = (optimizationType: OptimizationType) => {
  return optimizationType
    .split('_')
    .map(str => upperFirst(str))
    .join(' ')
}
