import * as React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Col,
  Row,
  TextField,
  Box,
  LoadingButton,
} from '@applift/factor'
import { InfoCircle } from '@applift/icons'
import { optimizationTypeToLabel } from '../../../services/RUMLogger/utils'
import {
  OptimizationType,
  RUM_ACTION_ATTRIBUTE,
} from '../../../services/RUMLogger/constants'

interface PriorityDialogProps {
  closeDialog: () => void
  onCompletion: (val: number) => void
  itemType: string
  rumSegment: OptimizationType
}

export const PriorityDialog = (props: PriorityDialogProps) => {
  const { closeDialog, onCompletion, itemType, rumSegment } = props

  const [enteredValue, setValue] = React.useState('')

  const doesHaveError = () => {
    if (!enteredValue) {
      return false
    }
    if (enteredValue !== '0' && !Number(enteredValue)) {
      return true
    }
    return Number(enteredValue) < 1 || Number(enteredValue) > 10
  }

  return (
    <Dialog open onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle onClose={closeDialog}>
        <Typography weight="demi"> Set Priority </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography sx={{ mb: 16 }}>
          Enter the
          <Typography sx={{ textWeight: 'demi', mx: 4 }}>Priority</Typography>
          for the selected {itemType}
        </Typography>
        <Row sx={{ my: 24 }}>
          <Col xs={6}>
            <TextField
              label="Priority (1-10)"
              value={enteredValue}
              placeholder="Enter priority"
              error={doesHaveError()}
              helperText={
                doesHaveError() ? 'Must be in the range of 1-10' : undefined
              }
              onChange={e => {
                {
                  setValue(e.target.value)
                }
              }}
              variant="outlinedDash"
              color="primary"
              InputProps={{
                numberInput: true,
                NumberInputProps: {
                  allowMouseWheel: true,
                  step: 1,
                  max: 10,
                  min: 1,
                },
              }}
            />
          </Col>
        </Row>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 8 }}>
          <InfoCircle fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
          <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
            This priority will be applied to all the selected creative(s).
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => onCompletion(Number(enteredValue))}
          disabled={doesHaveError() || !enteredValue}
          {...{
            [RUM_ACTION_ATTRIBUTE]: `Confirm Set Bulk ${optimizationTypeToLabel(rumSegment)} Priority`,
          }}
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
