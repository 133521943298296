import React from 'react'
import {
  Tooltip,
  Box,
  IconButton,
  bindTrigger,
  usePopupState,
  bindMenu,
  sx,
  MenuItem,
  Menu,
} from '@applift/factor'
import { Download, FileCsv, FileXlsx } from '@applift/icons'

type FileTypes = 'xlsx' | 'csv'

interface IDownloadTableAction {
  downloadfile: (fileType: FileTypes) => void
  isDownloading: boolean
  currentDownloadFileType: FileTypes | null
  setCurrentDownloadFileType: React.Dispatch<
    React.SetStateAction<FileTypes | null>
  >
}

export const DownloadTableAction = (props: IDownloadTableAction) => {
  const {
    downloadfile,
    isDownloading,
    currentDownloadFileType,
    setCurrentDownloadFileType,
  } = props

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'dealIdDownloadPopper',
  })
  return (
    <>
      <Tooltip title="Download in CSV/XLSX" placement="top" arrow>
        <Box>
          {/*@ts-ignore */}
          <IconButton
            {...bindTrigger(popupState)}
            id="all-deal-id-download-popper"
            selected={popupState.isOpen}
            color="secondary"
          >
            <Download fontSize={20} />
          </IconButton>
        </Box>
      </Tooltip>
      {/*@ts-ignore */}
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{
          list: sx({
            border: 1,
            borderColor: 'primary-600',
            borderRadius: 4,
          }),
        }}
      >
        <MenuItem
          disabled={currentDownloadFileType === 'csv' && isDownloading}
          onClick={() => {
            setCurrentDownloadFileType('csv')
            downloadfile('csv')
            popupState.close()
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FileCsv fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Download as CSV
          </Box>
        </MenuItem>
        <MenuItem
          disabled={currentDownloadFileType === 'xlsx' && isDownloading}
          onClick={() => {
            setCurrentDownloadFileType('xlsx')
            downloadfile('xlsx')
            popupState.close()
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FileXlsx fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Download as XLSX
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}
