import * as React from 'react'
import { GridRenderEditCellParams } from '@applift/datagrid'
import { Alert, Box, TextField, Tooltip } from '@applift/factor'
import { AlertTriangle } from '@applift/icons'

import styles from './styles.module.scss'

export const InlineNumberEditCell = (props: GridRenderEditCellParams) => {
  const { error, value, apiRef, row, column, hasFocus } = props

  const [inputValue, setInputValue] = React.useState(value)
  const inputRef = React.useRef<any>(null)
  const rowId = React.useMemo(() => row.id, [row])
  const field = React.useMemo(() => column.id, [column])
  const cellElement = React.useMemo(
    () => apiRef.getCellElement(rowId, field),
    [rowId, field, apiRef]
  )
  React.useLayoutEffect(() => {
    if (hasFocus && inputRef.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus, inputRef.current])

  const setInputValueWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexForValidation = /^\d*\.?\d*$/ // regex that checks if the string has only numbers or only a single dot or numbers with atmost an single dot
    const enteredValue = e.target.value

    const isValid = regexForValidation.test(enteredValue)

    if (isValid) {
      setInputValue(enteredValue)
      props.apiRef.setEditCellValue({
        rowId: props.row.id,
        field: props.cell.column.id,
        value: Number(enteredValue) || 0,
        debounceMs: 100,
      })
    }
  }

  // TODO: check if value if changed , if changed => ignoreModification : false
  // if not ignoreModification : true
  // const ignoreModifications = dataRow.priority === inputValue;
  const ignoreModifications =
    Boolean(error) || !inputValue || String(inputValue).trim().length === 0

  return (
    <Tooltip
      open={!!error}
      title={
        <Alert
          sx={{ p: 0, alignItems: 'start', boxShadow: 0, border: 0 }}
          severity="error"
          variant="outlined"
        >
          {error}
        </Alert>
      }
      arrow
      placement="top"
      severity="error"
    >
      <Box
        style={{
          height: `${
            cellElement ? cellElement.getBoundingClientRect().height : 0
          }px`,
        }}
      >
        <TextField
          type="text"
          inputRef={inputRef}
          value={inputValue}
          onChange={setInputValueWrapper}
          sx={{
            border: 0,
            height: 100,
          }}
          inputProps={{
            className: styles.budgetUpdateInput,
            onBlurCapture: () => {
              if (error) {
                apiRef.stopCellEditMode({
                  rowId,
                  field,
                  ignoreModifications: ignoreModifications,
                })
              }
            },
          }}
          InputProps={{
            classes: { root: styles.inlineEditCell },
            startAdornment: error ? (
              <AlertTriangle color="error" fontSize={16} />
            ) : null,
          }}
          InputLabelProps={{
            sx: { border: 0, borderRadius: 0, bgColor: 'danger-400' },
          }}
          color="primary"
          variant="outlined"
        />
      </Box>
    </Tooltip>
  )
}
