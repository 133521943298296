import { getCampaignDetailKey } from './QueryKeys'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { getInstance } from './instance'
import { QueryFunctionContext } from '@tanstack/react-query'
import { ResponseV2 } from '../models/Response'
import { CampaignObject } from '../models/Campaign'

export const getCampaignDetails = async ({
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<(typeof getCampaignDetailKey)['keys']>>) => {
  const { id } = queryKey[0]!
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel(`/v2/cmp/campaign/${id} - Request cancelled`)
  })
  try {
    const response: AxiosResponse<ResponseV2<CampaignObject>> =
      await getInstance().get(`/v2/cmp/campaign/${id}`, {
        cancelToken: source.token,
      })
    return response.data.responseObject
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response || e)
  }
}
