import React from 'react'
import { Col, Paper, Row } from '@applift/factor'
import {
  GridActionCellParams,
  RowSelectionState,
  SortingState,
  VisibilityState,
  constructListFromColumnDef,
} from '@applift/datagrid'

import { CreativeListDataType, useCampaignDetails } from '../../../../hooks'
import { DEFAULT_PAGE_SIZE } from '../../../../constants/creative'
import { CreativeGrid } from './CreativeGrid'
import { colDef, addVideoColDef } from './colDef'
import { ActionPanel } from './ActionPanel'
import { IncludeExcludeQueue } from '../../../../hooks/useIncludeExcludeQueue'
import { DateRange } from '../../../../models/Filter'
import { Timezone } from '../../../../models/Timezone'

interface CreativeGridWrapperProps {
  modelledCreativeCount?: number
  validCampaign: boolean
  budgetTypeId: number
  data: CreativeListDataType | undefined
  isFetching: boolean
  campaignId: number | null
  dimensionId: number
  setAction: (params: GridActionCellParams<any, any>) => void
  includeExcludeQueue: IncludeExcludeQueue
  currentApp: 'bidModel' | 'campaign'
  // Table exclusive props
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  sorting: SortingState
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
  allowBlockFilterValue: 'all' | 'allowed' | 'blocked'
  setAllowBlockFilterValue: React.Dispatch<
    React.SetStateAction<'all' | 'allowed' | 'blocked'>
  >
  campaignStatus: string
  dateRange?: DateRange | null
  timezone?: Timezone | null
  columnsToDisplay?: string[]
}

export interface CreativeGridWrapperRefType {
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}

export const CreativeGridWrapper = React.forwardRef<
  CreativeGridWrapperRefType,
  CreativeGridWrapperProps
>((props, ref) => {
  const {
    data,
    isFetching,
    campaignId,
    dimensionId,
    setAction,
    includeExcludeQueue,
    search,
    sorting,
    allowBlockFilterValue,
    setSearch,
    setSorting,
    setAllowBlockFilterValue,
    modelledCreativeCount,
    validCampaign,
    budgetTypeId,
    campaignStatus,
    dateRange,
    timezone,
    currentApp,
    columnsToDisplay,
  } = props
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})

  const { data: campaignDetails } = useCampaignDetails({
    id: campaignId,
  })

  const columnDefinition = React.useMemo(() => {
    const cols = addVideoColDef(Number(campaignDetails?.creativeType), colDef)
    return columnsToDisplay
      ? cols.filter(column => columnsToDisplay.includes(column.id as string))
      : cols
  }, [campaignDetails, columnsToDisplay])

  const defaultData = React.useMemo(
    () => constructListFromColumnDef(columnDefinition),
    [columnDefinition]
  )
  const [columnVisibility, setColumnVisibility] = React.useState(defaultData[1])
  const defaultDeselectedColumns = React.useState(() =>
    Object.keys(defaultData[1]).reduce((prev, one) => {
      // eslint-disable-next-line
      prev[one] = false
      return prev
    }, {} as VisibilityState)
  )[0]

  React.useImperativeHandle(ref, () => ({
    rowSelection: rowSelection,
    setRowSelection: setRowSelection,
  }))

  React.useEffect(() => {
    setColumnVisibility(defaultData[1])
  }, [defaultData])

  const totalObject = data?.total

  const flatData = React.useMemo(() => {
    const fData = data?.data
    return fData
  }, [data])

  const filteredRecords = React.useMemo(() => {
    if (allowBlockFilterValue !== 'all') {
      return flatData?.length
    }
    return data?.total.totalCount || 0
  }, [data, allowBlockFilterValue, flatData])

  const onSortingChangeWrapper = (
    value: React.SetStateAction<SortingState>
  ) => {
    setRowSelection({})
    setSorting(value)
  }

  const setSearchWrapper = (value: React.SetStateAction<string>) => {
    setRowSelection({})
    setSearch(value)
  }
  const selectedIds = Object.keys(rowSelection).map(id => Number(id))

  const selectedItems = React.useMemo(
    () =>
      flatData?.length
        ? flatData.filter(data => selectedIds.includes(data?.id as number))
        : [],
    [selectedIds, flatData]
  )

  const overlay = React.useMemo(() => {
    if (!campaignId) {
      return 'error'
    }
    if (!flatData?.length && !isFetching && search) {
      return 'noResult'
    }
    if (!flatData?.length && !isFetching) {
      return 'noRows'
    }
    return undefined
  }, [flatData?.length, isFetching, search, campaignId])

  return (
    <>
      <Row sx={{ height: 100 }}>
        <Col xs={12}>
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 100,
            }}
          >
            <ActionPanel
              colDef={columnDefinition}
              search={search}
              setSearch={setSearchWrapper}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              selectedItems={selectedItems}
              isTableLoading={isFetching}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              defaultData={defaultData}
              sorting={sorting}
              defaultDeselectedColumns={defaultDeselectedColumns}
              allowBlockFilterValue={allowBlockFilterValue}
              setAllowBlockFilterValue={setAllowBlockFilterValue}
              totalRecords={filteredRecords as number}
              onAction={setAction}
              loadingIncludeExclude={includeExcludeQueue.loadingIncludeExclude}
              dimensionId={dimensionId as number}
              modelledCreativeCount={modelledCreativeCount}
              validCampaign={validCampaign}
              budgetTypeId={budgetTypeId}
              dateRange={dateRange}
              timezone={timezone}
              campaignId={campaignId}
              currentApp={currentApp}
              showColumnSelector={currentApp === 'bidModel'}
            />
            <CreativeGrid
              footerData={{
                ...totalObject,
              }}
              currentApp={currentApp}
              data={flatData?.length ? flatData : []}
              columnDef={columnDefinition}
              totalRecords={filteredRecords}
              sorting={sorting}
              onSortingChange={onSortingChangeWrapper}
              rowIdKey="id"
              pageSize={DEFAULT_PAGE_SIZE}
              search={search}
              overlay={overlay}
              loading={isFetching}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              defaultDeselectedColumns={defaultDeselectedColumns}
              onAction={setAction}
              loadingIncludeExclude={includeExcludeQueue.loadingIncludeExclude}
              campaignStatus={campaignStatus}
              campaignId={campaignId}
            />
          </Paper>
        </Col>
      </Row>
    </>
  )
})

CreativeGridWrapper.displayName = 'CreativeGridWrapper'
