import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
} from '@applift/factor'

interface ModellingConfirmationDialogProps {
  closeDialog: () => void
  onCompletion: () => void
}

export const ModellingConfirmationDialog = (
  props: ModellingConfirmationDialogProps
) => {
  const { closeDialog, onCompletion } = props

  return (
    <Dialog open onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography weight="demi">Apply Advanced Modelling?</Typography>
      </DialogTitle>
      <DialogContent sx={{ mb: 12 }}>
        <Typography>
          This action will turn off the bid shading once any modelling has been
          applied. Are you sure you want to apply advanced modelling?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={onCompletion}
        >
          Apply Advanced Modelling
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
