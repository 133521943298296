export const CAMPAIGNS_RESTRICTION_CONDITION = {
  MIN_DURATION_DAYS: 3,
  MIN_DAILY_BUDGET_DOLLAR: 100,
  MIN_DAILY_BUDGET_IMPRESSION: 1000,
}

export const DEFAULT_MODELLING_COL_VISIBILITY = {
  CTR: true,
  clicks: true,
  impressions: true,
  spentDistribution: true,
  winRate: true,
}

export const SPENT_RATIO_METHOD = {
  FLEXIBLE: 1,
  STRICT: 2,
}

export const spentRatioMethodMap = {
  1: 'Flexible',
  2: 'Strict',
}

export const defaultOtherRow = {
  id: -1,
  name: 'Others',
  isExcluded: 0,
  impressions: 0,
  winRate: 0.0,
  clicks: 0,
  eCPM: 0.0,
  eCPC: 0.0,
  dataCost: 0.0,
  mediaSpent: 0.0,
  CTR: 0.0,
  spent: 0.0,
  conversionCount: 0,
  conversionValue: 0,
  attributedClickThroughConversions: 0,
  attributedViewThroughConversions: 0,
  costPerAttributedConversions: 0.0,
  attributedConversionsRate: 0.0,
  returnOnAdSpend: 0.0,
  VCR: 0.0,
  eCPV: 0.0,
  eCPCV: 0.0,
  startCount: 0,
  firstCount: 0,
  midCount: 0,
  thirdCount: 0,
  completeCount: 0,
  bidImpressions: 0,
  audioVideoViewed: 0.0,
  prebidCost: 0.0,
  creativeStatus: '-',
  creativeThumbnailSource:
    'https://d2v0lj9tfbnmhu.cloudfront.net/assets/dist/creatives/default-thumbnails/image-tableview.png',
  creativeCardSource:
    'https://d2v0lj9tfbnmhu.cloudfront.net/assets/dist/creatives/default-thumbnails/image-cardview.png',
  spentDistribution: 0.0,
}

export const DEFAULT_BID_DATA_OTHER_FLEX = {
  priority: 10,
  bidMultiplier: 1.0,
  spendRatio: 100.0,
  spendRatioTypeId: 1,
}

export const DEFAULT_BID_DATA_FLEX = {
  priority: 1,
  bidMultiplier: 1.0,
  spendRatio: 100.0,
  spendRatioTypeId: 1,
}
