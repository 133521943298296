import ReactDOM from 'react-dom'
import { enableLogRocket, initDataDog, ORIGIN_URL } from 'iqm-framework'
import * as serviceWorker from './serviceWorker'
import { DATADOG_SERVICE } from './services/RUMLogger/constants'

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  enableLogRocket('bid-model')
}

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  initDataDog(DATADOG_SERVICE, 'prod', {
    allowedTracingOrigins: [ORIGIN_URL],
    trackViewsManually: true,
  })
} else {
  initDataDog(DATADOG_SERVICE, 'stage', {
    allowedTracingOrigins: [ORIGIN_URL],
    trackViewsManually: true,
  })
}
// Do not move the style imports of factor and datagrid below app, that will mess with the import order and app styles will be in a mess
import './App/App.scss'
import '@applift/factor/dist/style'
import '@applift/datagrid/dist/style'
import '@applift/platform/dist/style'
import '@applift/platform-creatives/dist/style'
import '@applift/platform-campaign-details/dist/style'
import { App } from './App'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
