import { StateCreator } from 'zustand'
import { StoreFilter } from '../../models/Store'
import { AuthSlice, User } from '../../models/Auth'

export const createAuthStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  AuthSlice
> = set => ({
  auth: {
    user: null,
    isAuthorized: true,
    authorized: (user: User) => {
      set(
        prev => ({
          ...prev,
          auth: {
            ...prev.auth,
            isAuthorized: true,
            user: user,
          },
        }),
        false,
        'authorized'
      )
    },
    logout: () => {
      set(
        prev => ({
          ...prev,
          auth: { ...prev.auth, user: null, isAuthorized: false },
        }),
        false,
        'logout'
      )
    },
  },
})
