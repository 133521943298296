import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TypoTooltip,
} from '@applift/factor'
import {
  useCampaignMapLocationLegacy,
  ViewCampaignDetails,
} from '@applift/platform-campaign-details'
import { CAMPAIGN_STATUSES, creativeIconMap } from '../../constants/campaigns'
import { RUMLogger } from '../../services/RUMLogger'
import { getPathNumber } from 'iqm-framework'
import { CampaignObject } from '../../models/Campaign'
import { useCustomerConfigInfo } from '../../hooks'
import { useStore } from '../../store'

interface CampaignDetailsProps {
  campaignDetails: CampaignObject
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const getCreativeIcon = (creativeTypeId: number) => {
  const IconComponent =
    creativeIconMap[creativeTypeId as keyof typeof creativeIconMap]
  return <IconComponent sx={{ textColor: 'primary-500', fontSize: 32 }} />
}
const onEdit = (campaignId?: number) => {
  RUMLogger.CampaignDetails.editCampaign()
  window.open(
    encodeURI(`/campaigns/u/${getPathNumber()}/#/campaign-edit/${campaignId}`),
    '_blank'
  )
}

export const CampaignDetails = (props: CampaignDetailsProps) => {
  const { campaignDetails, isDialogOpen, setIsDialogOpen } = props
  const [owId] = useStore(state => [state.auth.user?.owId])
  const { data: customerConfigData } = useCustomerConfigInfo(
    owId?.toString() as string,
    {
      enabled: typeof owId === 'number',
    }
  )
  const isBidShadingEnabled = customerConfigData?.isBidShadingEnabled
  const isEditButtonDisabled = [
    CAMPAIGN_STATUSES.DELETED,
    CAMPAIGN_STATUSES.EXPIRED,
  ].includes(campaignDetails?.status)

  const mapUrl = useCampaignMapLocationLegacy('http://localhost:3000')

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth="xxl"
      fullWidth
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          overflow: 'hidden',
        }}
        onClose={() => setIsDialogOpen(false)}
      >
        {/* Checking wheter a creative type has been assigned to a campaign */}
        {typeof campaignDetails?.creativeType === 'number' &&
        campaignDetails?.creativeType !== 0
          ? getCreativeIcon(campaignDetails?.creativeType)
          : null}
        <TypoTooltip arrow placement="top" TypgraphyProps={{ weight: 'demi' }}>
          {campaignDetails?.campaignName}
        </TypoTooltip>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <ViewCampaignDetails
          key={campaignDetails?.id}
          campaignId={campaignDetails?.id}
          campaignMapLocationIframeURL={mapUrl}
          userType="ADVERTISER"
          isBidShadingEnabled={isBidShadingEnabled === true}
          enableSuspense={true}
          mapInitializerAppName="bidModel"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setIsDialogOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => onEdit(campaignDetails?.id)}
          disabled={isEditButtonDisabled}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
