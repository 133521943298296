import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@applift/factor'
import { RUM_ACTION_ATTRIBUTE } from '../../../services/RUMLogger/constants'

interface DiscardOrDeletionDialogProps {
  closeDialog: () => void
  dialogMaxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  title: string
  onDiscardOrDelete: () => void
  primaryBtnText: string
  dialogContent: string | JSX.Element
  primaryBtnRumLabel?: string
}

export const DiscardOrDeletionDialog = (
  props: DiscardOrDeletionDialogProps
) => {
  const {
    closeDialog,
    dialogMaxWidth,
    title,
    onDiscardOrDelete,
    primaryBtnText,
    dialogContent,
    primaryBtnRumLabel,
  } = props

  return (
    <Dialog open onClose={closeDialog} maxWidth={dialogMaxWidth} fullWidth>
      <DialogTitle id={`${title}_dialog`} onClose={closeDialog}>
        <Typography weight="demi"> {title} </Typography>
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={onDiscardOrDelete}
          {...{ [RUM_ACTION_ATTRIBUTE]: primaryBtnRumLabel }}
        >
          {primaryBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
