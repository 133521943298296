import { Typography, Row, Col, Box } from '@applift/factor'

interface NoResultsOverlayProps {
  text: string
  subText?: string
  illustration: JSX.Element
}

export const NoResultsOverlay = (props: NoResultsOverlayProps) => {
  const { text, subText, illustration } = props

  return (
    <Row
      sx={{
        flexDirection: 'column',
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Col
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {illustration}
        </Box>
      </Col>
      <Col xs={12}>
        <Typography
          component="p"
          variant="bodyMedium"
          sx={{
            textAlign: 'center',
          }}
        >
          {text}
        </Typography>
        <Typography
          component="p"
          variant="p"
          sx={{
            textAlign: 'center',
          }}
        >
          {subText}
        </Typography>
      </Col>
    </Row>
  )
}
