import { QueryFunctionContext } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { WithResponseSuccess } from '../models/Response'
import { getInstance } from './instance'
import { getListKey } from './QueryKeys'
import { DealIdListResponse } from '../models/DealId'

export const getDealIdList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getListKey)['keys']>
>): Promise<DealIdListResponse> => {
  const {
    searchField,
    campaignId,
    endDate,
    startDate,
    order,
    sortBy,
    timeZoneId,
    pageSize,
    isExcluded,
    dimensionId,
  } = queryKey[0] || {}
  try {
    const params = {
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(timeZoneId ? { timeZoneId: String(timeZoneId) } : {}),
      ...(searchField ? { searchField } : {}),
      sortBy: `${order === 'DESC' ? '-' : '+'}${sortBy}`,
      pageNo: String(pageParam),
      ...(typeof pageSize === 'number' ? { pageSize: String(pageSize) } : {}),
    }
    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<WithResponseSuccess<DealIdListResponse>> =
      await getInstance().post(
        `/v3/bm/campaigns/${campaignId}/reports/${dimensionId}?${paramString}`,
        {
          ...(typeof isExcluded === 'number'
            ? { isExcluded: String(isExcluded) }
            : {}),
        }
      )

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
