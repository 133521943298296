import { StateCreator } from 'zustand'
import { StoreFilter } from '../../models/Store'
import { FilterSlice } from '../../models/Filter'

export const createFilterStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  FilterSlice
> = set => ({
  filter: {
    timezone: null,
    dateRange: null,
    campaignId: null,
    status: 'running',
    setTimezone: value =>
      set(
        prev => ({
          ...prev,
          filter: {
            ...prev.filter,
            timezone: value,
          },
        }),
        false,
        'selectTimezone'
      ),
    setDateRange: value =>
      set(
        prev => ({
          ...prev,
          filter: {
            ...prev.filter,
            dateRange: value,
          },
        }),
        false,
        'setDateRange'
      ),
    setCampaignId: value =>
      set(
        prev => ({
          ...prev,
          filter: {
            ...prev.filter,
            campaignId: value,
          },
        }),
        false,
        'setCampaignId'
      ),
    setStatus: value =>
      set(
        prev => ({
          ...prev,
          filter: {
            ...prev.filter,
            status: value,
          },
        }),
        false,
        'setStatus'
      ),
  },
})
