import { Container } from '@applift/factor'

import { DeviceTypeGridWrapper } from './component/DeviceTypeGrid/DeviceTypeGridWrapper'

import styles from './styles.module.scss'

export const DeviceTypePage = () => {
  return (
    <Container className={styles.container}>
      <DeviceTypeGridWrapper />
    </Container>
  )
}
