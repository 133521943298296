import {
  StatusPending,
  StatusRunning,
  StatusDraft,
  StatusPaused,
  StatusRejected,
  StatusExpired,
  StatusDeleted,
} from '@applift/icons'
import { SvgIconProps } from '@applift/factor'

const iconMapper =
  (status: string) => (_props: Partial<Omit<SvgIconProps, 'children'>>) => {
    const props = { ..._props }
    const { sx = {} } = props
    delete props.sx

    return {
      pending: (
        <StatusPending sx={{ textColor: 'warning-400', ...sx }} {...props} />
      ),
      running: (
        <StatusRunning sx={{ textColor: 'success-400', ...sx }} {...props} />
      ),
      draft: <StatusDraft sx={{ textColor: 'info-400', ...sx }} {...props} />,
      paused: (
        <StatusPaused sx={{ textColor: 'warning-400', ...sx }} {...props} />
      ),
      rejected: (
        <StatusRejected sx={{ textColor: 'danger-400', ...sx }} {...props} />
      ),
      expired: (
        <StatusExpired sx={{ textColor: 'neutral-600', ...sx }} {...props} />
      ),
      deleted: (
        <StatusDeleted sx={{ textColor: 'danger-400', ...sx }} {...props} />
      ),
    }[status]
  }

export const StatusIcon = (props: {
  iconProps?: Partial<SvgIconProps>
  status: string
}) => {
  const { status, iconProps = {} } = props
  return iconMapper(status?.toLowerCase())?.(iconProps) || null
}
