import React from 'react'
import { Box, CircularProgress, Tooltip, Typography } from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'
import { InfoCircle } from '@applift/icons'

import { useStore } from '../../../../store'
import { IncludeExcludeSwitch } from '../../../../components/IncludeExcludeSwitch'
import { OpenExchangeList } from '../../../../models/OpenExchange'
import { ACTION } from '../../../CreativePage/component/CreativeGrid/action'

interface ListActionCellType {
  action?: (args: GridActionCellParams) => any
  rowData: OpenExchangeList
  meta: { loadingIncludeExclude: string[] }
}

export const ListActionCell = ({
  rowData,
  action,
  meta,
}: ListActionCellType) => {
  const [selectedCampaignId] = useStore(state => [state.filter.campaignId])

  const includeExclude = (isExcluded: 0 | 1) => {
    const ids = String(rowData.id)
    action?.({
      actionName: ACTION.TOGGLE_INCLUDE_EXCLUDE_INLINE,
      metaData: {
        data: rowData,
        id: ids,
        params: {
          campaignId: selectedCampaignId,
          ids: ids,
          isExcluded: isExcluded,
        },
      },
    })
  }

  const includeExcludeLoading = React.useMemo(() => {
    const id = rowData?.id
    return meta.loadingIncludeExclude.includes(String(id))
  }, [meta, rowData])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
      }}
    >
      {rowData.blocked ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textColor: 'danger-500',
          }}
        >
          <Typography>Blocked</Typography>
          <Tooltip
            title="This inventory is marked as blocked. The complete list of blocked inventories can be found in the Inventory app where you can unblock any if required."
            arrow
          >
            <InfoCircle fontSize={16} />
          </Tooltip>
        </Box>
      ) : (
        <>
          {includeExcludeLoading ? (
            <CircularProgress size={16} thickness={5} />
          ) : (
            <IncludeExcludeSwitch
              checked={!rowData.isExcluded}
              onChange={value => {
                includeExclude(value.target.checked ? 0 : 1)
              }}
              disabled={rowData.name === 'Unknown'}
            />
          )}
        </>
      )}
    </Box>
  )
}
