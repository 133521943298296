import React from 'react'
import styles from './Sidebar.module.scss'

interface SidebarProps {
  children?: React.ReactNode
}

export const Sidebar: React.FC<SidebarProps> = props => {
  const { children } = props

  return (
    <div className={styles.sidebar}>
      <div className={styles.container}>
        <div className={styles.main}>{children}</div>
      </div>
    </div>
  )
}
