import { Container } from '@applift/factor'

import { OpenExchangeGridWrapper } from './component/OpenExchangeGrid/OpenExchangeGridWrapper'

import styles from './styles.module.scss'

export const OpenExchangePage = () => {
  return (
    <Container className={styles.container}>
      <OpenExchangeGridWrapper />
    </Container>
  )
}
