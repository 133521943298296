import { datadogRum } from 'iqm-framework'

import { SUCCESS, FAILED, OptimizationType } from '../../constants'
import { getActionName, getApiErrorMsg } from '../../utils'
import {
  IncludeExcludeErrorResponse,
  IncludeExcludeSuccessResponse,
} from '../../../../models/Creative'
import { AxiosResponse } from 'axios'

const actions = {
  optimizeCampaign: {
    action: getActionName('optimizeCampaign'),
    text: 'Optimize Campaign',
  },
}

interface TOptimizationLogParams {
  response?: IncludeExcludeSuccessResponse | IncludeExcludeErrorResponse
  endpoint?: string
  isExcluded: 0 | 1
  activeTable: OptimizationType
  campaignId: number | null
  success?: boolean
}

export const optimizeCampaign = (params: TOptimizationLogParams) => {
  const { response, endpoint, isExcluded, campaignId, activeTable, success } =
    params

  const optimizationType = isExcluded ? 'exclude' : 'include'
  const activeTableForLog = (activeTable || '').replace('_', ' ').toLowerCase()

  const actionContext = {
    info: {
      campaignId,
      segmentUsed: activeTable,
      api: endpoint,
      isExcluded: !!isExcluded,
      isIncluded: !isExcluded,
      optimizationType,
    },
    errorMsg: success
      ? null
      : getApiErrorMsg(
          (response as IncludeExcludeSuccessResponse).axiosResponse,
          false
        ) ||
        (response as any).axiosError?.response?.data?.responseObject
          ?.errorMsg ||
        getApiErrorMsg(
          (response as IncludeExcludeErrorResponse).axiosError
            ?.response as AxiosResponse<any, any>,
          true
        ),
    statusCode:
      (response as IncludeExcludeSuccessResponse)?.axiosResponse?.status ||
      (response as IncludeExcludeErrorResponse)?.axiosError?.response?.status,
    success,
    action: actions.optimizeCampaign.action,
    latency:
      (response as IncludeExcludeSuccessResponse)?.axiosResponse?.latency ||
      ((response as IncludeExcludeErrorResponse)?.axiosError?.response as any)
        ?.latency,
  }

  datadogRum.addAction(
    `${
      actions.optimizeCampaign.text
    } [${optimizationType} ${activeTableForLog}] ${success ? SUCCESS : FAILED}`,
    actionContext
  )
}
