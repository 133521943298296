import React from 'react'
import { unescape } from 'lodash'
import { parseVASTDAAST } from 'iqm-framework'
import { CreativePreview } from '@applift/platform-creatives'
import { Box } from '@applift/factor'
import { Audio, Show } from '@applift/icons'

import { useCreativeInfo } from '../../../hooks'
import {
  AUDIO_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from '../../../constants/creative'

import styles from './styles.module.scss'

export const CreativePreviewCell = (props: { data: any }) => {
  const { data } = props
  const { creativeStatus } = data

  const audioPlayerRef = React.useRef<HTMLAudioElement>(null)

  const [showCreativePreview, setShowCreativePreview] =
    React.useState<boolean>(false)

  const [creativeInfoFetchingEnabled, setEnableCreativeInfoFetch] =
    React.useState<boolean>(false)

  const [audioSource, setAudioSource] = React.useState<string>('')

  const { data: creativeInfo, isSuccess: isCreativeInfoFetched } =
    useCreativeInfo(data?.id, {
      enabled: creativeInfoFetchingEnabled,
    })
  const creativeTypeId = creativeInfo?.platformCreativeTypeId

  const handleOpenPreview = React.useCallback(async () => {
    if (data.id) {
      const isNativeVideo =
        creativeInfo?.platformCreativeTypeId === NATIVE_CREATIVE_ID &&
        creativeInfo?.subMediaType === 'video'

      if (
        (creativeTypeId === VIDEO_CREATIVE_ID ||
          creativeTypeId === AUDIO_CREATIVE_ID ||
          isNativeVideo) &&
        creativeInfo
      ) {
        const vastOrDaastSourceXML = unescape(creativeInfo.creativeSource)
        const link = await parseVASTDAAST(vastOrDaastSourceXML)
        if (
          (creativeTypeId === NATIVE_CREATIVE_ID && isNativeVideo === false) ||
          creativeTypeId === AUDIO_CREATIVE_ID
        )
          setAudioSource(link)
      }
      setShowCreativePreview(true)
    }
  }, [creativeInfo, data, creativeTypeId])

  React.useEffect(() => {
    if (isCreativeInfoFetched && creativeInfoFetchingEnabled) {
      handleOpenPreview()
    }
  }, [isCreativeInfoFetched, handleOpenPreview, creativeInfoFetchingEnabled])

  const stopPlayingOtherAudio = () => {
    document
      .querySelectorAll('audio')
      .forEach(
        playerEl =>
          playerEl.getAttribute('src') !==
            audioPlayerRef.current?.getAttribute('src') && playerEl.pause()
      )
  }

  const playAudioHandler = () => {
    stopPlayingOtherAudio()
    if (audioPlayerRef.current) {
      audioPlayerRef.current.play()
    }
  }

  const renderContentCell = (item: any) => {
    const { creativeThumbnailSource } = item

    if (creativeThumbnailSource) {
      return (
        <>
          {showCreativePreview &&
          creativeInfo?.platformCreativeTypeId === AUDIO_CREATIVE_ID ? (
            // eslint-disable-next-line
            <audio
              ref={audioPlayerRef}
              controls
              src={audioSource}
              className={styles.audioPlayer}
              autoPlay
              onPlay={playAudioHandler}
            />
          ) : (
            <Box
              className={`${styles.noDataWrapper} ${styles.clickable}`}
              onClick={() => {
                if (!creativeInfo) {
                  setEnableCreativeInfoFetch(true)
                } else {
                  handleOpenPreview()
                }
              }}
            >
              <img
                className={styles.maxHeight80}
                style={{ cursor: 'pointer' }}
                src={creativeThumbnailSource}
                alt=""
              />
              <Box className={styles.imageOverlay}>
                <Box>
                  <Show fontSize={20} sx={{ textColor: 'neutral-0' }} />
                  <Box>Preview</Box>
                </Box>
              </Box>
            </Box>
          )}
          {showCreativePreview &&
          creativeInfo?.platformCreativeTypeId !== AUDIO_CREATIVE_ID ? (
            <CreativePreview
              creativeId={data?.id}
              open={showCreativePreview}
              onClose={() => {
                setShowCreativePreview(false)
              }}
              editable={false}
            />
          ) : null}
        </>
      )
    }

    if (item?.platformCreativeTypeId === AUDIO_CREATIVE_ID) {
      return (
        <>
          {showCreativePreview ? (
            // eslint-disable-next-line
            <audio
              ref={audioPlayerRef}
              controls
              src={audioSource}
              className={styles.audioPlayer}
              autoPlay
              onPlay={playAudioHandler}
            />
          ) : (
            <Box
              className={`${styles.noDataWrapper} ${styles.voiceIcon}`}
              onClick={() => {
                if (!creativeInfo) {
                  setEnableCreativeInfoFetch(true)
                } else {
                  handleOpenPreview()
                }
              }}
            >
              <Audio fontSize={20} />
            </Box>
          )}
        </>
      )
    }

    if (showCreativePreview) {
      return (
        <CreativePreview
          creativeId={data?.id}
          open={showCreativePreview}
          onClose={() => {
            setShowCreativePreview(false)
          }}
          editable={false}
        />
      )
    }

    return (
      <Box
        className={`${styles.noDataWrapper} ${styles.clickable}`}
        onClick={() => {
          if (!creativeInfo) {
            setEnableCreativeInfoFetch(true)
          } else {
            handleOpenPreview()
          }
        }}
      >
        <Audio fontSize={20} />
      </Box>
    )
  }

  if (data.total) {
    return null
  }

  if (creativeStatus === 'Failed') {
    return <Box className={styles.noDataWrapper}>-</Box>
  }

  return (
    <Box
      className={styles.maxHeight80}
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {renderContentCell(data)}
    </Box>
  )
}
