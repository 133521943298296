import React, { useCallback } from 'react'
import {
  TimezonePicker,
  TimezoneProvider,
  EpochDateRangePicker,
} from 'iqm-framework'
import moment from 'moment'
import 'moment-timezone'
import { useStore } from '../../store'
import { PreselectedValues } from '../../models/Filter'
import { CampaignObject } from '../../models/Campaign'
import styles from './styles.module.scss'

const preselected: PreselectedValues[] = [
  EpochDateRangePicker.PRESELECT_KEYS.START_TO_NOW,
  EpochDateRangePicker.PRESELECT_KEYS.TODAY,
  EpochDateRangePicker.PRESELECT_KEYS.YESTERDAY,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_WEEK,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_MONTH,
]

interface DateRangeWrapperProps {
  campaignDetails: CampaignObject
}

export const DateRangeWrapper = (props: DateRangeWrapperProps) => {
  const { campaignDetails } = props

  const [timezone, dateRange, setTimezone, setDateRange] = useStore(state => [
    state.filter.timezone,
    state.filter.dateRange,
    state.filter.setTimezone,
    state.filter.setDateRange,
  ])

  const getDefaultDateRange = useCallback(() => {
    return {
      getStartDate: (tz: string) => {
        const startDate = moment.tz(tz).startOf('day')
        if (dateRange?.start) {
          return moment.tz(
            moment
              .unix(dateRange.start)
              .startOf('day')
              .format('YYYY-MM-DD HH:mm:ss'),
            moment.tz.guess()
          )
        }
        return moment.tz(
          startDate.format('YYYY-MM-DD HH:mm:ss'),
          moment.tz.guess()
        )
      },
      getEndDate: (tz: string) => {
        const endDate = moment.tz(tz).endOf('day')
        return moment.tz(
          endDate.format('YYYY-MM-DD HH:mm:ss'),
          moment.tz.guess()
        )
      },
    }
  }, [dateRange])

  const handleDateChange = ({
    startDate: start,
    endDate: end,
  }: {
    startDate: number
    endDate: number
  }) => {
    // TODO: check use of initial change
    const isInitialChange = !dateRange
    const range = {
      start,
      end,
    }
    // TODO: check use of initial change
    if (isInitialChange) {
      //   selectInitialDateRange(range)
    } else {
      setDateRange(range)
    }
  }

  const strDateRange = React.useMemo(() => {
    if (dateRange && Object.keys(dateRange).length > 0) {
      return {
        startDate: dateRange?.start,
        endDate: dateRange?.end,
      }
    }
    return null
  }, [dateRange])

  if (!timezone?.value) {
    return <></>
  }

  return (
    <TimezoneProvider timezone={timezone}>
      <div className="d-flex">
        <div className="mr-2">
          <TimezonePicker
            className={styles.timezoneWrapper}
            onTimezoneChange={setTimezone}
          />
        </div>
        <div className={styles.dateRangePickerWrapper}>
          <EpochDateRangePicker
            dateRange={strDateRange}
            onDateRangeChange={handleDateChange}
            defaultDateRange={getDefaultDateRange()}
            dateFormat="MM/DD/YYYY"
            className={styles.dateRangePicker}
            datePickerProps={{
              numberOfCalendars: 2,
              singleDateRange: true,
            }}
            preselected={preselected}
            insidePreselectors
            tooltipParams={{
              label: 'Timeframe for the data to include in the Dashboard',
              auto: false,
              position: 'left',
            }}
            startDate={campaignDetails?.startTime}
            removeSafariSelectionIssue
            dataDogApplyButtonLabel="Apply Date Range"
          />
        </div>
      </div>
    </TimezoneProvider>
  )
}
