import React from 'react'
import { Layout } from 'factor'
import { Box, CircularProgress, Container } from '@applift/factor'
import { Switch } from 'react-router-dom'
import { DataDogRumRoute, Header } from 'iqm-framework'
import moment from 'moment'
import 'moment-timezone'
import { useLocation, useHistory } from 'react-router-dom'

import { useStore } from '../store'
import { SubNavbar, SubNavbarProps } from './SubNavbar'
import { Navigation } from './Navigation'
import { Sidebar } from './Sidebar'
import { routes } from '../routes'
import { NormalizedIOCampaignList } from '@applift/platform'
import { useCampaignDetails, useDimensions, useTimezone } from '../hooks'
import { transformCampaignDataToCampaignInitialData } from '../utils/transform'
import { getCurrentView } from '../routes'

import styles from './index.module.scss'

export const PageLayoutMain = () => {
  const [user, setTimezone] = useStore(state => [
    state.auth.user,
    state.filter.setTimezone,
  ])

  const { isLoading: isDimensionLoading } = useDimensions()
  const { data: timezonesList, isLoading } = useTimezone()
  React.useEffect(() => {
    const queryParameters = new URLSearchParams(location.search)
    const queryTimezone = queryParameters.get('timezone')
    if (
      timezonesList?.length &&
      user?.organizationTimezone &&
      typeof user?.organizationTimezone === 'number'
    ) {
      const timezone = timezonesList.find(tz => {
        if (queryTimezone) {
          return +tz.id === +queryTimezone
        }
        return +tz.id === +user.organizationTimezone
      })

      setTimezone(timezone!)
    }
  }, [timezonesList, user, setTimezone])

  return !(isLoading || isDimensionLoading) ? (
    <PageLayoutWrapper />
  ) : (
    <Container
      sx={{
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 'auto',
      }}
    >
      <CircularProgress size={72} thickness={3} />
    </Container>
  )
}

export const PageLayoutWrapper = () => {
  const location = useLocation()
  const [loading, setLoading] = React.useState(true)
  const [initialCampaignId, setInitialCampaignId] = React.useState<
    string | undefined
  >()
  const [initialDataLoaded, setInitialDataLoaded] =
    React.useState<boolean>(false)
  const history = useHistory()

  const executed = React.useRef(false)

  const [initialCampaignData, setInitialCampaignData] =
    React.useState<Record<string, NormalizedIOCampaignList>>()

  const {
    filter: { setCampaignId, setStatus, setDateRange, setTimezone },
  } = useStore()

  const { data: timezone, isLoading: isTimezoneLoading } = useTimezone()

  React.useEffect(() => {
    if (executed.current === true) {
      return
    }
    const view = getCurrentView(location.pathname)
    let campaignId = location.pathname.replace(view, '')
    campaignId = campaignId.replace(/[^\d]/, '')
    if (campaignId) {
      setInitialCampaignId(campaignId)
      executed.current = true
      return
    }
    setLoading(false)
    executed.current = true
  }, [location, setLoading, setInitialCampaignId])

  const { data: campaignDetails, isLoading } = useCampaignDetails({
    id: Number(initialCampaignId),
  })

  React.useEffect(() => {
    const queryParameters = new URLSearchParams(location.search)
    const queryStartDate = queryParameters.get('startDate')
    const queryEndDate = queryParameters.get('endDate')
    const queryTimezone = queryParameters.get('timezone')
    if (isLoading || isTimezoneLoading) {
      return
    }
    if (campaignDetails && timezone && !initialDataLoaded) {
      const currentTimezone = timezone.filter(one => {
        if (queryTimezone) {
          return one.id === +queryTimezone
        }
        return one.id === campaignDetails.timezone
      })[0]
      setCampaignId(campaignDetails.id)
      setStatus(campaignDetails.status)
      if (queryStartDate && queryEndDate) {
        setDateRange({
          start: parseInt(queryStartDate),
          end: parseInt(queryEndDate),
        })
      } else {
        setDateRange({
          start:
            moment(campaignDetails?.startTime * 1000)
              .tz(currentTimezone?.value || 'UTC')
              .startOf('day')
              .unix() * 1000,
          end:
            moment
              .tz(currentTimezone?.value || 'UTC')
              .endOf('day')
              .unix() * 1000,
        })
      }
      setInitialCampaignData(
        transformCampaignDataToCampaignInitialData(campaignDetails)
      )

      if (currentTimezone) {
        setTimezone(currentTimezone)
      }
      const view = getCurrentView(location.pathname)
      if (queryParameters.size) {
        setTimeout(() => {
          history.push(
            `${view}/${campaignDetails?.id}?${queryParameters.toString()}`
          )
        })
      } else {
        setTimeout(() => history.push(`${view}/${campaignDetails?.id}`))
      }
      setInitialDataLoaded(true)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialDataLoaded,
    campaignDetails,
    timezone,
    isTimezoneLoading,
    isLoading,
    setLoading,
    setInitialCampaignData,
    setCampaignId,
    setStatus,
    setDateRange,
    setTimezone,
    history,
  ])

  return loading ? (
    <Container
      sx={{
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 'auto',
      }}
    >
      <CircularProgress size={72} thickness={3} />
    </Container>
  ) : (
    <PageLayout initialCampaignData={initialCampaignData} />
  )
}

interface AppLayoutProps {
  initialCampaignData?: SubNavbarProps['initialCampaignData']
}

export const PageLayout = (props: AppLayoutProps) => {
  const { initialCampaignData } = props
  const [isSidebarOpen, toggleSidebarMenu] = useStore(state => [
    state.app.isSidebarOpen,
    state.app.toggleSidebarMenu,
  ])

  return (
    <>
      <Header backButton={false} title="Bid Model" leftLogo="Bid Model" />
      <Layout
        opened={isSidebarOpen}
        toggleSidebar={toggleSidebarMenu}
        sidebar={
          <Sidebar>
            <Navigation />
          </Sidebar>
        }
        className={styles.factorContainer}
        mainClassName={styles.factorMain}
        sidebarClassName="bidmodel"
      >
        <Box className={styles.subNav}>
          <SubNavbar initialCampaignData={initialCampaignData} />
        </Box>
        <Box className={styles.container}>
          <Switch>
            {routes.map(route => (
              <DataDogRumRoute
                key={route.path}
                path={route.path}
                // @ts-ignore
                component={route.component}
                exact={route.exact}
              />
            ))}
          </Switch>
        </Box>
      </Layout>
    </>
  )
}
