import { Container } from '@applift/factor'

import { ZipGridWrapper } from './component/ZipGrid/ZipGridWrapper'

import styles from './styles.module.scss'

export const ZipPage = () => {
  return (
    <Container className={styles.container}>
      <ZipGridWrapper />
    </Container>
  )
}
