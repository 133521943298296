import { CampaignObject } from '../models/Campaign'
import {
  CampaignList,
  normalizeCampaignData,
  NormalizedIOCampaignList,
} from '@applift/platform'
import {
  CreativeCtaDataType,
  CreativeInfo,
  TransformedCreativeInfo,
} from '../models/Creative'

export const transformCampaignDataToCampaignInitialData = (
  campaignData: CampaignObject
) => {
  const campaignObj: CampaignList = {
    id: campaignData.id,
    campaignId: campaignData.id,
    createdAt: campaignData.createDate,
    creativeTypeId: campaignData.creativeType,
    endTime: campaignData.endTime,
    ioBudgetTypeId: campaignData.budgetTypeId,
    ioId: campaignData.ioId,
    ioName: campaignData.ioName,
    name: campaignData.campaignName,
    owId: campaignData.owId,
    startTime: campaignData.startTime,
    status: campaignData.status,
    organizationName: campaignData.organizationName,
    organizationLogo: '',
    ioStatusId: 0,
    modifiedAt: campaignData.startTime,
  }
  const normalizedCampignData = normalizeCampaignData([campaignObj])
  const result = normalizedCampignData[0]!

  return { [result.value]: result } as Record<string, NormalizedIOCampaignList>
}

export const transformCreativeInfoData = (
  creativeDetails?: CreativeInfo,
  creativeCtaList?: CreativeCtaDataType[]
): TransformedCreativeInfo => {
  const newData = Object.assign(creativeDetails ?? {})

  if (newData?.nativeDetails) {
    newData.nativeDetails.ctaDetails = creativeCtaList?.find(
      one => one.id === creativeDetails?.nativeDetails?.ctaId
    )
    return newData
  }

  return newData
}
