import { TypoTooltip, TypographyProps } from '@applift/factor'

interface PercentCellType {
  value: number
  isFooterCell?: boolean
  decimalDigit?: number
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const PercentCell = ({
  value,
  isFooterCell = false,
  decimalDigit = 2,
  textTypographyProps,
}: PercentCellType) => {
  return value != null ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        ...textTypographyProps,
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {Number(value).toFixed(decimalDigit)}%
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
