import { StateCreator } from 'zustand'
import { StoreFilter } from '../../models/Store'
import { ModellingSlice } from '../../models/Modelling'

export const createModellingStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  ModellingSlice
> = set => ({
  modelling: {
    isDirty: false,
    showDiscardDialog: false,
    setIsDirty: value =>
      set(
        prev => ({
          ...prev,
          modelling: {
            ...prev.modelling,
            isDirty:
              typeof value === 'boolean' ? value : !prev.modelling.isDirty,
          },
        }),
        false,
        'setIsModelling'
      ),

    toggleDiscardDialog: value =>
      set(
        prev => ({
          ...prev,
          modelling: {
            ...prev.modelling,
            showDiscardDialog:
              typeof value === 'boolean'
                ? value
                : !prev.modelling.showDiscardDialog,
          },
        }),
        false,
        'toggleDiscardDialog'
      ),
  },
})
