import { capitalize } from 'lodash'
import { ColumnDef } from '@applift/datagrid'
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@applift/factor'
import { AlertTriangle, SemanticWarning } from '@applift/icons'

import {
  PercentCell,
  CurrencyCell,
  CreativePreviewCell,
  HourCell,
  FormattedNumberCell,
  CreativeDetailCell,
  RawDataCell,
  ImageCell,
} from '../../../../components/CellTypes'
import { CreativeListActionCell, CreativeNameCell } from '../CellTypes'

import { CampaignObject } from '../../../../models/Campaign'
import { VIDEO_CREATIVE_ID } from '../../../../constants/creative'
import { CreativeModelledList } from '../../../../models/Creative'
import { StatusIcon } from '../../../../components/StatusIcon/index'
import { ACTION } from './action'
import { InlineNumberEditCell } from '../CellTypes/InlineNumberEditCell'
import {
  SPENT_RATIO_METHOD,
  spentRatioMethodMap,
} from '../../../../constants/modelledTable'
import { AllowBlockTooltip } from '../../../../components/AllowBlockTooltip'
import { RUM_ACTION_ATTRIBUTE } from '../../../../services/RUMLogger/constants'

export const modelledCreativesColDef: ColumnDef<CreativeModelledList>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'ID',
    enableResizing: false,
    meta: {
      description: 'Creative ID',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => {
      if (props.row.original.id < 0) {
        return <>—</>
      }
      // @ts-ignore
      return props.table.options.meta?.currentApp === 'bidModel' ? (
        <CreativeDetailCell data={props.row.original} />
      ) : (
        <RawDataCell
          value={props.row.original.id.toString()}
          textTypographyProps={{
            sx: {
              textColor: props.row.original.isExcluded
                ? 'danger-500'
                : 'neutral-1000',
            },
          }}
        />
      )
    },
    size: 80,
  },
  {
    accessorKey: 'creativeThumbnailSource',
    id: 'creativeThumbnailSource',
    header: 'Preview',
    size: 150,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description: 'Creative Preview Thumbnail',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => {
      const rowData = props.row.original

      return rowData.id > 0 &&
        // @ts-ignore
        !props.table.options.meta?.disableActions &&
        // @ts-ignore
        props.table.options.meta?.currentApp === 'bidModel' ? (
        <CreativePreviewCell data={props.row.original} />
      ) : (
        <ImageCell src={rowData.creativeThumbnailSource} />
      )
    },
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Creative Name',
    enableResizing: false,
    meta: {
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => (
      <>
        <CreativeNameCell
          creativeType={props.row.original.creativeType}
          textTypographyProps={{
            sx: {
              textColor: props.row.original.isExcluded
                ? 'danger-500'
                : 'neutral-1000',
            },
          }}
          label={props.row.original.name}
        />
      </>
    ),
    size: 300,
  },
  {
    accessorKey: 'creativeStatus',
    id: 'creativeStatus',
    header: 'Status',
    size: 150,
    enableSorting: true,
    enableResizing: false,
    meta: {
      description: 'Status',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => {
      const status =
        props.row.original.creativeStatus?.toLowerCase() === 'approved'
          ? 'Ready'
          : capitalize(props.row.original.creativeStatus)
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 4,
            gap: 2,
            width: 100,
          }}
        >
          <StatusIcon status={status} iconProps={{ sx: { fontSize: 16 } }} />
          <RawDataCell
            value={status}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        </Box>
      )
    },
  },
  {
    accessorKey: 'priority',
    id: 'priority',
    header: () => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>Priority</Typography>
          <Typography sx={{ textWeight: 'normal' }}>(1-10)</Typography>
        </Box>
      )
    },
    size: 100,
    enableSorting: true,
    enableResizing: false,
    meta: {
      description:
        'Priority determines the sequence in which the waterfall will be executed for the corresponding dimensions',
      excludeColumnFromColumnVisibility: true,
      headerTitle: 'Priority',
      align: 'right',
      editable: true,
      preProcessEditCellProps: params => {
        let error = ''
        if (params.props.value > 10 || params.props.value < 1) {
          error = 'Priority must be in the range of 1-10'
        }
        return { ...params.props, error: error }
      },
      renderEditCell: InlineNumberEditCell,
    },
    cell: props => {
      const spendRatioMethod = props.row.original.spendRatioTypeId
      let tooltipMsg = ''
      if (spendRatioMethod === SPENT_RATIO_METHOD.STRICT) {
        tooltipMsg =
          'The STRICT method does not require the specification of priorities'
      }

      return props.row.original.priority ? (
        <FormattedNumberCell
          value={props.row.original.priority}
          textTypographyProps={{
            sx: {
              textColor: props.row.original.isExcluded
                ? 'danger-500'
                : 'neutral-1000',
            },
          }}
        />
      ) : (
        <Tooltip title={tooltipMsg} placement="top" arrow>
          <Box>—</Box>
        </Tooltip>
      )
    },
  },
  {
    accessorKey: 'spendRatio',
    id: 'spendRatio',
    header: () => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>Spend Ratio</Typography>
          <Typography sx={{ textWeight: 'normal' }}>(0%-100%)</Typography>
        </Box>
      )
    },
    size: 120,
    enableSorting: true,
    enableResizing: false,
    meta: {
      description:
        'Spend Ratio specifies the percentage of the  actual budget we intend for a respective  dimension value to expend',
      excludeColumnFromColumnVisibility: true,
      headerTitle: 'Spend Ratio',
      align: 'right',
      editable: true,
      preProcessEditCellProps: params => {
        let error = ''
        const spentPercentage = params.ctx.row.original.spentPercentage
        if (spentPercentage && params.props.value < spentPercentage) {
          error = `The Spend Ratio for this modelled term should not be less than the actual spent amount (${spentPercentage}%).`
        }
        if (params.props.value > 100 || params.props.value < 0) {
          error = 'Spend Ratio must be in the range of 0% - 100%'
        }
        return { ...params.props, error: error }
      },
      renderEditCell: InlineNumberEditCell,
    },
    cell: props => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'between',
            alignItems: 'center',
            width: 100,
          }}
        >
          {(props.row.original.spentPercentage ?? 0) >
            props.row.original.spendRatio && (
            <Tooltip
              arrow
              title={
                <Box sx={{ display: 'flex', gap: 8 }}>
                  <SemanticWarning color="error" fontSize={24} />
                  <Box>{`The Spend Ratio for this modelled term should not be less than the actual spent amount (${props.row.original.spentPercentage}%).`}</Box>
                </Box>
              }
              placement="top"
            >
              <AlertTriangle fontSize={24} color="error" />
            </Tooltip>
          )}
          <PercentCell
            value={props.row.original.spendRatio}
            decimalDigit={0}
            textTypographyProps={{
              sx: {
                ml: 'auto',
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        </Box>
      )
    },
    footer: props => {
      const rows = props?.row?.original?.tableData
      const isFlexible =
        rows[0]?.spendRatioTypeId === SPENT_RATIO_METHOD.FLEXIBLE
      let spendRatioTotalSum = 0
      rows?.forEach(
        (item: { spendRatio: number }) =>
          (spendRatioTotalSum +=
            typeof item?.spendRatio === 'number' ? item?.spendRatio : 0)
      )
      const hasError = isFlexible
        ? spendRatioTotalSum < 100
        : spendRatioTotalSum !== 100
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: hasError ? 'between' : 'end',
            width: 100,
            textColor: hasError ? 'danger-500' : 'neutral-1000',
          }}
        >
          {isFlexible && hasError ? (
            <Tooltip
              arrow
              placement="top"
              title={
                <Box sx={{ display: 'flex', gap: 8 }}>
                  <SemanticWarning color="error" fontSize={24} />
                  <Box>
                    For the FLEXIBLE method, the sum of all Spend Ratios must be
                    at least 100%.
                  </Box>
                </Box>
              }
            >
              <AlertTriangle color="error" fontSize={24} />
            </Tooltip>
          ) : null}
          {isFlexible ? <>—</> : null}
          {!isFlexible && hasError ? (
            <Tooltip
              placement="top"
              arrow
              title={
                <Box sx={{ display: 'flex', gap: 8 }}>
                  <SemanticWarning color="error" fontSize={24} />
                  <Box>
                    In the STRICT method, the sum of Spend Ratios must equate to
                    100%
                  </Box>
                </Box>
              }
            >
              <AlertTriangle color="error" fontSize={24} />
            </Tooltip>
          ) : null}
          {!isFlexible ? (
            <Typography sx={{ textWeight: 'demi' }}>
              {`${spendRatioTotalSum.toFixed(2)}%`}
            </Typography>
          ) : null}
        </Box>
      )
    },
  },
  {
    accessorKey: 'spendRatioTypeId',
    id: 'spendRatioTypeId',
    header: 'Spend Ratio Method',
    size: 160,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description:
        'The Spend Ratio method allows precise control over the allocation of budget for your modelled terms',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => {
      const methodId = props.row.original.spendRatioTypeId

      return (
        <RawDataCell
          value={
            spentRatioMethodMap[methodId as keyof typeof spentRatioMethodMap]
          }
          textTypographyProps={{
            sx: {
              textColor: props.row.original.isExcluded
                ? 'danger-500'
                : 'neutral-1000',
            },
          }}
        />
      )
    },
    footer: props => {
      const rows = props?.row?.original?.tableData
      const toggleValue =
        rows[0].spendRatioTypeId || SPENT_RATIO_METHOD.FLEXIBLE

      const hasOtherThanDeletedOrRejectedCreatives = rows?.some(
        (row: { id: number; creativeStatus: string }) =>
          row.id !== -1 &&
          !['rejected', 'deleted'].includes(row.creativeStatus?.toLowerCase())
      )
      const hideToggle =
        props?.row?.original?.isViewOnly ||
        !hasOtherThanDeletedOrRejectedCreatives

      return hideToggle ? (
        <Typography sx={{ textWeight: 'demi' }}>
          {SPENT_RATIO_METHOD.FLEXIBLE === toggleValue ? 'Flexible' : 'Strict'}
        </Typography>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={toggleValue}
          disabled={!rows.length}
          exclusive
          orientation="horizontal"
          size="small"
          variant="outlined"
          onChange={(_, value) => {
            if (value) {
              props.onAction?.({
                actionName: ACTION.TOGGLE_SPEND_RATIO_METHOD,
                metaData: {
                  spendRatioType: Number(value),
                },
              })
            }
          }}
        >
          <ToggleButton
            value={SPENT_RATIO_METHOD.FLEXIBLE}
            {...{ [RUM_ACTION_ATTRIBUTE]: 'Spend Ratio Method [Flexible]' }}
          >
            Flexible
          </ToggleButton>
          <ToggleButton
            value={SPENT_RATIO_METHOD.STRICT}
            {...{ [RUM_ACTION_ATTRIBUTE]: 'Spend Ratio Method [Strict]' }}
          >
            Strict
          </ToggleButton>
        </ToggleButtonGroup>
      )
    },
  },
  {
    accessorKey: 'bidMultiplier',
    id: 'bidMultiplier',
    header: () => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>Bid Modifier</Typography>
          <Typography sx={{ textWeight: 'normal' }}>(0.1x-100x)</Typography>
        </Box>
      )
    },
    size: 120,
    enableSorting: true,
    enableResizing: false,
    meta: {
      description:
        'Bid Modifier enables a user to adjust the bid price for a specific modelled term',
      headerTitle: 'Bid Modifier',
      excludeColumnFromColumnVisibility: true,
      align: 'right',
      editable: true,
      preProcessEditCellProps: params => {
        let error = ''
        if (
          Number(params.props.value) > 100 ||
          Number(params.props.value) < 0.1
        ) {
          error = 'Bid modifier must be in the range of 0.1x-100x'
        }
        return { ...params.props, error: error }
      },
      renderEditCell: props => <InlineNumberEditCell {...props} />,
    },
    cell: props => {
      return props.row.original.bidMultiplier ? (
        <RawDataCell
          value={`${Number(props.row.original.bidMultiplier).toFixed(1)}x`}
          textTypographyProps={{
            sx: {
              textColor: props.row.original.isExcluded
                ? 'danger-500'
                : 'neutral-1000',
            },
          }}
        />
      ) : (
        '-'
      )
    },
  },
  {
    id: 'performance',
    header: 'Performance',
    columns: [
      {
        accessorKey: 'impressions',
        id: 'impressions',
        header: 'Impressions',
        enableResizing: false,
        meta: {
          align: 'right',
          description: "Number of times ad displayed on someone's screen",
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 130,
      },
      {
        accessorKey: 'clicks',
        id: 'clicks',
        header: 'Clicks',
        enableResizing: false,
        meta: {
          align: 'right',
          description: 'Number of times someone clicks your ad',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
      },
      {
        accessorKey: 'winRate',
        id: 'winRate',
        header: 'Win Rate',
        enableResizing: false,
        meta: {
          headerTitle: 'Win Rate(%)',
          align: 'right',
          description: 'Percentage of Impressions won per the Bids made',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
      },
      {
        accessorKey: 'CTR',
        id: 'CTR',
        header: 'CTR',
        meta: {
          align: 'right',
          headerTitle: 'CTR(%)',
          description:
            'Click-through rate is the percentage of Clicks received per the Impressions served',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'spentDistribution',
        id: 'spentDistribution',
        header: 'Spent Distribution(%)',
        meta: {
          align: 'right',
          description: 'Spent Distribution(%)',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'cost',
    header: 'Cost',
    columns: [
      {
        accessorKey: 'eCPM',
        id: 'eCPM',
        header: 'eCPM',
        meta: {
          align: 'right',
          headerTitle: 'eCPM($)',
          description:
            'Effective Cost Per Mile is the amount you pay per one thousand Impressions',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'eCPC',
        id: 'eCPC',
        header: 'eCPC',
        meta: {
          align: 'right',
          headerTitle: 'eCPC($)',
          description:
            'Effective Cost Per Click is the amount spent divided by the number of clicks',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'spending',
    header: 'Spending',
    columns: [
      {
        accessorKey: 'mediaSpent',
        id: 'mediaSpent',
        header: 'Media Cost',
        meta: {
          align: 'right',
          headerTitle: 'Media Cost($)',
          description: 'Amount spent to win Impressions',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'dataCost',
        id: 'dataCost',
        header: 'Data Cost',
        meta: {
          align: 'right',
          headerTitle: 'Data Cost($)',
          description:
            'Amount spent to serve Impressions to Audience you matched or purchased',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'prebidCost',
        id: 'prebidCost',
        header: 'Pre-bid Cost',
        meta: {
          align: 'right',
          headerTitle: 'Pre-bid Cost($)',
          description: 'Pre-bid Cost',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'spent',
        id: 'spent',
        header: 'Total Spent',
        meta: {
          align: 'right',
          headerTitle: 'Total Cost($)',
          description:
            'Total spent is the amount spent by the campaign(s) for the selected timeframe, that includes the media cost, data cost and pre-bid cost',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'conversions',
    header: 'Conversions',
    columns: [
      {
        accessorKey: 'conversionValue',
        id: 'conversionValue',
        header: 'Total Attributed Conversion Value',
        meta: {
          align: 'right',
          description: 'Total Attributed Conversion Value',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'conversionCount',
        id: 'conversionCount',
        header: 'Total Attributed Conversions',
        meta: {
          align: 'right',
          description: 'Total Attributed Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'returnOnAdSpend',
        id: 'returnOnAdSpend',
        header: 'Return On Ad Spend',
        meta: {
          align: 'right',
          description: 'Return On Ad Spend',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedClickThroughConversions',
        id: 'attributedClickThroughConversions',
        header: 'Attributed Click Through Conversions',
        meta: {
          align: 'right',
          description: 'Attributed Click Through Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedViewThroughConversions',
        id: 'attributedViewThroughConversions',
        header: 'Attributed View Through Conversions',
        meta: {
          align: 'right',
          description: 'Attributed View Through Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'costPerAttributedConversions',
        id: 'costPerAttributedConversions',
        header: 'Cost Per Attributed Conversions',
        meta: {
          align: 'right',
          description: 'Cost Per Attributed Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedConversionsRate',
        id: 'attributedConversionsRate',
        header: 'Attributed Conversions Rate',
        meta: {
          align: 'right',
          description: 'Attributed Conversions Rate',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
    ],
  },
  {
    accessorKey: 'Allowlist/Blocklist',
    id: 'Allowlist/Blocklist',
    meta: {
      description: <AllowBlockTooltip listType="creatives" />,
      excludeColumnFromColumnVisibility: true,
      // @ts-ignore
      excludeColumnFromDownload: true,
    },
    cell: props => {
      return props.row.original.id !== -1 ? (
        <CreativeListActionCell
          action={props.onAction}
          rowData={props.row?.original}
          meta={props.table.options.meta}
        />
      ) : null
    },
    size: 140,
    enableResizing: false,
    enableSorting: false,
  },
]

export const videoColDef: ColumnDef<any>[] = [
  {
    id: 'video',
    header: 'Video',
    columns: [
      {
        accessorKey: 'startCount',
        id: 'startCount',
        header: 'Video Start',
        meta: {
          align: 'right',
          description: 'Number of video ads that start to play',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'firstCount',
        id: 'firstCount',
        header: 'Video 25%',
        meta: {
          align: 'right',
          description:
            'Number of video ads that play through 25% of their duration',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        enableResizing: false,
        size: 100,
      },
      {
        accessorKey: 'midCount',
        id: 'midCount',
        header: 'Video 50%',
        meta: {
          align: 'right',
          description:
            'Number of video ads that play through 50% of their duration',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        size: 100,
      },
      {
        accessorKey: 'thirdCount',
        id: 'thirdCount',
        header: 'Video 75%',
        meta: {
          align: 'right',
          description:
            'Number of video ads that play through 75% of their duration',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        size: 100,
      },
      {
        accessorKey: 'completeCount',
        id: 'completeCount',
        header: 'Video 100%',
        meta: {
          align: 'right',
          description: 'Number of video ads that play through to completion',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          <FormattedNumberCell
            isFooterCell
            // @ts-ignore
            value={props?.renderValue() as number}
          />
        ),
        size: 110,
        enableResizing: false,
      },
      {
        accessorKey: 'audioVideoViewed',
        id: 'audioVideoViewed',
        header: 'Hours Viewed',
        meta: {
          align: 'right',
          description: 'Hours Viewed',
        },
        cell: props => (
          <HourCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <HourCell isFooterCell value={props?.renderValue() as number} />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
]

export const videoPerformanceColDef = [
  {
    accessorKey: 'VCR',
    id: 'VCR',
    header: 'VCR(%)',
    meta: {
      align: 'right',
      description:
        'Video Completion Rate is the percentage of video ads that play through to completion',
    },
    cell: (props: {
      renderValue: () => number
      row: { original: { isExcluded: boolean } }
    }) => (
      <PercentCell
        value={props.renderValue()}
        textTypographyProps={{
          sx: {
            textColor: props.row.original.isExcluded
              ? 'danger-500'
              : 'neutral-1000',
          },
        }}
      />
    ),
    footer: (props: any) => (
      <PercentCell value={props?.renderValue?.() as number} />
    ),
    size: 100,
    enableResizing: false,
  },
]

export const addVideoColDef = <T,>(
  creativeType: CampaignObject['creativeType'],
  columnDefinition: ColumnDef<T>[]
) => {
  if (creativeType === VIDEO_CREATIVE_ID) {
    const vDef = columnDefinition.map(def => {
      if (def.id === 'performance') {
        const newDef = JSON.parse(JSON.stringify(def))
        // @ts-ignore
        newDef.columns = [...def.columns, ...videoPerformanceColDef]
        return newDef
      }
      return def
    })
    return [...vDef, ...videoColDef]
  }
  return columnDefinition
}
