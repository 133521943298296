import { TypoTooltip, TypographyProps } from '@applift/factor'
import { formatCurrency } from '../../utils/format'

interface CurrencyCellType {
  value: number
  isFooterCell?: boolean
  formatCurrencyValue?: boolean
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CurrencyCell = ({
  value,
  isFooterCell = false,
  formatCurrencyValue = true,
  textTypographyProps,
}: CurrencyCellType) => {
  return value != null ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        ...textTypographyProps,
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {formatCurrencyValue ? formatCurrency(value) : value}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
