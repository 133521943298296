import * as React from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { List } from '@applift/factor'
import { useLocation } from 'react-router-dom'

import { NestedSidebarItem, SidebarItem } from '../../components/SidebarItem'
import { useNavbarCount } from '../../hooks/useCount'
import { WithResponse } from '../../models/Response'
import { getCountResponse } from '../../api/count'
import { useStore } from '../../store'
import { transformNavData } from './navItems'
import { useDimensions } from '../../hooks'
import { getCurrentView } from '../../routes'

export const Navigation = () => {
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState<{ [key: string]: Boolean }>(() => {
    const view = getCurrentView(location.pathname)
    const parentView = view.split('/')[1]
    if (parentView) {
      const openObj: { [key: string]: Boolean } = {}
      openObj[`${parentView}`] = true
      return { ...openObj }
    }
    return {}
  })
  const {
    filter: { dateRange, campaignId },
  } = useStore()

  const { data: dimensionsCountData } = useNavbarCount(
    {
      id: campaignId as number,
      startDate: dateRange?.start ?? 0,
      endDate: dateRange?.end ?? 0,
    },
    { enabled: Boolean(campaignId) }
  ) as UseQueryResult<getCountResponse, WithResponse<void>>

  const { data: dimensions } = useDimensions()

  const navbarItemsData = React.useMemo(() => {
    if (dimensions?.dimensionData?.length) {
      return transformNavData(
        dimensions?.dimensionData,
        campaignId,
        dimensionsCountData
      )
    }
    return []
  }, [dimensions, campaignId, dimensionsCountData])

  if (!Array.isArray(navbarItemsData)) {
    return null
  }
  const sidebarItems = navbarItemsData?.map(item => {
    if (Array.isArray(item.subdimensions)) {
      return (
        <NestedSidebarItem
          id="discard_enable_field"
          key={item.name}
          myKey={item.name}
          startIcon={item.startIcon}
          subItems={item.subdimensions}
          title={item.label}
          isOpen={isOpen[item.name] === true}
          currentPath={location.pathname}
          onClick={() => {
            setIsOpen(prev => {
              const copy = { ...prev }
              if (prev[item.name] === true) {
                delete copy[item.name]
                return copy
              }
              return { ...prev, [item.name]: true }
            })
          }}
          setIsOpen={setIsOpen}
        />
      )
    }
    return (
      <SidebarItem
        id="discard_enable_field"
        key={item.name}
        startIcon={item.startIcon}
        title={item.label}
        currentPath={location.pathname}
        // @ts-ignore
        count={item.count!}
        // @ts-ignore
        to={item.to!}
        setIsOpen={setIsOpen}
        parentKey=""
      />
    )
  })

  return (
    <List
      sx={{
        p: 8,
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      {sidebarItems}
    </List>
  )
}
