import {
  Box,
  TypoTooltip,
  SvgIconProps,
  TypographyProps,
  ButtonBase,
  Link,
} from '@applift/factor'
import { getIcon, IconNamesTypes } from '../../../../registry'
import { toInitCap } from '../../../../utils'

interface InventoryNameCellType {
  iconName: IconNamesTypes | string
  iconSize: SvgIconProps['fontSize']
  label: string
  position?: 'after' | 'before'
  inventoryType?: 'web' | 'app'
  inventoryUrl?: string
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const InventoryNameCell = ({
  iconName,
  iconSize,
  label,
  position = 'before',
  inventoryType,
  inventoryUrl,
  textTypographyProps,
}: InventoryNameCellType) => {
  const icon = getIcon((iconName as IconNamesTypes) || 'App', {
    fontSize: iconSize,
  })
  const checkHttp = (url: string) => {
    if (url.includes('https') || url.includes('http')) {
      return url
    }
    return `https://${url}`
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
        ...(position === 'after' ? { flexDirection: 'row-reverse' } : {}),
      }}
    >
      {icon}
      {inventoryType === 'web' ? (
        <>
          <ButtonBase>
            <Link
              Component="a"
              href={checkHttp(inventoryUrl as string)}
              // @ts-ignore
              target="_blank"
              rel="noopener noreferrer"
            >
              <TypoTooltip
                arrow
                placement="top"
                TypgraphyProps={{
                  ...textTypographyProps,
                  gutterBottom: false,
                  sx: {
                    ...(position === 'after' ? { mr: 4 } : { ml: 4 }),
                    ...textTypographyProps?.sx,
                  },
                }}
              >
                {toInitCap(label)}
              </TypoTooltip>
            </Link>
          </ButtonBase>
        </>
      ) : (
        <TypoTooltip
          arrow
          placement="top"
          TypgraphyProps={{
            ...textTypographyProps,
            gutterBottom: false,
            sx: {
              ...(position === 'after' ? { mr: 4 } : { ml: 4 }),
              ...textTypographyProps?.sx,
            },
          }}
        >
          {toInitCap(label)}
        </TypoTooltip>
      )}
    </Box>
  )
}
