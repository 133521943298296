import * as React from 'react'
import { Box, Tooltip, Typography } from '@applift/factor'
import { InfoCircle } from '@applift/icons'

import {
  getCurrentCampaignPriorityString,
  getPriorityShiftTimestampString,
} from './utils'
import styles from './styles.module.scss'
import { useCampaignDetails, useTimezone } from '../../../../hooks'

interface Props {
  campaignNextPerformanceCheckTime?: number | null
  campaignLastPriorityShiftTime?: number | null
  ioId?: number | null
  campaignId: number
}

export const CampaignPriorityShiftInfo = (props: Props) => {
  const { campaignId } = props

  const { data: campaignDetails } = useCampaignDetails({ id: campaignId })
  const { data: timezones } = useTimezone()

  const campTimezone = React.useMemo(() => {
    // @ts-ignore
    if (campaignDetails && campaignDetails.timezone && timezones) {
      // @ts-ignore
      return timezones.find(tz => tz.id === campaignDetails.timezone) || null
    }
    return null
  }, [campaignDetails, timezones])

  if (!campTimezone || !campaignDetails?.campaignNextPerformanceCheckTime) {
    return null
  }

  const nextPerformanceCheck = (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 8 }}>
      <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
        Next performance check:{' '}
        <Typography weight="demi">
          {getPriorityShiftTimestampString(
            campTimezone,
            campaignDetails?.campaignNextPerformanceCheckTime
          )}
        </Typography>
      </Typography>
    </Box>
  )

  if (campaignDetails?.campaignLastPriorityShiftTime) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip
          arrow
          classes={{
            tooltip: styles.tooltipFitContent,
          }}
          placement="top-start"
          title={
            <Typography component="div">
              <Typography sx={{ whiteSpace: 'nowrap' }} component="div">
                Last priority shift was on
                <br />
                <Typography weight="demi">
                  {getPriorityShiftTimestampString(
                    campTimezone,
                    campaignDetails?.campaignLastPriorityShiftTime
                  )}
                </Typography>
                <br />
                <Box sx={{ marginTop: 24 }}>
                  Active{' '}
                  {campaignDetails?.campaignDimensionCurrentPriority === 1
                    ? 'priority: '
                    : 'priorities: '}
                  <Typography weight="demi">
                    {getCurrentCampaignPriorityString(
                      campaignDetails?.campaignDimensionCurrentPriority ?? 0
                    )}
                  </Typography>
                </Box>
              </Typography>
            </Typography>
          }
        >
          <InfoCircle fontSize={16} sx={{ textColor: 'neutral-400', mr: 4 }} />
        </Tooltip>
        {nextPerformanceCheck}
      </Box>
    )
  }

  return nextPerformanceCheck
}
