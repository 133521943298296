import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'
import { getCampaignDetailKey, getCustomerConfigKey } from '../api/QueryKeys'
import { WithResponse } from '../models/Response'
import { getCampaignDetails } from '../api/filter'
import { getCustomerConfig } from '../api/app'

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    }
  )

export const useCampaignDetails = ({ id }: { id: number | null }) => {
  const data = useQuery(
    getCampaignDetailKey.keys('getCampaignDetailedInfo', Number(id)),
    getCampaignDetails,
    {
      onError,
      enabled: !(id == null || isNaN(id)),
    }
  )
  return data
}

export const useCustomerConfigInfo = (
  customerOwId: string,
  options?: {
    enabled?: boolean
  }
) => {
  const data = useQuery(
    getCustomerConfigKey.keys('getCustomerConfig', customerOwId),
    getCustomerConfig,
    {
      enabled: options?.enabled,
      cacheTime: 5000,
      onError,
    }
  )
  return data
}
