import { ColumnDef } from '@applift/datagrid'

import {
  RawDataCell,
  PercentCell,
  CurrencyCell,
  FormattedNumberCell,
} from '../../../../components/CellTypes'
import { InventoryNameCell, ListActionCell } from '../CellTypes'
import { BidModelTableMeta } from '../../../../models/Common'
import { AllowBlockTooltip } from '../../../../components/AllowBlockTooltip'

// Change below any to creative list api response
export const colDef: ColumnDef<any>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'ID',
    meta: {
      description: 'ID',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => (
      <RawDataCell
        value={`${props.renderValue()}`}
        textTypographyProps={{
          sx: {
            textColor:
              props.row.original.isExcluded || props.row.original.blocked
                ? 'danger-500'
                : 'neutral-1000',
          },
        }}
      />
    ),
    size: 80,
    enableResizing: false,
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Inventory Name',
    meta: {
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => (
      <>
        <InventoryNameCell
          iconName={props.row.original.inventoryTrafficType as string}
          inventoryType={props.row.original.inventoryTrafficType}
          inventoryUrl={props.row.original.inventoryBundleDomain}
          iconSize={24}
          label={props.renderValue() as string}
          textTypographyProps={{
            sx: {
              textColor:
                props.row.original.isExcluded || props.row.original.blocked
                  ? 'danger-500'
                  : 'neutral-1000',
            },
          }}
        />
      </>
    ),
    size: 200,
    enableResizing: false,
  },
  {
    id: 'performance',
    header: 'Performance',
    columns: [
      {
        accessorKey: 'impressions',
        id: 'impressions',
        header: 'Impressions',
        meta: {
          align: 'right',
          description: "Number of times ad displayed on someone's screen",
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 130,
        enableResizing: false,
      },
      {
        accessorKey: 'clicks',
        id: 'clicks',
        header: 'Clicks',
        meta: {
          align: 'right',
          description: 'Number of times someone clicks your ad',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'winRate',
        id: 'winRate',
        header: 'Win Rate',
        meta: {
          align: 'right',
          headerTitle: 'Win Rate(%)',
          description: 'Percentage of Impressions won per the Bids made',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'CTR',
        id: 'CTR',
        header: 'CTR',
        meta: {
          align: 'right',
          headerTitle: 'CTR(%)',
          description:
            'Click-through rate is the percentage of Clicks received per the Impressions served',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'cost',
    header: 'Cost',
    columns: [
      {
        accessorKey: 'eCPM',
        id: 'eCPM',
        header: 'eCPM',
        meta: {
          align: 'right',
          headerTitle: 'eCPM($)',
          description:
            'Effective Cost Per Mile is the amount you pay per one thousand Impressions',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'eCPC',
        id: 'eCPC',
        header: 'eCPC',
        meta: {
          align: 'right',
          headerTitle: 'eCPC($)',
          description:
            'Effective Cost Per Click is the amount spent divided by the number of clicks',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'spending',
    header: 'Spending',
    columns: [
      {
        accessorKey: 'mediaSpent',
        id: 'mediaSpent',
        header: 'Media Cost',
        meta: {
          align: 'right',
          headerTitle: 'Media Cost($)',
          description: 'Amount spent to win Impressions',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'dataCost',
        id: 'dataCost',
        header: 'Data Cost',
        meta: {
          align: 'right',
          headerTitle: 'Data Cost($)',
          description:
            'Amount spent to serve Impressions to Audience you matched or purchased',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'prebidCost',
        id: 'prebidCost',
        header: 'Pre-bid Cost',
        meta: {
          align: 'right',
          headerTitle: 'Pre-bid Cost($)',
          description: 'Pre-bid Cost',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'spent',
        id: 'spent',
        header: 'Total Spent',
        meta: {
          align: 'right',
          headerTitle: 'Total Cost($)',
          description:
            'Total spent is the amount spent by the campaign(s) for the selected timeframe, that includes the media cost, data cost and pre-bid cost',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'conversions',
    header: 'Conversions',
    columns: [
      {
        accessorKey: 'conversionValue',
        id: 'conversionValue',
        header: 'Total Attributed Conversion Value',
        meta: {
          align: 'right',
          description: 'Total Attributed Conversion Value',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'conversionCount',
        id: 'conversionCount',
        header: 'Total Attributed Conversions',
        meta: {
          align: 'right',
          description: 'Total Attributed Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'returnOnAdSpend',
        id: 'returnOnAdSpend',
        header: 'Return On Ad Spend',
        meta: {
          align: 'right',
          description: 'Return On Ad Spend',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedClickThroughConversions',
        id: 'attributedClickThroughConversions',
        header: 'Attributed Click Through Conversions',
        meta: {
          align: 'right',
          description: 'Attributed Click Through Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedViewThroughConversions',
        id: 'attributedViewThroughConversions',
        header: 'Attributed View Through Conversions',
        meta: {
          align: 'right',
          description: 'Attributed View Through Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'costPerAttributedConversions',
        id: 'costPerAttributedConversions',
        header: 'Cost Per Attributed Conversions',
        meta: {
          align: 'right',
          description: 'Cost Per Attributed Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedConversionsRate',
        id: 'attributedConversionsRate',
        header: 'Attributed Conversions Rate',
        meta: {
          align: 'right',
          description: 'Attributed Conversions Rate',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor:
                  props.row.original.isExcluded || props.row.original.blocked
                    ? 'danger-500'
                    : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
    ],
  },
  {
    accessorKey: 'Actions',
    header: 'Allowlist/Blocklist',
    meta: {
      description: <AllowBlockTooltip listType="open exhanges" />,
      excludeColumnFromColumnVisibility: true,
      // @ts-ignore
      excludeColumnFromDownload: true,
    },
    cell: props => {
      return (
        <ListActionCell
          action={props.onAction}
          rowData={props.row?.original}
          meta={props.table.options.meta as BidModelTableMeta}
        />
      )
    },
    size: 140,
    enableResizing: false,
    enableSorting: false,
  },
]
