import * as React from 'react'
import {
  Box,
  Button,
  Col,
  Container,
  IconButton,
  LoadingButton,
  Menu,
  MenuItem,
  Row,
  Tab,
  Tabs,
  Tooltip,
  enqueueSnackbar,
} from '@applift/factor'
import {
  AdjustmentsHorizontal,
  Grid,
  AlertTriangle,
  EllipsisVertical,
} from '@applift/icons'
import { GridActionCellParams, SortingState } from '@applift/datagrid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'

import {
  CreativeGridWrapper,
  CreativeGridWrapperRefType,
} from './component/CreativeGrid/CreativeGridWrapper'
import { ModelledCreativeGridWrapper } from './component/ModelledCreativeGrid/ModelledCreativeGridWrapper'
import { TabPanel } from '../../components/TabPanel/index'
import { CreativeListDataType } from '../../hooks'
import {
  CreativeList,
  CreativeModelledListResponse,
  IncludeExcludeRequestData,
} from '../../models/Creative'
import { ACTION, ActionNameType } from './component/CreativeGrid/action'
import { useIncludeExcludeQueue } from '../../hooks/useIncludeExcludeQueue'
import {
  IUpdateRequest,
  includeExcludeFunc,
  updateCreativeModelling,
} from '../../api/creative'
import {
  CAMPAIGNS_RESTRICTION_CONDITION,
  SPENT_RATIO_METHOD,
  defaultOtherRow,
} from '../../constants/modelledTable'
import {
  DiscardModellingDialog,
  ModellingConfirmationDialog,
  ResetModellingDialog,
} from '../../components/Dialogs'
import { DateRange } from '../../models/Filter'
import { Timezone } from '../../models/Timezone'
import { getResetParam, getUpdateParam } from '../../utils/modellingHelper'
import { CampaignPriorityShiftInfo } from './component/PriorityShiftInfo/CampaignPriorityShiftInfo'
import {
  CAMPAIGN_BUDGET_TYPE,
  CAMPAIGN_STATUSES,
} from '../../constants/campaigns'
import { WithResponse } from '../../models/Response'
import { OptimizationType } from '../../services/RUMLogger/constants'

import styles from './styles.module.scss'

export interface CreativePageApiRefType {
  getCurrentData: () => CreativeModelledListResponse | undefined
  hasChange: () => boolean
  hasError: () => boolean
  getModelledCreativesPayload: () => IUpdateRequest
  setShowDiscardModelling: React.Dispatch<React.SetStateAction<boolean>>
}

interface AllCreativesTableProps {
  data: CreativeListDataType | undefined
  isFetching: boolean
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  sorting: SortingState
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
  allowBlockFilterValue: 'all' | 'allowed' | 'blocked'
  setAllowBlockFilterValue: React.Dispatch<
    React.SetStateAction<'all' | 'allowed' | 'blocked'>
  >
  totalCount?: number
  dateRange?: DateRange | null
  timezone: Timezone | null
  columnsToDisplay?: string[]
}

interface ModelledCreativesTableProps {
  data: CreativeModelledListResponse | undefined
  isFetching: boolean
  dimensionId: number
  totalCount?: number
  dateRange?: DateRange | null
  timezone: Timezone | null
  columnsToDisplay?: string[]
}

interface RequiredCampaignData {
  campaignId: number
  isBidShading: boolean
  budgetDay?: number
  startTime: number
  endTime: number
  maxDayImpressions?: number
  budgetTypeId: number
  campaignStatus: string
}

export interface CreativePageProps {
  app: 'bidModel' | 'campaign'
  campaignData: RequiredCampaignData
  dimensionId?: number
  visibleSection: 'All' | 'Modelled'
  setVisibleSection: (data: 'All' | 'Modelled') => void
  allCreativesTableProps: AllCreativesTableProps
  modelCreativesTableProps: ModelledCreativesTableProps
  onModellingChange?: (hasChange: boolean, hasError: boolean) => void
  onDialogAction?: (dialogState: boolean) => void
}

export const CREATIVE_TAB = {
  ALL: 'All',
  MODELLED: 'Modelled',
} as const

type SetModifiedModelledState = React.Dispatch<
  React.SetStateAction<CreativeModelledListResponse>
>

export const CreativePage = React.forwardRef<
  CreativePageApiRefType,
  CreativePageProps
>((props, ref) => {
  const {
    app,
    campaignData,
    allCreativesTableProps,
    modelCreativesTableProps,
    dimensionId = 1,
    visibleSection,
    setVisibleSection,
    onModellingChange,
    onDialogAction,
  } = props
  const {
    campaignId,
    isBidShading,
    endTime,
    startTime,
    budgetTypeId,
    budgetDay,
    campaignStatus,
    maxDayImpressions,
  } = campaignData

  const campaignDuration = moment(endTime * 1000).diff(startTime * 1000, 'days')

  const queryClient = useQueryClient()

  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false)
  const [confirmationMetaData, setConfirmationMeta] = React.useState<
    CreativeList[] | undefined
  >(undefined)
  const [modifiedModelledState, setModifiedModelledState] = React.useState<
    CreativeModelledListResponse | undefined
  >(modelCreativesTableProps.data)
  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    { data?: CreativeList[]; id: string; params: any }
  > | null>(null)
  const [hasChange, setHasChange] = React.useState<boolean>(false)
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [showDiscardModeling, setShowDiscardModelling] =
    React.useState<boolean>(false)

  const creativeTableRef = React.useRef<CreativeGridWrapperRefType>(null)

  const creativeTableCount = allCreativesTableProps?.totalCount || 0

  const modelledCreativeCount = modifiedModelledState?.total.totalCount || 0

  React.useEffect(() => {
    if (!modelCreativesTableProps.isFetching) {
      setModifiedModelledState(modelCreativesTableProps.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelCreativesTableProps.data, modelCreativesTableProps.isFetching])

  React.useEffect(() => {
    onModellingChange?.(hasChange, hasError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedModelledState, hasChange, hasError])

  React.useEffect(() => {
    onDialogAction?.(showDiscardModeling)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDiscardModeling])

  const isAllTableExcluded = React.useMemo(() => {
    if (allCreativesTableProps.allowBlockFilterValue === 'allowed') {
      return 0
    }
    if (allCreativesTableProps.allowBlockFilterValue === 'blocked') {
      return 1
    }
    return undefined
  }, [allCreativesTableProps.allowBlockFilterValue])

  const onIncludeExcludeSuccess = (
    res: WithResponse<string>,
    payload: IncludeExcludeRequestData
  ) => {
    const succeedIds = payload.ids.split(',')?.map((id: string) => parseInt(id))
    queryClient.setQueriesData(
      {
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getAllCreativeDimensionList',
      },
      (pages: any) => {
        const numberOfIds = succeedIds.length
        const updatedData = pages.data?.map((one: any) => {
          if (succeedIds.includes(one.id)) {
            return {
              ...one,
              ...{
                isExcluded: payload?.isExcluded,
              },
            }
          }
          return one
        })
        if (allCreativesTableProps.allowBlockFilterValue !== 'all') {
          const filteredRecord = updatedData.filter(
            (data: CreativeList) => data.isExcluded === isAllTableExcluded
          )
          return {
            ...pages,
            data: filteredRecord,
            totalRecords: pages.totalRecords - numberOfIds,
            filteredRecords: pages.filteredRecords - numberOfIds,
            total: {
              ...pages.total,
              totalCount: pages.total.totalCount - numberOfIds,
            },
          }
        }
        return {
          ...pages,
          data: updatedData,
        }
      }
    )
    queryClient.refetchQueries({
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getModelledCreativesList',
    })
    enqueueSnackbar(
      res?.data && typeof res?.data === 'string'
        ? res?.data
        : `The Creatives have been  ${
            payload?.isExcluded ? 'blocked' : 'allowed'
          }.`,
      {
        variant: 'success',
      }
    )
    if (action?.actionName === ACTION.TOGGLE_INCLUDE_EXCLUDE_BULK) {
      // Clearing row selection
      creativeTableRef.current?.setRowSelection({})
    }
  }

  const includeExcludeQueue = useIncludeExcludeQueue(
    includeExcludeFunc,
    campaignId!,
    dimensionId,
    {
      onIncludeExcludeSuccess: onIncludeExcludeSuccess,
      segment: OptimizationType.CREATIVE,
    }
  )

  const modelledTableCountWithOutOther = React.useMemo(() => {
    return modifiedModelledState?.data?.reduce?.((acc: any, curr: any) => {
      if (curr.id === -1) {
        return acc
      }
      return ++acc
    }, 0)
  }, [modifiedModelledState])

  const currentSpendRatioMethod = React.useMemo(() => {
    return (
      modifiedModelledState?.data[0]?.spendRatioTypeId ||
      SPENT_RATIO_METHOD.FLEXIBLE
    )
  }, [modifiedModelledState])

  const defaultBidValue = React.useMemo(() => {
    if (currentSpendRatioMethod === SPENT_RATIO_METHOD.FLEXIBLE) {
      return {
        bidMultiplier: 1.0,
        priority: 1,
        spendRatio: 100,
        spendRatioTypeId: SPENT_RATIO_METHOD.FLEXIBLE,
      }
    }
    if (currentSpendRatioMethod === SPENT_RATIO_METHOD.STRICT) {
      return {
        bidMultiplier: 1.0,
        priority: 0,
        spendRatio: 100,
        spendRatioTypeId: SPENT_RATIO_METHOD.STRICT,
      }
    }
  }, [currentSpendRatioMethod])

  const alreadyAddedCreative = React.useMemo(
    () => modifiedModelledState?.data?.map(row => row.id),
    [modifiedModelledState]
  )

  const defaultOtherspentRatio = () => {
    const rowSelection = creativeTableRef.current?.rowSelection || {}
    const selectedId = Object.keys(rowSelection)?.map(id => parseInt(id))
    const nonModelledNonSelectedData = allCreativesTableProps.data?.data.filter(
      data =>
        !(
          alreadyAddedCreative?.includes(data.id) ||
          selectedId.includes(data.id)
        )
    )

    return nonModelledNonSelectedData?.reduce((acc, curr) => {
      return acc + Number(curr?.spentPercentage || 0)
    }, 0)
  }
  const getOtherRow = () => {
    const defaultFlexibleBidModeldata = {
      priority: 10,
      bidMultiplier: 1.0,
      spendRatio: 100.0,
      spendRatioTypeId: 1,
    }
    const defaultStrictBidModeldata = {
      priority: 10,
      bidMultiplier: 1.0,
      spendRatio: defaultOtherspentRatio(),
      spendRatioTypeId: 2,
    }
    if (currentSpendRatioMethod === SPENT_RATIO_METHOD.FLEXIBLE) {
      return { ...defaultOtherRow, ...defaultFlexibleBidModeldata }
    }
    if (currentSpendRatioMethod === SPENT_RATIO_METHOD.STRICT) {
      return { ...defaultOtherRow, ...defaultStrictBidModeldata }
    }
    return defaultOtherRow
  }

  // Handling the other row insertion and removal
  React.useEffect(() => {
    setModifiedModelledState(prev => {
      const otherRowAvailable = prev?.data.some(row => row.id === -1)
      // Adding other row
      if (
        prev?.total?.totalCount &&
        prev.total.totalCount < creativeTableCount &&
        !otherRowAvailable
      ) {
        const prevTotalObj = prev?.total
        const otherRow = getOtherRow()
        return {
          ...prev,
          total: {
            ...prevTotalObj,
            totalCount: (prevTotalObj?.totalCount as number) + 1,
          },
          data: [...prev.data, otherRow],
        } as CreativeModelledListResponse
      }
      // Removing other row if All the creative have been added
      if (
        modelledTableCountWithOutOther === creativeTableCount &&
        creativeTableCount !== 0 &&
        otherRowAvailable
      ) {
        const prevTotalObj = prev?.total
        return {
          ...prev,
          total: {
            ...prevTotalObj,
            totalCount: (prevTotalObj?.totalCount as number) - 1,
          },
          data: prev?.data.filter(item => !(item.id === -1)),
        } as CreativeModelledListResponse
      }
      // removing other if every creative is removed
      if (modelledTableCountWithOutOther === 0 && otherRowAvailable) {
        const prevTotalObj = prev?.total
        return {
          ...prev,
          total: {
            ...prevTotalObj,
            totalCount: (prevTotalObj?.totalCount as number) - 1,
          },
          data: prev?.data.filter(item => !(item.id === -1)),
        } as CreativeModelledListResponse
      }
      return prev
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modifiedModelledState,
    creativeTableCount,
    modelledTableCountWithOutOther,
  ])

  React.useEffect(() => {
    let spendRatioTotalSum = 0
    modifiedModelledState?.data.forEach(row => {
      spendRatioTotalSum +=
        typeof row?.spendRatio === 'number' ? row?.spendRatio : 0
    })
    const hasTotalError =
      currentSpendRatioMethod === SPENT_RATIO_METHOD.FLEXIBLE
        ? spendRatioTotalSum < 100
        : spendRatioTotalSum !== 100

    const lessThenSpent = !!modifiedModelledState?.data.some(
      row =>
        row.spendRatio &&
        row.spentPercentage &&
        row.spendRatio < row.spentPercentage
    )

    const isAllDeleted = modifiedModelledState?.data.length === 0
    if (isAllDeleted) {
      setHasError(false)
    } else {
      setHasError(hasTotalError || lessThenSpent)
    }
  }, [modifiedModelledState, currentSpendRatioMethod])

  const maxBid = modifiedModelledState?.campaignBidDetails?.maxBid || 0

  const addToModelling = (newData: CreativeList[]) => {
    setModifiedModelledState(prev => {
      const previousData = prev?.data
      const filterAddedData = newData?.filter(
        data => !alreadyAddedCreative?.includes(data.id)
      )
      const newAddedData = filterAddedData?.map(data => ({
        ...data,
        ...defaultBidValue,
        ...(currentSpendRatioMethod === SPENT_RATIO_METHOD.STRICT
          ? { spendRatio: data?.spentPercentage }
          : {}),
      }))

      let newCount = prev?.total?.totalCount || 0
      if (newAddedData?.length) {
        newCount += newAddedData.length
      }
      if (
        allCreativesTableProps.totalCount !==
        // @ts-ignore
        newAddedData?.length + modelCreativesTableProps?.totalCount
      ) {
        const otherAleadyAvailable = alreadyAddedCreative?.includes(-1)
        return {
          ...prev,
          data: [
            ...(newAddedData?.length ? newAddedData : []),
            ...(previousData?.length ? previousData : []),
            ...(!otherAleadyAvailable ? [getOtherRow()] : []),
          ],
          total: {
            ...prev?.total,
            ...{
              totalCount: otherAleadyAvailable ? newCount : newCount + 1,
            }, // Adding 1 for the other row
          },
          campaignBidDetails: {
            ...prev?.campaignBidDetails,
            ...(!prev?.campaignBidDetails.baseBid
              ? { baseBid: maxBid || null }
              : {}),
          },
        } as CreativeModelledListResponse
      }

      return {
        ...prev,
        data: [
          ...(newAddedData?.length ? newAddedData : []),
          ...(previousData?.length ? previousData : []),
        ],
        total: {
          ...prev?.total,
          ...{ totalCount: newCount },
        },
        campaignBidDetails: {
          ...prev?.campaignBidDetails,
          ...(!prev?.campaignBidDetails.baseBid
            ? { baseBid: maxBid || null }
            : {}),
        },
      } as CreativeModelledListResponse
    })
    setVisibleSection(CREATIVE_TAB.MODELLED)
  }

  const validCampaign = React.useMemo(() => {
    if (!endTime) {
      return false
    }
    if (campaignDuration < CAMPAIGNS_RESTRICTION_CONDITION.MIN_DURATION_DAYS) {
      return false
    }
    if (
      budgetTypeId === CAMPAIGN_BUDGET_TYPE.DOLLAR &&
      typeof budgetDay === 'number' &&
      budgetDay < CAMPAIGNS_RESTRICTION_CONDITION.MIN_DAILY_BUDGET_DOLLAR
    ) {
      return false
    }
    if (
      budgetTypeId === CAMPAIGN_BUDGET_TYPE.IMPRESSIONS &&
      typeof maxDayImpressions === 'number' &&
      maxDayImpressions <
        CAMPAIGNS_RESTRICTION_CONDITION.MIN_DAILY_BUDGET_IMPRESSION
    ) {
      return false
    }
    return true
  }, [campaignDuration, budgetDay, budgetTypeId, maxDayImpressions, endTime])

  const disableModelledCreativeTab = React.useMemo(() => {
    if (allCreativesTableProps.totalCount === 0) {
      return true
    }
    if (!validCampaign && modelledCreativeCount === 0) {
      return true
    }
    if (
      campaignStatus === CAMPAIGN_STATUSES.EXPIRED &&
      modelledCreativeCount === 0
    ) {
      return true
    }
    return false
  }, [
    allCreativesTableProps.totalCount,
    validCampaign,
    modelledCreativeCount,
    campaignStatus,
  ])

  const modelledCreativeTabTooltip = React.useMemo(() => {
    if (allCreativesTableProps.totalCount === 0) {
      return 'Atleast 1 creative is required to apply advanced modelling'
    }
    if (!validCampaign && modelledCreativeCount === 0) {
      return budgetTypeId === 1
        ? 'Advanced modelling is only available for  campaigns with a daily budget exceeding $100 and a duration of at least 3 days.'
        : 'Advanced modelling is only available for campaigns with daily impressions exceeding 1000 and a duration of at least 3 days.'
    }
    return ''
  }, [
    allCreativesTableProps.totalCount,
    validCampaign,
    budgetTypeId,
    modelledCreativeCount,
  ])

  const ModelledTabTitle = () => {
    const tooltipTitle =
      budgetTypeId === CAMPAIGN_BUDGET_TYPE.DOLLAR
        ? 'For optimal effectiveness of the campaign modelling, it is recommended to allocate a daily budget of $100, with a campaign duration of at least 3 days.'
        : 'For optimal effectiveness of the campaign modelling, it is recommended to allocate a daily budget of 1000 impressions, with a campaign duration of at least 3 days.'
    return (
      <>
        Modelled Creatives
        {typeof modelledCreativeCount === 'number'
          ? ` (${modelledCreativeCount})`
          : ''}
        <>
          {visibleSection === 'Modelled' &&
          !validCampaign &&
          !['deleted', 'expired'].includes(campaignStatus) ? (
            <>
              <Tooltip title={tooltipTitle} placement="top" arrow>
                <AlertTriangle
                  color="warning"
                  sx={{ ml: 8, textColor: 'warning-400' }}
                />
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </>
      </>
    )
  }

  // Handling table actions
  React.useEffect(() => {
    switch (action?.actionName) {
      case ACTION.TOGGLE_INCLUDE_EXCLUDE_INLINE:
      case ACTION.TOGGLE_INCLUDE_EXCLUDE_BULK: {
        const actionRowIds = action?.metaData?.id?.split(',')
        if (actionRowIds.length) {
          actionRowIds.forEach(id => {
            includeExcludeQueue.enqueue(id, action.metaData.params)
          })
        }
        break
      }
      case ACTION.ADD_TO_MODELLING: {
        if (isBidShading && app === 'bidModel') {
          setShowConfirmation(true)
          setConfirmationMeta(action?.metaData?.data)
        } else {
          if (action?.metaData?.data) {
            addToModelling(action?.metaData?.data)
          }
        }
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, isBidShading])

  const updateMutation = useMutation(updateCreativeModelling, {
    mutationKey: ['updateModelling'],
    onSuccess: res => {
      queryClient.refetchQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getModelledCreativesList',
      })
      queryClient.refetchQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getAllCreativeDimensionList',
      })
      queryClient.refetchQueries({
        predicate: (query: any) => {
          return (
            query.queryKey?.[0]?.scope === 'getCampaignDetailedInfo' &&
            query.queryKey?.[0]?.id === campaignId
          )
        },
      })
      enqueueSnackbar(res.data || 'Updated successfully', {
        variant: 'success',
      })
    },
    onError: res => {
      // @ts-ignore
      enqueueSnackbar(res?.errorObjects[0]?.error || 'Something went wrong.', {
        variant: 'error',
      })
    },
  })

  const updateParams = React.useMemo<IUpdateRequest>(() => {
    const params = getUpdateParam(
      modelCreativesTableProps?.data as CreativeModelledListResponse,
      modifiedModelledState as CreativeModelledListResponse
    )
    return params
  }, [modelCreativesTableProps, modifiedModelledState])

  React.useImperativeHandle(ref, () => ({
    getCurrentData: () => modifiedModelledState,
    hasChange: () => hasChange,
    hasError: () => hasError,
    getModelledCreativesPayload: () => updateParams,
    setShowDiscardModelling: setShowDiscardModelling,
  }))

  const resetParams = React.useMemo<IUpdateRequest>(() => {
    const params = getResetParam(
      modelCreativesTableProps?.data as CreativeModelledListResponse
    )
    return {
      ...(params.length ? { bidModelRequests: params } : {}),
    }
  }, [modelCreativesTableProps.data])

  React.useEffect(() => {
    const updatedField = Object.keys(updateParams)
    if (updatedField.length) {
      setHasChange(true)
    }
    if (updatedField.length === 0) {
      setHasChange(false)
    }
  }, [updateParams])

  const onUpdate = React.useCallback(() => {
    updateMutation.mutate({
      campaignId,
      params: updateParams,
    })
  }, [campaignId, updateMutation, updateParams])

  const onReset = React.useCallback(() => {
    updateMutation.mutate({
      campaignId,
      params: resetParams,
    })
  }, [campaignId, updateMutation, resetParams])

  const disableUpdate = React.useMemo(() => {
    if (hasError) {
      return true
    }
    if (!hasChange) {
      return true
    }
    return updateMutation.isLoading || !hasChange
  }, [updateMutation, hasChange, hasError])

  const disableCancel = React.useMemo(() => {
    if (!hasChange) {
      return true
    }
    return updateMutation.isLoading || !hasChange
  }, [updateMutation, hasChange])

  const ioActionsMenuAnchor = React.useRef<HTMLButtonElement>(null)
  const [isIoActionsMenuOpen, setIsIoActionsMenuOpen] = React.useState(false)
  const [showResetModeling, setShowResetModelling] =
    React.useState<boolean>(false)

  const discardChanged = () => {
    setModifiedModelledState(modelCreativesTableProps.data)
  }

  return (
    <Container className={styles.container}>
      <Row>
        <Col xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              display: 'flex',
              justifyContent: 'between',
            }}
          >
            <>
              <Tabs
                value={visibleSection === CREATIVE_TAB.ALL ? 0 : 1}
                onChange={(_, value) => {
                  if (
                    visibleSection === 'Modelled' &&
                    hasChange &&
                    app === 'bidModel'
                  ) {
                    setShowDiscardModelling(true)
                    return
                  }
                  setVisibleSection(
                    value === 0 ? CREATIVE_TAB.ALL : CREATIVE_TAB.MODELLED
                  )
                }}
                size="large"
              >
                <Tab
                  icon={<Grid />}
                  iconPosition="start"
                  label={`All Creatives${
                    typeof allCreativesTableProps.totalCount === 'number'
                      ? ` (${allCreativesTableProps.totalCount})`
                      : ''
                  }`}
                  id={`tab-${0}`}
                  aria-controls={`tabpanel-${0}`}
                />
                <Tooltip
                  title={modelledCreativeTabTooltip}
                  arrow
                  placement="top"
                >
                  <Tab
                    disabled={disableModelledCreativeTab}
                    classes={{ root: styles.tab, disabled: styles.disabled }}
                    icon={<AdjustmentsHorizontal />}
                    iconPosition="start"
                    label={<ModelledTabTitle />}
                    id={`tab-${1}`}
                    aria-controls={`tabpanel-${1}`}
                  />
                </Tooltip>
              </Tabs>
            </>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              {app === 'bidModel' && (
                <CampaignPriorityShiftInfo campaignId={campaignId} />
              )}
              {visibleSection === CREATIVE_TAB.MODELLED &&
                app === 'bidModel' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      ml: 12,
                    }}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setShowDiscardModelling(true)
                      }}
                      size="small"
                      disabled={disableCancel}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={updateMutation.isLoading}
                      variant="contained"
                      onClick={onUpdate}
                      size="small"
                      disabled={disableUpdate}
                    >
                      Update Changes
                    </LoadingButton>
                    <>
                      <IconButton
                        size="small"
                        color="primary"
                        ref={ioActionsMenuAnchor}
                        onClick={() => setIsIoActionsMenuOpen(true)}
                        sx={{ ml: 8 }}
                      >
                        <EllipsisVertical />
                      </IconButton>
                      <Menu
                        anchorEl={ioActionsMenuAnchor.current}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={isIoActionsMenuOpen}
                        onClose={() => setIsIoActionsMenuOpen(false)}
                        sx={{ mt: 4 }}
                        PaperProps={{
                          sx: { borderColor: 'primary-600' },
                          variant: 'outlined',
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setShowResetModelling(true)
                            setIsIoActionsMenuOpen(false)
                          }}
                          sx={{ pr: 32 }}
                          disabled={
                            !modelledCreativeCount ||
                            ['deleted', 'expired'].includes(campaignStatus) ||
                            !modelCreativesTableProps.data?.data.length
                          }
                        >
                          Reset Modelling
                        </MenuItem>
                      </Menu>
                    </>
                  </Box>
                )}
            </Box>
          </Box>
        </Col>
      </Row>

      <TabPanel
        value={visibleSection === CREATIVE_TAB.ALL ? 0 : 1}
        index={0}
        sx={{
          height: 100,
          overflow: 'auto',
        }}
      >
        <CreativeGridWrapper
          ref={creativeTableRef}
          modelledCreativeCount={modelledCreativeCount}
          validCampaign={validCampaign}
          budgetTypeId={budgetTypeId}
          campaignId={campaignId}
          currentApp={app}
          dimensionId={dimensionId}
          setAction={setAction}
          campaignStatus={campaignStatus}
          includeExcludeQueue={includeExcludeQueue}
          {...allCreativesTableProps}
        />
      </TabPanel>
      <TabPanel
        value={visibleSection === CREATIVE_TAB.ALL ? 0 : 1}
        index={1}
        sx={{
          height: 100,
          overflow: 'auto',
        }}
      >
        <ModelledCreativeGridWrapper
          modifiedModelledData={
            modifiedModelledState as CreativeModelledListResponse
          }
          setModifiedModelledData={
            setModifiedModelledState as SetModifiedModelledState
          }
          campaignId={campaignId}
          currentApp={app}
          campaignStatus={campaignStatus}
          {...{
            ...modelCreativesTableProps,
          }}
        />
      </TabPanel>
      {showConfirmation && (
        <ModellingConfirmationDialog
          onCompletion={() => {
            if (confirmationMetaData) {
              addToModelling(confirmationMetaData)
            }
            setShowConfirmation(false)
          }}
          closeDialog={() => {
            setShowConfirmation(false)
          }}
        />
      )}
      {showResetModeling && (
        <ResetModellingDialog
          onClose={() => {
            setShowResetModelling(false)
          }}
          onReset={() => {
            onReset()
            setShowResetModelling(false)
          }}
        />
      )}
      {showDiscardModeling && (
        <DiscardModellingDialog
          onClose={() => {
            setShowDiscardModelling(false)
          }}
          onDiscard={() => {
            discardChanged()
            setShowDiscardModelling(false)
          }}
        />
      )}
    </Container>
  )
})

CreativePage.displayName = 'CreativePage'
