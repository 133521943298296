import * as React from 'react'
import {
  Row,
  Col,
  TextField,
  IconButton,
  Button,
  Chip,
  Tooltip,
  Box,
  Typography,
} from '@applift/factor'
import { Search, Close, CaretDown, CaretUp, Delete } from '@applift/icons'
import {
  RowSelectionState,
  ColumnVisibility,
  VisibilityState,
  ColumnDef,
  GridActionCellParams,
} from '@applift/datagrid'

import { useDownloadModelledDimensionList } from '../../../../hooks'
import { AllowBlockFilter } from '../../../../components/AllowBlockFilter'
import {
  CreativeList,
  CreativeModelledListResponse,
} from '../../../../models/Creative'
import { ACTION } from './action'
import { ButtonMenu } from '../../../../components/ButtonMenu'
import { DiscardOrDeletionDialog } from '../../../../components/Dialogs/index'
import { SPENT_RATIO_METHOD } from '../../../../constants/modelledTable'
import { DateRange } from '../../../../models/Filter'
import { Timezone } from '../../../../models/Timezone'
import { BaseBidAction } from './BaseBidAction'
import { CAMPAIGN_STATUSES } from '../../../../constants/campaigns'
import { DEFAULT_CREATIVE_LIST_SORTING } from '../../../../constants/creative'
import { DownloadTableAction } from '../../../../components/DownloadTableAction'
import { RUMLogger } from '../../../../services/RUMLogger'
import {
  OptimizationType,
  RUM_ACTION_ATTRIBUTE,
} from '../../../../services/RUMLogger/constants'

interface ActionPanelProps {
  apiRef: any
  colDef: ColumnDef<CreativeList>[]
  search: string
  setSearch: (value: string) => void
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  selectedItems: any[]
  isTableLoading: boolean
  columnVisibility: VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  allowBlockFilterValue: 'all' | 'allowed' | 'blocked'
  setAllowBlockFilterValue: React.Dispatch<
    React.SetStateAction<'all' | 'allowed' | 'blocked'>
  >
  defaultData: [any[], VisibilityState]
  defaultDeselectedColumns: VisibilityState
  totalRecords: number
  onAction: (params: GridActionCellParams<any, any>) => void
  loadingIncludeExclude: string[]
  setDialogToShow: React.Dispatch<
    React.SetStateAction<'bidModifier' | 'spendRatio' | 'priority' | null>
  >
  dimensionId: number
  setModifiedModelledData?: React.Dispatch<
    React.SetStateAction<CreativeModelledListResponse>
  >
  isViewOnly?: boolean
  modifiedModelledData: CreativeModelledListResponse
  selectedCampaignId: number | null
  selectedDateRange?: DateRange | null
  selectedTimezone?: Timezone | null
  showColumnSelector: boolean
  maxBid?: number
  baseBidInputRef: React.RefObject<HTMLInputElement>
  baseBidPrice?: number | null
  currentApp: 'bidModel' | 'campaign' | 'customers'
  campaignStatus: string
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    apiRef,
    colDef,
    search,
    setSearch,
    rowSelection,
    selectedItems,
    setRowSelection,
    isTableLoading,
    columnVisibility,
    setColumnVisibility,
    defaultData,
    defaultDeselectedColumns,
    allowBlockFilterValue,
    setAllowBlockFilterValue,
    totalRecords,
    onAction,
    loadingIncludeExclude,
    setDialogToShow,
    dimensionId,
    setModifiedModelledData,
    isViewOnly = false,
    modifiedModelledData,
    selectedCampaignId,
    selectedDateRange,
    selectedTimezone,
    showColumnSelector,
    maxBid,
    baseBidInputRef,
    baseBidPrice,
    currentApp,
    campaignStatus,
  } = props

  const [currentDownloadFileType, setCurrentDownloadFileType] = React.useState<
    'xlsx' | 'csv' | null
  >(null)
  const [actionsAnchorEl, setActionsAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [isActionsMenuOpen, modifyActionsMenuVisibility] = React.useState(false)
  const [isRemoveModellingEnabled, toggleRemoveModellingState] =
    React.useState(false)

  const [showSearch, setShowSearch] = React.useState<boolean>(false)
  const searchFieldRef = React.useRef<HTMLInputElement>(null)

  const selectedItemsCount = React.useMemo(
    () => Object.keys(rowSelection).length,
    [rowSelection]
  )

  const chipLabel = React.useMemo(() => {
    return `${selectedItemsCount} out of ${totalRecords} selected`
  }, [selectedItemsCount, totalRecords])

  const isOnlyExcludedSelected = React.useMemo(
    () => selectedItems.every(item => item.isExcluded === 1),
    [selectedItems]
  )

  const isOnlyIncludedSelected = React.useMemo(
    () => selectedItems.every(item => item.isExcluded === 0),
    [selectedItems]
  )

  const blockBtnTooltipMsg = React.useMemo(() => {
    return ''
  }, [])

  const unblockButtonTooltip = React.useMemo(() => {
    return ''
  }, [])

  const hideAnchorMenu = () => {
    setActionsAnchorEl(null)
    modifyActionsMenuVisibility(false)
  }

  const MODELLING_ACTIONS = [
    {
      label: 'Set Priority',
      onClick: () => {
        hideAnchorMenu()
        setDialogToShow?.('priority')
      },
      disabled:
        modifiedModelledData?.data?.[0]?.spendRatioTypeId ===
        SPENT_RATIO_METHOD.STRICT,
    },
    {
      label: 'Set Spend Ratio',
      onClick: () => {
        hideAnchorMenu()
        setDialogToShow?.('spendRatio')
      },
    },
    {
      label: 'Set Bid Modifier',
      onClick: () => {
        hideAnchorMenu()
        setDialogToShow?.('bidModifier')
      },
      disabled: !baseBidPrice,
    },
  ]

  const setColumnVisibilityWrapper = (value: any) => {
    setColumnVisibility(value)
  }

  const includeExclude = (isExcluded: 0 | 1) => {
    const ids = Object.keys(rowSelection).join(',')
    onAction?.({
      actionName: ACTION.TOGGLE_INCLUDE_EXCLUDE_BULK,
      metaData: {
        id: ids,
        params: {
          campaignId: selectedCampaignId,
          ids: ids,
          isExcluded: isExcluded,
        },
      },
    })
  }

  const onDownloadSuccess = (res: any) => {
    const downloadUrl = res.data?.url
    if (downloadUrl) {
      window.open(downloadUrl)
    }
  }

  const onDownloadSettled = () => {
    setCurrentDownloadFileType(null)
  }

  const downloadMutation = useDownloadModelledDimensionList({
    onSuccess: onDownloadSuccess,
    onSettled: onDownloadSettled,
  })

  const colList: { label: string; value: string }[] = React.useMemo(() => {
    const list: { label: string; value: string }[] = []
    colDef.forEach(data => {
      // @ts-ignore
      if (data?.columns?.length) {
        // @ts-ignore
        data?.columns?.forEach((row: any) => {
          const title =
            typeof row.header === 'string' ? row.header : row.meta.headerTitle
          if (!row?.meta?.excludeColumnFromDownload) {
            // @ts-ignore
            list.push({ label: title, value: row.accessorKey })
          }
        })
      } else {
        // @ts-ignore
        if (!data?.meta?.excludeColumnFromDownload) {
          list.push({
            // @ts-ignore
            label:
              typeof data.header === 'string'
                ? data.header
                : data?.meta?.headerTitle,
            // @ts-ignore
            value: data.accessorKey,
          })
        }
      }
    })
    return list
  }, [colDef])

  const column = React.useMemo(() => {
    return colList.filter(col => {
      if (
        typeof columnVisibility[col?.value] === 'boolean' &&
        columnVisibility[col?.value]
      ) {
        return true
      }
      if (
        Object.prototype.hasOwnProperty.call(
          defaultDeselectedColumns,
          col?.value
        )
      ) {
        return false
      }
      return true
    })
  }, [columnVisibility, colList, defaultDeselectedColumns])

  const downloadfile = (fileType: 'xlsx' | 'csv') => {
    const { sorting } = apiRef.current.tableInstance.getState()
    const sortingState = sorting.length
      ? sorting[0]
      : DEFAULT_CREATIVE_LIST_SORTING[0]
    downloadMutation.mutate({
      campaignId: selectedCampaignId as number,
      startDate: selectedDateRange?.start as number,
      endDate: selectedDateRange?.end as number,
      timezoneId: selectedTimezone?.id as number,
      sortBy: `${sortingState?.desc ? '-' : '+'}${sortingState?.id}`,
      searchField: search,
      total_count: totalRecords,
      fileType,
      columns: column,
      download: true,
      calledApp: currentApp !== 'bidModel' ? currentApp : undefined,
      dimensionId,
      ...(allowBlockFilterValue !== 'all'
        ? { isExcluded: allowBlockFilterValue === 'allowed' ? 0 : 1 }
        : {}),
    })
  }

  const modelledTableCountWithOutOther = React.useMemo(() => {
    return modifiedModelledData?.data?.reduce?.((acc: any, curr: any) => {
      if (curr.id === -1) {
        return acc
      }
      return ++acc
    }, 0)
  }, [modifiedModelledData])

  const removeModelling = () => {
    const selectedIds = Object.keys(rowSelection)
    const isAllCreativeSelected =
      selectedIds.length >= modelledTableCountWithOutOther
    setModifiedModelledData?.(prev => {
      const removedIds = Object.keys(rowSelection)
      return {
        ...prev,
        total: {
          ...prev.total,
          totalCount: (prev.total.totalCount as number) - removedIds.length,
        },
        data: prev.data.filter(
          item => !Object.keys(rowSelection).includes(item.id.toString())
        ),
        ...(isAllCreativeSelected
          ? {
              campaignBidDetails: { ...prev.campaignBidDetails, baseBid: null },
            }
          : {}),
      }
    })
    setRowSelection({})
    toggleRemoveModellingState(false)

    RUMLogger.AdvancedModelling.removeFromModelling({
      campaignId: selectedCampaignId,
      ids: selectedIds.map(idStr => Number.parseInt(idStr, 10)),
      dimension: OptimizationType.CREATIVE,
    })
  }

  const selectedIds = Object.keys(rowSelection)
  const isAllCreativeSelected =
    selectedIds.length >= modelledTableCountWithOutOther

  const removeModellingContent = (
    <Box>
      {isAllCreativeSelected ? (
        <>
          <Typography component="p" variant="bodyMedium" sx={{ mb: 12 }}>
            Modelling will be removed from all the creatives. Are you sure you
            want to remove?
          </Typography>
          <Typography component="p" variant="bodyMedium">
            Please save the changes to apply latest updates.
          </Typography>
        </>
      ) : (
        <>
          <Typography component="p" variant="bodyMedium" sx={{ mb: 12 }}>
            {selectedItemsCount === 1
              ? `Modelling will be removed from this creative. Are you sure you want to remove ?`
              : `Modelling will be removed from ${selectedItemsCount} creatives. Are you sure you want to remove ?`}
          </Typography>
          <Typography component="p" variant="bodyMedium">
            Please save the changes to apply latest updates.
          </Typography>
        </>
      )}
    </Box>
  )

  const isDeletedOrRejectedSelected = selectedItems.some(item =>
    ['deleted', 'rejected'].includes(item.creativeStatus?.toLowerCase())
  )

  const isOthersRowSelected = selectedItems.some(item => item.id === -1)
  const isAllItemsSelected =
    modifiedModelledData?.data.length === selectedItems.length

  const disableRemoveModelling = isOthersRowSelected && !isAllItemsSelected

  const isCampaignExpiredOrDeleted = [
    CAMPAIGN_STATUSES.EXPIRED,
    CAMPAIGN_STATUSES.DELETED,
  ].includes(campaignStatus)

  const isBaseBidEditDisabled = isCampaignExpiredOrDeleted || isViewOnly

  return (
    <Box sx={{ px: 12 }}>
      <Row
        sx={{
          alignItems: 'center',
          width: 100,
          justifyContent:
            selectedItemsCount > 0 && !isTableLoading ? 'between' : 'end',
        }}
      >
        {selectedItemsCount > 0 && !isTableLoading && (
          <Col xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              size="small"
              label={chipLabel}
              color="secondary"
              onDelete={() => {
                setRowSelection({})
              }}
            />
            <Tooltip
              placement="top"
              arrow
              title={
                isOthersRowSelected && !isAllItemsSelected
                  ? `"Others" row cannot be removed from the modelling, as it serves as a fallback mechanism.`
                  : ''
              }
            >
              <Box sx={{ display: 'inline-flex' }}>
                <Button
                  size="small"
                  color="error"
                  variant="outlined"
                  disabled={disableRemoveModelling}
                  startIcon={<Delete />}
                  sx={{ marginLeft: 8 }}
                  onClick={
                    currentApp === 'bidModel'
                      ? () => toggleRemoveModellingState(true)
                      : removeModelling
                  }
                  {...{ [RUM_ACTION_ATTRIBUTE]: 'Remove from Modelling' }}
                >
                  Remove modelling
                </Button>
              </Box>
            </Tooltip>
            {currentApp === 'bidModel' && (
              <Box sx={{ display: 'inline-flex' }}>
                <Button
                  size="small"
                  disabled={isDeletedOrRejectedSelected}
                  color="primary"
                  variant="outlined"
                  endIcon={isActionsMenuOpen ? <CaretUp /> : <CaretDown />}
                  sx={{ marginLeft: 8 }}
                  onClick={e => {
                    setActionsAnchorEl(e.currentTarget)
                    modifyActionsMenuVisibility(true)
                  }}
                >
                  Actions
                </Button>
                <ButtonMenu
                  anchorEl={actionsAnchorEl}
                  buttons={MODELLING_ACTIONS}
                  onClose={() => {
                    modifyActionsMenuVisibility(false)
                  }}
                  open={isActionsMenuOpen}
                />
              </Box>
            )}
            {isOnlyIncludedSelected && (
              <Tooltip arrow placement="top" title={blockBtnTooltipMsg}>
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={Boolean(loadingIncludeExclude.length)}
                    sx={{ marginLeft: 8 }}
                    onClick={() => {
                      includeExclude(1)
                    }}
                  >
                    Block
                  </Button>
                </Box>
              </Tooltip>
            )}
            {isOnlyExcludedSelected && (
              <Tooltip arrow placement="top" title={unblockButtonTooltip}>
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ marginLeft: 8 }}
                    onClick={() => {
                      includeExclude(0)
                    }}
                    disabled={Boolean(loadingIncludeExclude.length)}
                  >
                    Unblock
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Col>
        )}
        <Col
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            my: 16,
          }}
        >
          <Row
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
            }}
          >
            <BaseBidAction
              isViewOnly={isBaseBidEditDisabled}
              maxBid={maxBid}
              baseBidPrice={baseBidPrice}
              baseBidDisable={totalRecords === 0}
              setModifiedModelledData={setModifiedModelledData}
              baseBidInputRef={baseBidInputRef}
            />
            <Col sx={{ p: 0 }}>
              {!showSearch ? (
                <IconButton
                  sx={{ ml: 8 }}
                  color="secondary"
                  onClick={() => {
                    setShowSearch(prev => !prev)
                    setTimeout(() => {
                      searchFieldRef.current?.focus()
                    }, 0)
                  }}
                >
                  <Search sx={{ textColor: 'neutral-400' }} />
                </IconButton>
              ) : (
                <TextField
                  type="text"
                  value={search}
                  onChange={(e: any) => {
                    setSearch(e.target.value)
                  }}
                  inputRef={searchFieldRef}
                  placeholder="Search by ID, Name"
                  variant="outlinedDash"
                  InputProps={{
                    startAdornment: (
                      <Search sx={{ textColor: 'neutral-400' }} />
                    ),
                    endAdornment:
                      search?.length > 0 ? (
                        <IconButton
                          onClick={() => {
                            setSearch('')
                            setShowSearch(false)
                          }}
                          size="small"
                        >
                          <Close fontSize={16} />
                        </IconButton>
                      ) : null,
                  }}
                  inputProps={{
                    onBlurCapture: () => {
                      if (!search) {
                        setShowSearch(false)
                      }
                    },
                  }}
                />
              )}
            </Col>
            <Col xs={'auto'} sx={{ paddingX: 0 }}>
              {!isViewOnly && (
                <DownloadTableAction
                  downloadfile={downloadfile}
                  isDownloading={downloadMutation.isLoading}
                  currentDownloadFileType={currentDownloadFileType}
                  setCurrentDownloadFileType={setCurrentDownloadFileType}
                />
              )}
            </Col>
            <Col xs={'auto'} sx={{ paddingX: 0 }}>
              <AllowBlockFilter
                selectedValue={allowBlockFilterValue}
                setSelectedValue={setAllowBlockFilterValue}
              />
            </Col>
            {showColumnSelector && (
              <Col xs={'auto'} sx={{ paddingX: 0 }}>
                <ColumnVisibility
                  data={defaultData[0]}
                  onChange={setColumnVisibilityWrapper}
                  value={columnVisibility}
                  slotProps={{
                    SelectAdvancePopoverProps: {
                      slotProps: {
                        PopoverProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                          },
                        },
                      },
                    },
                    IconButtonProps: { color: 'secondary' },
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {isRemoveModellingEnabled && (
        <DiscardOrDeletionDialog
          primaryBtnText="Remove"
          onDiscardOrDelete={removeModelling}
          closeDialog={() => toggleRemoveModellingState(false)}
          title="Remove Modelling"
          dialogContent={removeModellingContent}
          primaryBtnRumLabel="Confirm Remove from Modelling"
        />
      )}
    </Box>
  )
}
