import React from 'react'
import { CurrencyDollar, InfoCircle } from '@applift/icons'
import {
  Col,
  Typography,
  Tooltip,
  TextField,
  Alert,
  Box,
} from '@applift/factor'

import { currencyFormatter, formatCurrency } from '../../../../utils'
import { REGEX_POSITIVE_FLOAT } from '../../../../constants/regex'
import { CreativeModelledListResponse } from '../../../../models/Creative'

import styles from './styles.module.scss'

interface IBaseBidAction {
  maxBid?: number
  baseBidPrice?: number | null
  baseBidDisable?: boolean
  baseBidInputRef: React.RefObject<HTMLInputElement>
  isViewOnly?: boolean
  setModifiedModelledData?: React.Dispatch<
    React.SetStateAction<CreativeModelledListResponse>
  >
}

export const BaseBidAction = (props: IBaseBidAction) => {
  const {
    maxBid,
    baseBidPrice,
    baseBidInputRef,
    setModifiedModelledData,
    isViewOnly = false,
    baseBidDisable,
  } = props

  const [baseBid, setBaseBid] = React.useState<
    number | string | undefined | null
  >(baseBidPrice)

  const [hasFocus, setHasFocus] = React.useState(false)

  const tooltipTitle = () => {
    if (hasFocus && !Number(baseBid)) {
      return 'Base Bid Price is required to apply Bid Modifiers'
    }

    if (maxBid && baseBid && maxBid < Number(baseBid)) {
      return (
        <Alert
          sx={{ p: 0, alignItems: 'start', boxShadow: 0, border: 0 }}
          severity="error"
          variant="outlined"
        >
          Base Bid Price must be less than or equal to Max Bid Price
        </Alert>
      )
    }
  }

  const haveError = () => {
    return Boolean(maxBid && baseBid && maxBid < Number(baseBid))
  }

  const onChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const isValid =
      REGEX_POSITIVE_FLOAT.test(value) ||
      (Boolean(value.split('.').length - 1 < 2) &&
        value[value.length - 1] === '.')

    if (!isValid) {
      return
    }

    setBaseBid(value)
  }
  React.useEffect(() => {
    setBaseBid(baseBidPrice)
    if (baseBidInputRef.current) {
      // @ts-ignore
      baseBidInputRef.current.value = baseBidPrice
    }
  }, [baseBidPrice, baseBidInputRef])

  return (
    <>
      <Col
        xs={'auto'}
        sx={{ display: 'flex', alignItems: 'center' }}
        className={styles.infoItem}
      >
        <Typography variant="label">Max Bid Price:</Typography>
        <Typography variant="label" weight="demi" sx={{ ml: 4 }}>
          {maxBid ? formatCurrency(maxBid) : '-'}
        </Typography>
      </Col>
      <Col xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <Tooltip
            title="A base bidding amount up to which you allow us to bid."
            placement="top"
            arrow
            sx={{ mr: 4 }}
          >
            <InfoCircle fontSize={14} />
          </Tooltip>
          <Typography variant="label" gutterBottom={false}>
            Base Bid Price:
          </Typography>
        </Box>
        <Box>
          {isViewOnly ? (
            <Typography variant="label" weight="demi" sx={{ ml: 4 }}>
              {baseBidPrice ? formatCurrency(baseBidPrice) : '-'}
            </Typography>
          ) : (
            <Tooltip
              open={Boolean(tooltipTitle())}
              title={tooltipTitle()}
              placement="top"
              severity={haveError() ? 'error' : undefined}
              arrow
            >
              <TextField
                value={baseBid ? baseBid : undefined}
                classes={{ root: styles.baseBidInput }}
                type="text"
                size="small"
                onChange={onChangeWrapper}
                error={haveError()}
                placeholder="Enter"
                variant="outlined"
                sx={{ ml: 8 }}
                inputRef={baseBidInputRef}
                disabled={baseBidDisable}
                inputProps={{
                  onFocusCapture: () => {
                    setHasFocus(true)
                  },
                  onBlurCapture: () => {
                    setHasFocus(false)
                    if (haveError() || !Number(baseBid)) {
                      setBaseBid(baseBidPrice)
                      if (baseBidInputRef?.current?.value) {
                        // @ts-ignore
                        baseBidInputRef.current.value = baseBidPrice
                      }
                      return
                    }
                    if (Number(baseBid) && !haveError()) {
                      const value = currencyFormatter.format(Number(baseBid))
                      setBaseBid(value)
                      setModifiedModelledData?.(prev => ({
                        ...prev,
                        campaignBidDetails: {
                          ...prev.campaignBidDetails,
                          baseBid: Number(value),
                        },
                      }))
                      if (baseBidInputRef?.current?.value) {
                        baseBidInputRef.current.value = value
                      }
                    }
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <CurrencyDollar sx={{ textColor: 'neutral-400' }} />
                  ),
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Col>
    </>
  )
}
