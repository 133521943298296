import React from 'react'
import { Box } from '@applift/factor'
import { CreativePreview } from '@applift/platform-creatives'

import { RawDataCell } from '../RawDataCell'

export const CreativeDetailCell = (props: { data: any }) => {
  const { data } = props

  const [creativeIdToPreview, setCreativeIdToPreview] = React.useState(null)

  return data.id > 0 ? (
    <>
      <Box
        onClick={() => setCreativeIdToPreview(data.id)}
        style={{ cursor: 'pointer' }}
      >
        <RawDataCell
          value={data.id as string}
          textTypographyProps={{
            sx: {
              textColor: data.isExcluded ? 'danger-500' : 'success-500',
            },
          }}
        />
      </Box>
      {creativeIdToPreview && (
        <CreativePreview
          creativeId={creativeIdToPreview}
          open={typeof creativeIdToPreview === 'number'}
          onClose={() => setCreativeIdToPreview(null)}
          editable={false}
        />
      )}
    </>
  ) : (
    <>—</>
  )
}
