import * as React from 'react'
import { Row, Col, sx } from '@applift/factor'
import {
  SortingState,
  DataGrid,
  RowSelectionState,
  useGridApiRef,
  ColumnPinningState,
  VisibilityState,
  GridActionCellParams,
} from '@applift/datagrid'
import { NoData, SearchData, SelectObject } from '@applift/illustrations'
import { ClipboardRemove } from '@applift/icons'

import { NoResultsOverlay } from '../../../../components/GridOverLay'
import { CAMPAIGN_STATUSES } from '../../../../constants/campaigns'
import { CreativeList } from '../../../../models/Creative'

export interface CreativeGridProps {
  data: CreativeList[]
  columnDef: any[]
  totalRecords?: number
  rowIdKey: string
  sorting: SortingState
  pageSize: number
  search: string
  overlay?: 'error' | 'noRows' | 'noResult' | undefined
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>
  footerData: any
  loading: boolean
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  columnVisibility: VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  defaultDeselectedColumns: VisibilityState
  onAction: (params: GridActionCellParams<any, any>) => void
  loadingIncludeExclude: string[]
  campaignStatus: string
  currentApp: 'bidModel' | 'campaign'
  campaignId: number | null
}

export const CreativeGrid = (props: CreativeGridProps) => {
  const {
    data,
    columnDef,
    totalRecords,
    rowIdKey,
    pageSize,
    sorting,
    footerData,
    loading,
    rowSelection,
    overlay,
    setRowSelection,
    onSortingChange,
    columnVisibility,
    defaultDeselectedColumns,
    setColumnVisibility,
    onAction,
    loadingIncludeExclude,
    campaignStatus,
    currentApp,
    campaignId,
  } = props

  const [columnPinning] = React.useState<ColumnPinningState>({
    left: ['__check__', 'id'],
    right: ['Allowlist/Blocklist'],
  })

  const [columnOrder] = React.useState<string[]>([
    '__check__',
    'id',
    'creativeThumbnailSource',
    'name',
    'creativeStatus',
    'modelled',
    'impressions',
    'clicks',
    'winRate',
    'CTR',
    'spentDistribution',
    'VCR',
    'eCPM',
    'eCPC',
    'mediaSpent',
    'dataCost',
    'prebidCost',
    'spent',
    'conversionValue',
    'conversionCount',
    'returnOnAdSpend',
    'attributedClickThroughConversions',
    'attributedViewThroughConversions',
    'costPerAttributedConversions',
    'attributedConversionsRate',
    'startCount',
    'firstCount',
    'midCount',
    'thirdCount',
    'completeCount',
    'audioVideoViewed',
  ])

  const setRowSelectionWrapper = React.useCallback(
    (_value: any) => {
      let value = _value
      if (typeof _value === 'function') {
        value = _value()
      }
      setRowSelection({ ...value })
    },

    [setRowSelection]
  )
  const apiRef = useGridApiRef()
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    apiRef.current.subscribeEvent('viewportInnerSizeChange', params => {
      setHeight(params.height)
    })
  }, [apiRef])

  const isCampaignExpiredOrDeleted = [
    CAMPAIGN_STATUSES.EXPIRED,
    CAMPAIGN_STATUSES.DELETED,
  ].includes(campaignStatus)

  return (
    <>
      <Row sx={{ flexGrow: 1 }}>
        <Col xs={12}>
          <DataGrid
            apiRef={apiRef}
            footerPlacement={data?.length ? 'top' : undefined}
            footerData={footerData}
            data={data}
            columns={columnDef}
            getRowId={(row: any) => {
              if (row && typeof row[rowIdKey] === 'number') {
                return `${row[rowIdKey]}`
              }
              return row[rowIdKey]
            }}
            state={{
              sorting,
              rowSelection,
              columnOrder,
              columnPinning,
              columnVisibility: {
                ...defaultDeselectedColumns,
                ...columnVisibility,
              },
            }}
            pageSize={pageSize}
            hideHeader
            hideFooter
            rowHeight={80}
            checkboxSelection={!isCampaignExpiredOrDeleted}
            overscrollBehaviorX="contain"
            overlay={overlay}
            meta={{
              loadingIncludeExclude: loadingIncludeExclude,
              disableActions: isCampaignExpiredOrDeleted,
              currentApp,
              isModelledTab: false,
              campaignId,
            }}
            components={{
              NoResultsOverlay,
              NoRowsOverlay: NoResultsOverlay,
              ErrorOverlay: NoResultsOverlay,
            }}
            componentsProps={{
              noResultsOverlay: {
                text: 'No results found',
                subText: 'We can’t find any items matching your search.',
                illustration:
                  height <= 250 ? (
                    <ClipboardRemove
                      sx={{ textColor: 'neutral-400', mb: 8 }}
                      fontSize={36}
                    />
                  ) : (
                    <SearchData
                      sx={{
                        textColor: 'primary-500',
                        width: height <= 400 ? 50 : 100,
                        height: 'auto',
                        mb: height <= 400 ? 24 : 40,
                      }}
                    />
                  ),
              },
              // @ts-ignore
              noRowsOverlay: {
                // @ts-ignore
                text: 'No Creatives Available',
                illustration: (
                  <NoData
                    sx={{
                      textColor: 'primary-500',
                      width: height <= 400 ? 50 : 100,
                      height: 'auto',
                      mb: height <= 400 ? 24 : 40,
                    }}
                  />
                ),
              },
              // @ts-ignore
              errorOverlay: {
                // @ts-ignore
                text: 'Campaign is not selected.',
                subText: 'Select a campaign from the dropdown.',
                illustration: (
                  <SelectObject
                    sx={{
                      textColor: 'primary-500',
                      width: height <= 400 ? 50 : 100,
                      height: 'auto',
                      mb: height <= 400 ? 24 : 40,
                    }}
                  />
                ),
              },
            }}
            showColumnRightBorder
            showCellRightBorder
            disableRowSelectionOnClick
            rowCount={totalRecords}
            classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
            loading={loading}
            onAction={onAction}
            onRowSelectionChange={setRowSelectionWrapper}
            onSortingChange={onSortingChange}
            onColumnVisibilityChange={setColumnVisibility}
          />
        </Col>
      </Row>
    </>
  )
}
