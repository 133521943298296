import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@applift/factor'

interface ResetModellingDialogProps {
  onClose: () => void
  onReset: () => void
}

export const ResetModellingDialog = (props: ResetModellingDialogProps) => {
  const { onClose, onReset } = props

  return (
    <Dialog open onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        <Typography weight="demi"> Reset Modelling </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 12 }}>
          <Typography>
            All the modelled terms will have the following values:
          </Typography>
          <ul>
            <li>Priority as 1</li>
            <li>Bid Modifier to 1x</li>
            <li> Spend ratio method as FLEXIBLE & value as 100%</li>
          </ul>
        </Box>
        <Typography>
          Are you sure you want to reset the modelling terms to their default
          settings?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={onReset}>
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  )
}
