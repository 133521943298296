import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LoadingButton,
  Link,
  ButtonBase,
} from '@applift/factor'
import { AppInventory } from '@applift/icons'
import { getPathNumber } from 'iqm-framework'

interface GlobalBlockDialogProps {
  closeDialog: () => void
  onCompletion: () => void
  isProcessRunning: boolean
}

export const GlobalBlockDialog = (props: GlobalBlockDialogProps) => {
  const { closeDialog, onCompletion, isProcessRunning } = props

  const openInventoryApp = () => {
    window.open(encodeURI(`/inventory/u/${getPathNumber()}/#/`), '_blank')
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      PaperProps={{ sx: { overflowY: 'visible' } }}
    >
      <DialogTitle onClose={closeDialog}>Add to Global Blocklist</DialogTitle>
      <DialogContent
        dividers
        sx={{ flexDirection: 'column', display: 'flex', overflowY: 'visible' }}
      >
        <Typography component="p" variant="bodyMedium" sx={{ mb: 8 }}>
          Are you sure you want to add the selected inventories to the Global
          Blocklist?
        </Typography>

        <Typography component="p" sx={{ textColor: 'neutral-500' }}>
          This will immediately block the selected inventories and the platform
          will not serve ads to them while they are blocked. You can manage
          blocked inventories from the
          <AppInventory
            style={{ position: 'relative', top: '8px' }}
            fontSize={24}
          />
          <ButtonBase onClick={openInventoryApp}>
            <Link Component="a">Inventory App</Link>
          </ButtonBase>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={isProcessRunning}
          onClick={onCompletion}
        >
          Block Now
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
