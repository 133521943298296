import { Box, TypoTooltip, TypographyProps } from '@applift/factor'
import { toInitCap } from '../../../../utils'
import { IconNamesTypes, getIcon } from '../../../../registry'

interface CreativeNameCellType {
  creativeType: IconNamesTypes | string
  label: string
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CreativeNameCell = ({
  creativeType,
  label,
  textTypographyProps,
}: CreativeNameCellType) => {
  const creativeTypeIcon = creativeType
    ? getIcon(creativeType as any, {
        fontSize: 20,
      })
    : null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
      }}
    >
      {creativeTypeIcon ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: 4,
            gap: 4,
          }}
        >
          {creativeTypeIcon}
        </Box>
      ) : null}
      <TypoTooltip
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
        }}
      >
        {toInitCap(label)}
      </TypoTooltip>
    </Box>
  )
}
