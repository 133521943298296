import * as React from 'react'
import { enqueueSnackbar } from '@applift/factor'
import {
  IncludeExcludeErrorResponse,
  IncludeExcludeRequestData,
  IncludeExcludeSuccessResponse,
} from '../models/Creative'
import { OptimizationType } from '../services/RUMLogger/constants'
import { RUMLogger } from '../services/RUMLogger'

export interface IncludeExcludeQueue {
  enqueue: (id: string, data: any) => void
  loadingIncludeExclude: string[]
}

export function useIncludeExcludeQueue(
  includeExcludeFn: (
    requestData: IncludeExcludeRequestData
  ) => () => Promise<
    IncludeExcludeSuccessResponse | IncludeExcludeErrorResponse
  >,
  campaignId: number,
  dimensionId: number,
  options?: {
    onIncludeExcludeSuccess?: (res: any, payload: any) => void
    onIncludeExcludeError?: (res: any, payload: any) => void
    segment?: OptimizationType
  }
): IncludeExcludeQueue {
  const [includeExcludeQueue, setIncludeExcludeQue] = React.useState(new Map())
  const [loadingIncludeExclude, setLoadingIncludeExclude] = React.useState<
    string[]
  >([])
  const processQueue = React.useCallback(
    async (ids: string, isExcluded: 0 | 1) => {
      const includeExcludeFnWithMetadata = includeExcludeFn({
        ids,
        isExcluded,
        campaignId,
        dimensionId,
      })

      try {
        const response = await includeExcludeFnWithMetadata()

        if (options?.segment) {
          RUMLogger.Optimize.optimizeCampaign({
            success: true,
            campaignId,
            isExcluded,
            activeTable: options.segment,
            endpoint: (includeExcludeFnWithMetadata as any).apiUrl,
            response,
          })
        }

        options?.onIncludeExcludeSuccess?.(response, {
          ids,
          isExcluded,
          campaignId,
          dimensionId,
        })
      } catch (e: any) {
        enqueueSnackbar(
          e?.error && typeof e.error === 'string'
            ? e.error
            : 'Something went wrong. Please try after sometime.',
          {
            variant: 'error',
          }
        )

        if (options?.segment) {
          RUMLogger.Optimize.optimizeCampaign({
            success: false,
            campaignId,
            isExcluded,
            activeTable: options.segment,
            endpoint: (includeExcludeFnWithMetadata as any).apiUrl,
            response: e,
          })
        }

        options?.onIncludeExcludeError?.(e, {
          ids,
          isExcluded,
          campaignId,
        })
      } finally {
        // Clearing IncludeExclude queue
        const processedIds = ids.split(',')
        setLoadingIncludeExclude(prev => {
          const newQueue = prev.filter(id => !processedIds.includes(id))
          return newQueue
        })
      }
    },
    [campaignId, includeExcludeFn, options, dimensionId]
  )

  React.useEffect(() => {
    if (includeExcludeQueue.size && campaignId && dimensionId) {
      const idToProcess = Array.from(includeExcludeQueue.keys())
      setLoadingIncludeExclude(prev => [...prev, ...idToProcess])
      const ids = idToProcess.join(',')
      const isExcluded = includeExcludeQueue.values().next().value.isExcluded
      processQueue(ids, isExcluded)
      // clearing the queue
      setIncludeExcludeQue(prev => {
        const newMap = new Map(prev)
        newMap.clear()
        return newMap
      })
    }
  }, [includeExcludeQueue, processQueue, dimensionId, campaignId])

  const enqueue = (id: string, data: any) =>
    setIncludeExcludeQue(prevQue => {
      const newMap = new Map(prevQue)
      newMap.set(id, data)
      return newMap
    })

  return { enqueue, loadingIncludeExclude }
}
