import React from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Paper, Row, Typography, enqueueSnackbar } from '@applift/factor'
import {
  GridActionCellParams,
  RowSelectionState,
  SortingState,
  VisibilityState,
  constructListFromColumnDef,
} from '@applift/datagrid'

import {
  useCampaignDetails,
  useCityList,
  useDebounceValue,
  useDimensions,
} from '../../../../hooks'
import {
  DEFAULT_LIST_SORTING,
  DEFAULT_NO_OF_ENTRIES,
  DEFAULT_PAGE_SIZE,
} from '../../../../constants/dealId'
import { CityGrid } from './CityGrid'
import { colDef } from './colDef'
import { ActionPanel } from './ActionPanel'
import { useStore } from '../../../../store'
import { addVideoColDef } from '../../../CreativePage/component/CreativeGrid/colDef'
import {
  ACTION,
  ActionNameType,
} from '../../../CreativePage/component/CreativeGrid/action'
import { CityList } from '../../../../models/City'
import { queryClient } from '../../../../cache'
import { useIncludeExcludeQueue } from '../../../../hooks/useIncludeExcludeQueue'
import { getCurrentView } from '../../../../routes'
import { includeExcludeFunc } from '../../../../api/creative'
import { OptimizationType } from '../../../../services/RUMLogger/constants'

export const CityGridWrapper = () => {
  const [search, setSearch] = React.useState('')
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [sorting, setSorting] =
    React.useState<SortingState>(DEFAULT_LIST_SORTING)
  const [allowBlockFilterValue, setAllowBlockFilterValue] = React.useState<
    'all' | 'allowed' | 'blocked'
  >('all')
  const [campaignId, timezone, storeDateRange] = useStore(state => [
    state.filter.campaignId,
    state.filter.timezone,
    state.filter.dateRange,
  ])
  const { data: campaignDetails } = useCampaignDetails({
    id: campaignId,
  })
  const columnDefinition = React.useMemo(() => {
    return addVideoColDef(Number(campaignDetails?.creativeType), colDef)
  }, [campaignDetails])

  const defaultData = React.useMemo(
    () => constructListFromColumnDef(columnDefinition),
    [columnDefinition]
  )
  const [columnVisibility, setColumnVisibility] = React.useState(defaultData[1])
  const defaultDeselectedColumns = React.useState(() =>
    Object.keys(defaultData[1]).reduce((prev, one) => {
      // eslint-disable-next-line
      prev[one] = false
      return prev
    }, {} as VisibilityState)
  )[0]

  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    { data?: CityList; id: string; params: any }
  > | null>(null)

  const onIncludeExcludeSuccess = (res: any, payload: any) => {
    const succeedIds = payload.ids.split(',')
    queryClient.setQueriesData(
      {
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getCityList',
      },
      (pages: any) => {
        const numberOfIds = succeedIds.length
        const updatedPage = pages.pages.map((page: any) => {
          const newData = page.data?.map((one: any) => {
            if (succeedIds.includes(String(one.id))) {
              return {
                ...one,
                ...{
                  isExcluded: payload?.isExcluded,
                },
              }
            }
            return one
          })
          if (allowBlockFilterValue !== 'all') {
            return {
              ...page,
              data: newData.filter(
                (data: CityList) => data.isExcluded === isExcluded
              ),
              total: {
                ...page.total,
                totalCount: page.total.totalCount - numberOfIds,
              },
            }
          }
          return {
            ...page,
            data: newData,
          }
        })
        return {
          ...pages,
          pages: updatedPage,
        }
      }
    )
    enqueueSnackbar(
      res?.message && typeof res.message === 'string'
        ? res.message
        : `The City have been ${payload?.isExcluded ? 'blocked' : 'allowed'}.`,
      {
        variant: 'success',
      }
    )
    if (action?.actionName === ACTION.TOGGLE_INCLUDE_EXCLUDE_BULK) {
      // Clearing row selection
      setRowSelection({})
    }
  }

  const location = useLocation()

  const { data: dimensionData } = useDimensions()

  const path = getCurrentView(location.pathname)
  const pathElements = path.split('/')
  const currentDimension = pathElements[pathElements.length - 1]?.replace(
    /-/g,
    '_'
  )

  const dimensionId = currentDimension
    ? dimensionData?.keyIdPair[currentDimension]
    : null

  const includeExcludeQueue = useIncludeExcludeQueue(
    includeExcludeFunc,
    campaignId!,
    dimensionId!,
    {
      onIncludeExcludeSuccess: onIncludeExcludeSuccess,
      segment: OptimizationType.CITY,
    }
  )

  React.useEffect(() => {
    switch (action?.actionName) {
      case ACTION.TOGGLE_INCLUDE_EXCLUDE_INLINE:
      case ACTION.TOGGLE_INCLUDE_EXCLUDE_BULK: {
        const actionRowIds = action?.metaData?.id?.split(',')
        if (actionRowIds.length) {
          actionRowIds.forEach(id => {
            includeExcludeQueue.enqueue(id, action.metaData.params)
          })
        }
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  React.useEffect(() => {
    setColumnVisibility(defaultData[1])
  }, [defaultData])

  // Resetting the table state on change of the campaign
  React.useEffect(() => {
    setRowSelection({})
    setSorting(DEFAULT_LIST_SORTING)
    setSearch('')
    setAllowBlockFilterValue('all')
  }, [campaignId])

  const debouncedSearch = useDebounceValue(search, 500)

  const isExcluded = React.useMemo(() => {
    if (allowBlockFilterValue === 'allowed') {
      return 0
    }
    if (allowBlockFilterValue === 'blocked') {
      return 1
    }
    return undefined
  }, [allowBlockFilterValue])

  const dateRange = React.useMemo(
    () => ({
      startDate: storeDateRange?.start,
      endDate: storeDateRange?.end,
    }),
    [storeDateRange]
  )
  const callCityList = React.useMemo(() => {
    return Boolean(
      dateRange.endDate && dateRange.startDate && campaignId && dimensionId
    )
  }, [dateRange, campaignId, dimensionId])

  const { data, fetchNextPage, isFetching } = useCityList(
    debouncedSearch,
    dimensionId as number,
    String(campaignId),
    DEFAULT_PAGE_SIZE,
    // using TS ignore cause date Ragne value will be there,
    // made sure using enabled (callCityList) key
    // @ts-ignore
    dateRange,
    timezone?.id || 11, // 11 for the UTC
    sorting,
    isExcluded,
    DEFAULT_NO_OF_ENTRIES,
    { enabled: callCityList }
  )

  // @ts-ignore
  const totalObject = data?.pages[0]?.total

  const flatData = React.useMemo(() => {
    return (
      data?.pages
        ?.map(page => {
          return page.data ?? []
        })
        .flat(1) || []
    )
  }, [data])

  const filteredRecords = React.useMemo(
    // @ts-ignore
    () => data?.pages[0]?.total.totalCount,
    [data]
  )

  const onSortingChangeWrapper = (
    value: React.SetStateAction<SortingState>
  ) => {
    setRowSelection({})
    setSorting(value)
  }

  const setSearchWrapper = (value: React.SetStateAction<string>) => {
    setRowSelection({})
    setSearch(value)
  }
  const selectedIds = Object.keys(rowSelection).map(id => String(id))

  const selectedItems = React.useMemo(
    () =>
      flatData.length
        ? flatData.filter(data => selectedIds.includes(String(data?.id)))
        : [],
    [selectedIds, flatData]
  )

  const overlay = React.useMemo(() => {
    if (!campaignId) {
      return 'error'
    }
    if (!flatData?.length && !isFetching && search) {
      return 'noResult'
    }
    if (!flatData?.length && !isFetching) {
      return 'noRows'
    }
    return undefined
  }, [flatData?.length, isFetching, search, campaignId])

  return (
    <>
      <Row sx={{ my: 12 }}>
        <Col xs={12}>
          <Typography component="h2" variant="bodyLarge" weight="demi">
            {`Cites ${
              !isFetching &&
              typeof filteredRecords === 'number' &&
              !isNaN(filteredRecords)
                ? `(${filteredRecords})`
                : ''
            }`}
          </Typography>
        </Col>
      </Row>
      <Row sx={{ flexGrow: 1 }}>
        <Col xs={12}>
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              height: 100,
            }}
          >
            <ActionPanel
              colDef={columnDefinition}
              search={search}
              setSearch={setSearchWrapper}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              selectedItems={selectedItems as any[]}
              isTableLoading={isFetching}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              defaultData={defaultData}
              sorting={sorting}
              defaultDeselectedColumns={defaultDeselectedColumns}
              allowBlockFilterValue={allowBlockFilterValue}
              setAllowBlockFilterValue={setAllowBlockFilterValue}
              totalRecords={filteredRecords}
              onAction={setAction}
              loadingIncludeExclude={includeExcludeQueue.loadingIncludeExclude}
              dimensionId={dimensionId as number}
            />
            <CityGrid
              footerData={{
                ...totalObject,
              }}
              data={flatData}
              columnDef={columnDefinition}
              totalRecords={filteredRecords}
              sorting={sorting}
              onSortingChange={onSortingChangeWrapper}
              rowIdKey="id"
              pageSize={DEFAULT_PAGE_SIZE}
              search={search}
              overlay={overlay}
              loading={isFetching}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              defaultDeselectedColumns={defaultDeselectedColumns}
              onAction={setAction}
              loadingIncludeExclude={includeExcludeQueue.loadingIncludeExclude}
              onFetchRows={() => {
                // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                setTimeout(fetchNextPage, 100)
              }}
            />
          </Paper>
        </Col>
      </Row>
    </>
  )
}
