import { datadogRum } from 'iqm-framework'
import { AxiosError } from 'axios'
import { getApiErrorContext } from './utils'
import * as Optimize from './actions/Optimize'
import * as CampaignDetails from './actions/CampaignDetails'
import * as AdvancedModelling from './actions/AdvancedModelling'

export const RUMLogger = {
  Optimize,
  CampaignDetails,
  AdvancedModelling,
  global: {
    addAction: (name: string, context: { [key: string]: any }) => {
      datadogRum.addAction(name, context)
    },
    logError: (error: AxiosError, context?: { [key: string]: any }) => {
      datadogRum.addError(error, {
        ...getApiErrorContext(error),
        ...(context || {}),
      })
    },
  },
}
