import { QueryFunctionContext } from '@tanstack/react-query'

import { getInstance } from './instance'
import { getModelledDimensionsCountKey, getNavbarCountKey } from './QueryKeys'
import { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '../models/Response'
import { CampaignStatus } from '../models/Campaign'
import { ModelledDimensionsCount } from '../models/Common'
import { getCampaignCountByStatusKey } from '../hooks'

export const dimensionCountKey = [
  'ageGroup',
  'city',
  'creative',
  'dealId',
  'deviceType',
  'exchange',
  'gender',
  'income',
  'openExchange',
  'publisherCategory',
  'state',
  'trafficType',
  'zip',
] as const

export type DimensionCountKey = (typeof dimensionCountKey)[number]

export interface getCountResponse {
  ageGroup: number
  city: number
  creative: number
  dealId: number
  deviceType: number
  exchange: number
  gender: number
  income: number
  openExchange: number
  publisherCategory: number
  state: number
  trafficType: number
  zip: number
}

export const getNavbarCount = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getNavbarCountKey)['keys']>
>): Promise<getCountResponse | {}> => {
  const payload = queryKey[0]?.countParam
  const { startDate, endDate, id } = payload!
  try {
    const result = await getInstance().get(
      `/v3/bm/campaigns/${id}/dimensions/count`,
      { params: { startDate, endDate } }
    )
    return result.data.data
  } catch (e) {
    return Promise.reject(e as AxiosError)
  }
}

export const getCampaignCountByStatus = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getCampaignCountByStatusKey)['keys']>
>) => {
  try {
    const response: AxiosResponse<WithResponse<CampaignStatus[]>> =
      await getInstance().post('/v3/cmp/campaigns/count', {
        campaignTypeIds: queryKey[0]?.campaignTypeIds,
      })

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response || e)
  }
}

export const getModelledDimensionsCount = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getModelledDimensionsCountKey)['keys']>
>) => {
  try {
    const response: AxiosResponse<WithResponse<ModelledDimensionsCount>> =
      await getInstance().get(
        `/v3/bm/campaigns/${queryKey[0]?.campaignId}/bid-models/count`,
        {
          params: {
            dimensionId: queryKey[0]?.dimensionId,
          },
        }
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response || e)
  }
}
