import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'
import {
  getModelledDimensionsCountKey,
  getNavbarCountKey,
} from '../api/QueryKeys'
import { WithResponse } from '../models/Response'
import {
  getNavbarCount,
  getCampaignCountByStatus,
  getModelledDimensionsCount,
} from '../api/count'

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    }
  )

export const useNavbarCount = (
  countParam: {
    id: number
    startDate: number
    endDate: number
  },
  options?: {
    enabled?: boolean
    onError?: (err: WithResponse<void>) => void
  }
) => {
  const data = useQuery(
    getNavbarCountKey.keys('getNavbarCount', countParam),
    getNavbarCount,
    {
      onError: (err: WithResponse<void>) => {
        if (options?.onError) {
          options.onError(err)
        }
      },
      enabled: options?.enabled,
    }
  )
  return data
}

export const getCampaignCountByStatusKey = {
  keys: (args: { scope: string; campaignTypeIds: number[] }) => [args],
}

export const useCampaignCountByStatus = ({
  campaignTypeIds,
}: {
  campaignTypeIds: number[]
}) => {
  const data = useQuery(
    getCampaignCountByStatusKey.keys({
      scope: 'getCampaignCountByStatus',
      campaignTypeIds,
    }),
    getCampaignCountByStatus,
    {
      onError,
    }
  )
  return data
}

export const useModelledDimensionsCount = (
  countParam: {
    campaignId: number
    dimensionId?: number
  },
  options?: {
    enabled?: boolean
  }
) => {
  const data = useQuery(
    getModelledDimensionsCountKey.keys(
      'getModelledDimensionsCount',
      countParam.campaignId,
      countParam.dimensionId
    ),
    getModelledDimensionsCount,
    {
      onError,
      enabled: options?.enabled,
    }
  )
  return data
}
