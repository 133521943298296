import { Box, Typography } from '@applift/factor'
import styles from './styles.module.scss'

export const AllowBlockTooltip = (props: { listType: string }) => {
  const { listType } = props
  return (
    <Box>
      <Box sx={{ mb: 12 }}>
        <Typography
          component="p"
          weight="demi"
          className={styles.allow}
          sx={{ position: 'relative', ml: 12 }}
        >
          Allowlist:
        </Typography>
        <Typography>{`List of ${listType} targeted for this campaign`}</Typography>
      </Box>
      <Box>
        <Typography
          component="p"
          weight="demi"
          className={styles.block}
          sx={{ position: 'relative', ml: 12 }}
        >
          Blocklist:
        </Typography>
        <Typography>{`List of ${listType} blocked for this campaign`}</Typography>
      </Box>
    </Box>
  )
}
