import * as React from 'react'
import { RowProps, Row, Col } from '@applift/factor'

interface TabPanelProps {
  children: React.ReactNode
  value: number
  index: number
}

export const TabPanel = React.forwardRef<
  HTMLDivElement,
  TabPanelProps & RowProps
>((props, ref) => {
  const { children, index, value, ...other } = props
  return value === index ? (
    <Row ref={ref} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Col
        sx={{
          height: 100,
          overflow: 'auto',
        }}
      >
        {children}
      </Col>
    </Row>
  ) : null
})

TabPanel.displayName = 'TabPanel'
