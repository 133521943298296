import {
  Badge,
  bindMenu,
  bindTrigger,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  SvgIcon,
  sx,
  Typography,
  usePopupState,
} from '@applift/factor'
import { Filter, Redo } from '@applift/icons'
import clsx from 'clsx'

import styles from './index.module.scss'

const FILTER_ITEMS: {
  status_key: 'allowed' | 'blocked'
  status_label: string
  value: string
}[] = [
  {
    status_key: 'allowed',
    status_label: 'Allowed',
    value: 'allowed',
  },
  {
    status_key: 'blocked',
    status_label: 'Blocked',
    value: 'blocked',
  },
]

interface Props {
  selectedValue: 'all' | 'allowed' | 'blocked'
  setSelectedValue: (value: 'all' | 'allowed' | 'blocked') => void
}

export const AllowBlockFilter = (props: Props) => {
  const { selectedValue, setSelectedValue } = props

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'filterPopper',
  })

  return (
    <>
      <Badge
        overlap="circular"
        badgeContent={selectedValue !== 'all' ? 1 : 0}
        color="warning"
      >
        {/*@ts-ignore */}
        <IconButton
          {...bindTrigger(popupState)}
          id="mainlist-filter-button"
          selected={popupState.isOpen}
          color="secondary"
        >
          <Filter />
        </IconButton>
      </Badge>
      {/*@ts-ignore */}
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ border: 1 }}
        classes={{
          paper: clsx(
            sx({ border: 1, borderColor: 'primary-500' }),
            styles.filterDropdown
          ),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'between',
            px: 12,
            py: 8,
          }}
        >
          <Typography variant="bodySmall" weight="demi" sx={{ mb: 0 }}>
            Filter
          </Typography>
          <Link
            Component="button"
            color="primary"
            underline="none"
            onClick={() => setSelectedValue('all')}
            disabled={selectedValue === 'all'}
          >
            <SvgIcon sx={{ mr: 2 }} fontSize={24}>
              <Redo />
            </SvgIcon>
            Clear Filters
          </Link>
        </Box>
        {FILTER_ITEMS?.map(val => (
          <MenuItem
            value={val.status_key}
            key={val.status_key}
            selected={selectedValue === val.status_key}
            onClick={() => setSelectedValue(val.status_key)}
          >
            {val.status_label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
