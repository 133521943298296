import {
  LayoutCard,
  GlobeAlt,
  ClipboardList,
  Devices,
  Exchange,
} from '@applift/icons'

import { PATH } from '../../routes'
import { getCountResponse } from '../../api/count'
import { Dimensions } from '../../api/dimensions'
import { DimensionCountKey } from '../../api/count'

export interface NavbarItem {
  key: string
  to?: string
  title: string
  startIcon: JSX.Element
  count?: number
  subItems?: NavbarItem[]
}

const DimensionPath = [
  'creative',
  'deal_id',
  'open_exchange',
  'publisher_category',
  'traffic_type',
  'device_type',
  'state',
  'city',
  'zip',
  'exchange',
] as const

type DimensionsPath = (typeof DimensionPath)[number]

export const dimensionPathMapper = (campaignId: number | null) => ({
  creative: campaignId ? `${PATH.CREATIVE}/${campaignId}` : PATH.CREATIVE,
  deal_id: campaignId ? `${PATH.DEAL_ID}/${campaignId}` : PATH.DEAL_ID,
  open_exchange: campaignId
    ? `${PATH.OPEN_EXCHANGE}/${campaignId}`
    : PATH.OPEN_EXCHANGE,
  publisher_category: campaignId
    ? `${PATH.PUBLISHER_CATEGORY}/${campaignId}`
    : PATH.PUBLISHER_CATEGORY,
  traffic_type: campaignId
    ? `${PATH.TRAFFIC_TYPE}/${campaignId}`
    : PATH.TRAFFIC_TYPE,
  device_type: campaignId
    ? `${PATH.DEVICE_TYPE}/${campaignId}`
    : PATH.DEVICE_TYPE,
  state: campaignId ? `${PATH.STATE}/${campaignId}` : PATH.STATE,
  city: campaignId ? `${PATH.CITY}/${campaignId}` : PATH.CITY,
  zip: campaignId ? `${PATH.ZIP}/${campaignId}` : PATH.ZIP,
  exchange: campaignId ? `${PATH.EXCHANGE}/${campaignId}` : PATH.EXCHANGE,
})

const DimensionsName = [
  'creative',
  'inventory',
  'device',
  'location',
  'exchange',
] as const

type DimensionsName = (typeof DimensionsName)[number]

export const dimensionIconMapper = {
  creative: <LayoutCard fontSize={24} color="primary" />,
  inventory: <ClipboardList fontSize={24} color="primary" />,
  device: <Devices fontSize={24} color="primary" />,
  location: <GlobeAlt fontSize={24} color="primary" />,
  exchange: <Exchange fontSize={24} color="primary" />,
}

export const dimensionCountKey = {
  creative: 'creative',
  deal_id: 'dealId',
  open_exchange: 'openExchange',
  publisher_category: 'publisherCategory',
  traffic_type: 'trafficType',
  device_type: 'deviceType',
  state: 'state',
  city: 'city',
  zip: 'zip',
  exchange: 'exchange',
}
export const getNavItems = ({
  campaignId,
  countData,
}: {
  campaignId?: number
  countData?: getCountResponse
}) =>
  [
    {
      key: 'creative',
      to: campaignId ? `${PATH.CREATIVE}/${campaignId}` : PATH.CREATIVE,
      title: 'Creative',
      startIcon: <LayoutCard fontSize={24} color="primary" />,
      count: countData?.creative,
    },
    {
      key: 'inventory',
      title: 'Inventory',
      startIcon: <ClipboardList fontSize={24} color="primary" />,
      subItems: [
        {
          key: 'dealId',
          to: campaignId ? `${PATH.DEAL_ID}/${campaignId}` : PATH.DEAL_ID,
          title: 'Deal ID',
          count: countData?.dealId,
        },
        {
          key: 'openExchange',
          to: campaignId
            ? `${PATH.OPEN_EXCHANGE}/${campaignId}`
            : PATH.OPEN_EXCHANGE,
          title: 'Open Exchange',
          count: countData?.openExchange,
        },
        {
          key: 'publisherCategory',
          to: campaignId
            ? `${PATH.PUBLISHER_CATEGORY}/${campaignId}`
            : PATH.PUBLISHER_CATEGORY,
          title: 'Publisher Category',
          count: countData?.publisherCategory,
        },
      ],
    },
    {
      key: 'technology',
      title: 'Device',
      startIcon: <Devices fontSize={24} color="primary" />,
      subItems: [
        {
          key: 'trafficType',
          to: campaignId
            ? `${PATH.TRAFFIC_TYPE}/${campaignId}`
            : PATH.TRAFFIC_TYPE,
          title: 'Traffic Type',
          count: countData?.trafficType,
        },
        {
          key: 'deviceType',
          to: campaignId
            ? `${PATH.DEVICE_TYPE}/${campaignId}`
            : PATH.DEVICE_TYPE,
          title: 'Device Type',
          count: countData?.deviceType,
        },
      ],
    },
    {
      key: 'location',
      title: 'Location',
      startIcon: <GlobeAlt fontSize={24} color="primary" />,
      subItems: [
        {
          key: 'state',
          to: campaignId ? `${PATH.STATE}/${campaignId}` : PATH.STATE,
          title: 'State',
          count: countData?.state,
        },
        {
          key: 'city',
          to: campaignId ? `${PATH.CITY}/${campaignId}` : PATH.CITY,
          title: 'City',
          count: countData?.city,
        },
        {
          key: 'zip',
          to: campaignId ? `${PATH.ZIP}/${campaignId}` : PATH.ZIP,
          title: 'Zip',
          count: countData?.zip,
        },
      ],
    },
    {
      key: 'exchange',
      to: campaignId ? `${PATH.EXCHANGE}/${campaignId}` : PATH.EXCHANGE,
      title: 'Exchange',
      startIcon: <Exchange fontSize={24} color="primary" />,
      count: countData?.exchange,
    },
  ] as NavbarItem[]

export const transformNavData = (
  initNavData: Dimensions[],
  campaignId: number | null,
  dimensionsCountData?: getCountResponse
) => {
  return initNavData?.map(dimension => {
    if (Array.isArray(dimension.subdimensions)) {
      return {
        ...dimension,
        startIcon:
          dimensionIconMapper[`${dimension?.name}` as DimensionsName] ?? null,
        subdimensions: dimension.subdimensions.map(subdimensions => {
          return {
            ...subdimensions,
            to: dimensionPathMapper(campaignId)[
              `${subdimensions.name}` as DimensionsPath
            ],
            startIcon:
              dimensionIconMapper[`${subdimensions.name}` as DimensionsName] ??
              null,
            count:
              dimensionsCountData?.[
                `${
                  dimensionCountKey[`${subdimensions.name}` as DimensionsPath]
                }` as DimensionCountKey
              ] ?? null,
          }
        }),
      }
    }
    return {
      ...dimension,
      to: dimensionPathMapper(campaignId)[
        `${dimension?.name}` as DimensionsPath
      ],
      startIcon:
        dimensionIconMapper[`${dimension?.name}` as DimensionsName] ?? null,
      count:
        dimensionsCountData?.[
          `${
            dimensionCountKey[`${dimension?.name}` as DimensionsPath]
          }` as DimensionCountKey
        ] ?? null,
    }
  })
}
