import * as React from 'react'
import { Box, MenuItem, TextField, Typography } from '@applift/factor'
import { SelectIOCampaignApiRefType } from '@applift/platform'
import { useStore } from '../../store'
import { useCampaignCountByStatus } from '../../hooks/useCount'
import { StatusIcon } from '../../components/StatusIcon'
import { CAMPAIGN_TYPE } from '../../constants/campaigns'

const ALLOWED_STATUS_OPTIONS = [
  'running',
  'paused',
  'expired',
  'deleted',
  'draft',
  'pending',
  'rejected',
]

interface CampaignStatusDropDownProps {
  apiRef: React.MutableRefObject<SelectIOCampaignApiRefType | undefined>
}

export const CampaignStatusDropDown = (props: CampaignStatusDropDownProps) => {
  const { apiRef } = props
  const [status, setStatus, isDirty, toggleDiscardDialog] = useStore(state => [
    state.filter.status,
    state.filter.setStatus,
    state.modelling.isDirty,
    state.modelling.toggleDiscardDialog,
  ])

  const {
    data: campaignStatusCountData,
    isLoading: campaignStatusCountLoading,
  } = useCampaignCountByStatus({ campaignTypeIds: [CAMPAIGN_TYPE.ADVANCED] })

  const campaignStatusCount = React.useMemo(() => {
    return campaignStatusCountData?.filter(cmpData =>
      ALLOWED_STATUS_OPTIONS.includes(cmpData.status_key)
    )
  }, [campaignStatusCountData])
  return (
    <TextField
      variant="outlined"
      select
      SelectProps={{
        placeholder: 'Status',
        MenuProps: {
          PaperProps: {
            sx: { height: 'auto' },
          },
        },
        renderValue: value => {
          if (campaignStatusCountLoading) {
            return ''
          }
          const currectSelection = campaignStatusCount?.find(
            val => val.status_key === value
          )
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {currectSelection?.status_key ? (
                <StatusIcon
                  iconProps={{ fontSize: 20, sx: { mr: 4 } }}
                  status={currectSelection?.status_key}
                />
              ) : (
                ''
              )}
              <Typography>{`${currectSelection?.status_label} (${currectSelection?.status_count})`}</Typography>
            </Box>
          )
        },
      }}
      value={status}
      sx={{ width: 100 }}
      onChange={e => {
        if (isDirty) {
          toggleDiscardDialog(true)
          return
        }
        apiRef.current?.reset()
        setStatus(e.target.value)
      }}
    >
      {campaignStatusCount
        ?.sort((a, b) => a.order - b.order)
        ?.map(val => (
          <MenuItem
            startIcon={<StatusIcon status={val.status_key} />}
            value={val.status_key}
            key={val.status_key}
          >
            {val.status_label} ({val.status_count})
          </MenuItem>
        ))}
    </TextField>
  )
}
