import { AxiosError } from 'axios'
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { WithResponse } from '../models/Response'
import {
  getAllDimensionListKey,
  getCreativeInfoKey,
  getModelledDimensionListKey,
} from '../api/QueryKeys'
import {
  downloadListFunc,
  getAllCreativeDimensionList,
  getCreativeInfo,
  getModelledCreativesList,
} from '../api/creative'
import { CreativeList, CreativeModelledListResponse } from '../models/Creative'
import { AllDimensionListPayload, ModelledListPayload } from '../api/dimensions'

export type List = WithResponse<any>

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    }
  )

export type CreativeListDataType = {
  data: CreativeList[]
  total: CreativeList
}

export const useDownloadCreativeList = (options?: {
  onSuccess?: (res: any, payload: any) => void
  onError?: (props: any) => void
  onSettled?: (props: any) => void
}) => {
  const mutationResult = useMutation(downloadListFunc, {
    mutationKey: ['downloadCreativeList'],
    onSuccess: options?.onSuccess,
    onError: e => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      })
      if (options?.onError) {
        options.onError(e)
      }
    },
    onSettled: options?.onSettled,
  })

  return mutationResult
}

export const useCreativeInfo = (
  creativeId: number,
  options?: {
    enabled?: boolean
  }
) => {
  const data = useQuery(
    getCreativeInfoKey.keys('getCreativeInfo', creativeId),
    getCreativeInfo,
    {
      enabled: options?.enabled,
      cacheTime: 0,
      onError,
    }
  )
  return data
}

export const useCreativeDimensionList = (
  payload: AllDimensionListPayload,
  options?: {
    onSuccess?: UseQueryOptions<CreativeListDataType>['onSuccess']
    onError?: UseQueryOptions<CreativeListDataType>['onError']
    enabled?: UseQueryOptions<CreativeListDataType>['enabled']
    meta?: UseQueryOptions<CreativeListDataType>['meta']
  }
) => {
  const {
    searchField,
    sorting,
    dimensionId,
    dateRange,
    campaignId,
    timeZoneId,
    isExcluded,
    skipReporting,
    pageSize,
  } = payload

  const queryData = useQuery(
    getAllDimensionListKey.keys({
      scope: 'getAllCreativeDimensionList',
      pageNo: -1,
      searchField,
      campaignId,
      dateRange,
      sorting,
      timeZoneId,
      isExcluded,
      dimensionId,
      skipReporting,
      pageSize,
    }),
    getAllCreativeDimensionList,
    {
      onSuccess: options?.onSuccess,
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      enabled: options?.enabled ?? true,
      meta: options?.meta,
      cacheTime: 0,
    }
  )
  return queryData
}

export const useModelledCreativesList = (
  payload: ModelledListPayload,
  options?: {
    onSuccess?: UseQueryOptions<CreativeModelledListResponse>['onSuccess']
    onError?: UseQueryOptions<CreativeModelledListResponse>['onError']
    enabled?: UseQueryOptions<CreativeModelledListResponse>['enabled']
    meta?: UseQueryOptions<CreativeModelledListResponse>['meta']
  }
) => {
  const {
    searchField,
    sorting,
    dimensionId,
    campaignId,
    pageSize,
    dateRange,
    isExcluded,
    timeZoneId,
    pageNo,
    skipReporting,
  } = payload

  const queryData = useQuery<
    CreativeModelledListResponse,
    Error,
    CreativeModelledListResponse
  >(
    getModelledDimensionListKey.keys({
      scope: 'getModelledCreativesList',
      pageSize,
      searchField,
      campaignId,
      dateRange,
      sorting,
      timeZoneId,
      isExcluded,
      dimensionId,
      pageNo,
      skipReporting,
    }),
    getModelledCreativesList,
    {
      onSuccess: options?.onSuccess,
      onError: () => {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
        })
      },
      enabled: options?.enabled,
      cacheTime: 0,
      meta: options?.meta,
    }
  )
  return queryData
}
