import { Switch, SwitchProps } from '@applift/factor'
import styles from './styles.module.scss'

export const IncludeExcludeSwitch = (props: SwitchProps) => {
  return (
    <Switch
      className={styles.toggleSwitch}
      classes={{
        thumb: styles.thumb,
        track: styles.track,
        checked: styles.checked,
      }}
      color="success"
      {...props}
    />
  )
}
