export const DEFAULT_CREATIVE_LIST_SORTING = [{ id: 'impressions', desc: true }]
export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_NO_OF_ENTRIES = 50

export const creativeIDMap: Record<string, number> = {
  image: 11,
  html: 13,
  video: 14,
  audio: 17,
  native: 15,
}

export const IMAGE_CREATIVE_ID = 11
export const HTML_CREATIVE_ID = 13
export const VIDEO_CREATIVE_ID = 14
export const AUDIO_CREATIVE_ID = 17
export const NATIVE_CREATIVE_ID = 15
