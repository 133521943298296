import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { PATH } from '../../routes'

export const HomePage = () => {
  const history = useHistory()

  React.useEffect(() => {
    history.push(PATH.CREATIVE)
  }, [history])

  return <div></div>
}
