import React from 'react'
import { Box, CircularProgress } from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'

import { useStore } from '../../../../store'
import { IncludeExcludeSwitch } from '../../../../components/IncludeExcludeSwitch'
import { StateList } from '../../../../models/State'
import { BidModelTableMeta } from '../../../../models/Common'
import { ACTION } from '../../../CreativePage/component/CreativeGrid/action'

interface ListActionCellType {
  action?: (args: GridActionCellParams) => any
  rowData: StateList
  meta: BidModelTableMeta
}

export const ListActionCell = ({
  rowData,
  action,
  meta,
}: ListActionCellType) => {
  const [campaignId] = useStore(state => [state.filter.campaignId])

  const includeExclude = (isExcluded: 0 | 1) => {
    const ids = String(rowData.id)
    action?.({
      actionName: ACTION.TOGGLE_INCLUDE_EXCLUDE_INLINE,
      metaData: {
        data: rowData,
        id: ids,
        params: {
          campaignId: campaignId,
          ids: ids,
          isExcluded: isExcluded,
        },
      },
    })
  }

  const includeExcludeLoading = React.useMemo(() => {
    const id = rowData?.id
    return meta.loadingIncludeExclude.includes(String(id))
  }, [meta, rowData])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
      }}
    >
      {includeExcludeLoading ? (
        <CircularProgress size={16} thickness={5} />
      ) : (
        <IncludeExcludeSwitch
          checked={!rowData.isExcluded}
          onChange={value => {
            includeExclude(value.target.checked ? 0 : 1)
          }}
          disabled={rowData.name === 'Unknown'}
        />
      )}
    </Box>
  )
}
