import * as React from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { Box, ClickAwayListener } from '@applift/factor'
import { SortingState } from '@applift/datagrid'

import {
  CREATIVE_TAB,
  CreativePage,
  CreativePageApiRefType,
} from './CreativePage'
import { DEFAULT_CREATIVE_LIST_SORTING } from '../../constants/creative'
import { useStore } from '../../store'
import {
  useCampaignDetails,
  useCreativeDimensionList,
  useDebounceValue,
  useDimensions,
  useModelledCreativesList,
  useModelledDimensionsCount,
  useNavbarCount,
} from '../../hooks'
import { getCurrentView } from '../../routes'
import { getCountResponse } from '../../api/count'
import { WithResponse } from '../../models/Response'
import {
  CAMPAIGN_BUDGET_TYPE,
  CAMPAIGN_STATUSES,
} from '../../constants/campaigns'
import { findParentWithId } from '../../utils'

export const CreativePageWrapper = () => {
  const creativeRef = React.useRef<CreativePageApiRefType>(null)
  const [visibleSection, setVisibleSection] = React.useState<
    'All' | 'Modelled'
  >(CREATIVE_TAB.ALL)

  // *Common Data
  const [
    campaignId,
    timezone,
    dateRangeFromStore,
    campaignStatus,
    setIsDirty,
    showDiscardDialog,
    toggleDiscardDialog,
  ] = useStore(state => [
    state.filter.campaignId,
    state.filter.timezone,
    state.filter.dateRange,
    state.filter.status,
    state.modelling.setIsDirty,
    state.modelling.showDiscardDialog,
    state.modelling.toggleDiscardDialog,
  ])

  const { data: dimensionData } = useDimensions()
  const { data: campaignDetails } = useCampaignDetails({
    id: campaignId,
  })
  const path = getCurrentView(location.pathname)
  const pathElements = path.split('/')
  const currentDimension = pathElements[pathElements.length - 1]?.replace(
    /-/g,
    '_'
  )

  const dimensionId = React.useMemo(() => {
    if (dimensionData && currentDimension) {
      return dimensionData.keyIdPair[currentDimension]
    }
  }, [dimensionData, currentDimension])

  const { data: modelledCount } = useModelledDimensionsCount(
    {
      campaignId: campaignId as number,
      dimensionId: dimensionId as number,
    },
    {
      enabled: Boolean(campaignId && dimensionId),
    }
  )

  const modelledCreativesCount = modelledCount?.dimensionWiseCount.find(
    dimension => dimension.dimensionId === dimensionId
  )?.count

  const dateRange = React.useMemo(
    () => ({
      startDate: dateRangeFromStore?.start,
      endDate: dateRangeFromStore?.end,
    }),
    [dateRangeFromStore]
  )

  const { data: allDimensionsCountData } = useNavbarCount(
    {
      id: campaignId as number,
      startDate: dateRange?.startDate ?? 0,
      endDate: dateRange?.endDate ?? 0,
    },
    { enabled: Boolean(campaignId) }
  ) as UseQueryResult<getCountResponse, WithResponse<void>>

  const campaignBudget = React.useMemo(
    () =>
      campaignDetails?.budgetTypeId === CAMPAIGN_BUDGET_TYPE.DOLLAR
        ? campaignDetails.budgetTotal
        : campaignDetails?.totalImpressions,
    [campaignDetails]
  )

  const callModelCreativeList = React.useMemo(() => {
    return Boolean(
      dateRange.endDate &&
        dateRange.startDate &&
        campaignId &&
        dimensionId &&
        typeof campaignBudget === 'number'
    )
  }, [dateRange, campaignId, dimensionId, campaignBudget])

  const { data: modelTableData, isFetching: isModelTableLoading } =
    useModelledCreativesList(
      {
        skipReporting: [
          CAMPAIGN_STATUSES.DRAFT,
          CAMPAIGN_STATUSES.PENDING,
        ].includes(campaignStatus),
        dimensionId: dimensionId as number,
        campaignId: campaignId as number,
        dateRange,
        timeZoneId: timezone?.id || 11, // 11 for the UTC
        sorting: DEFAULT_CREATIVE_LIST_SORTING,
        pageNo: -1,
      },
      {
        enabled: callModelCreativeList,
        meta: { campaignBudget: campaignBudget },
      }
    )

  // * Data for the All CREATIVE list
  const [search, setSearch] = React.useState('')
  const [sorting, setSorting] = React.useState<SortingState>(
    DEFAULT_CREATIVE_LIST_SORTING
  )
  const [allowBlockFilterValue, setAllowBlockFilterValue] = React.useState<
    'all' | 'allowed' | 'blocked'
  >('all')

  const debouncedSearch = useDebounceValue(search, 500)

  const isExcluded = React.useMemo(() => {
    if (allowBlockFilterValue === 'allowed') {
      return 0
    }
    if (allowBlockFilterValue === 'blocked') {
      return 1
    }
    return undefined
  }, [allowBlockFilterValue])

  const callCreativeList = React.useMemo(() => {
    return Boolean(
      dateRange.endDate &&
        dateRange.startDate &&
        campaignId &&
        dimensionId &&
        typeof campaignBudget === 'number'
    )
  }, [dateRange, campaignId, dimensionId, campaignBudget])

  const { data, isLoading } = useCreativeDimensionList(
    {
      searchField: debouncedSearch,
      dimensionId: dimensionId as number,
      campaignId: campaignId as number,
      dateRange,
      timeZoneId: timezone?.id || 11, // 11 for the UTC
      sorting,
      isExcluded,
    },
    {
      enabled: callCreativeList,
      meta: { campaignBudget: campaignBudget },
    }
  )

  // Resetting the table state on change of the campaign
  React.useEffect(() => {
    setSorting(DEFAULT_CREATIVE_LIST_SORTING)
    setSearch('')
    setAllowBlockFilterValue('all')
    setVisibleSection('All')
  }, [campaignId])

  const [hasChange, setHasChange] = React.useState<boolean>(false)

  const onModellingChange = (hasChange: boolean) => {
    setHasChange(hasChange)
    setIsDirty(hasChange)
  }

  const onClickAway = (e: MouseEvent | TouchEvent) => {
    if (hasChange) {
      if (findParentWithId(e.target, 'discard_enable_field')) {
        e.stopImmediatePropagation()
        e.stopPropagation()
        e.preventDefault()
        creativeRef.current?.setShowDiscardModelling(true)
      }
    }
  }

  React.useEffect(() => {
    creativeRef.current?.setShowDiscardModelling(showDiscardDialog)
  }, [showDiscardDialog])

  const onDialogAction = (dialogState: boolean) => {
    toggleDiscardDialog(dialogState)
  }

  const setVisibleSectionWrapper = (newSection: 'All' | 'Modelled') => {
    setVisibleSection(newSection)
  }

  return (
    <ClickAwayListener
      disableReactTree
      enableCapturePhase
      onClickAway={onClickAway}
    >
      <Box sx={{ height: 100, mt: 24 }}>
        <CreativePage
          ref={creativeRef}
          key={campaignId}
          app="bidModel"
          campaignData={{
            campaignId: campaignId!,
            isBidShading: campaignDetails?.isBidShading as boolean,
            budgetDay: campaignDetails?.budgetDay as number,
            startTime: campaignDetails?.startTime as number,
            maxDayImpressions: campaignDetails?.maxDayImpressions as number,
            endTime: campaignDetails?.endTime as number,
            budgetTypeId: campaignDetails?.budgetTypeId as number,
            campaignStatus: campaignStatus,
          }}
          dimensionId={dimensionId}
          onModellingChange={onModellingChange}
          onDialogAction={onDialogAction}
          visibleSection={visibleSection}
          setVisibleSection={setVisibleSectionWrapper}
          allCreativesTableProps={{
            data: data,
            isFetching: isLoading,
            search: search,
            setSearch: setSearch,
            sorting: sorting,
            setSorting: setSorting,
            allowBlockFilterValue: allowBlockFilterValue,
            setAllowBlockFilterValue: setAllowBlockFilterValue,
            totalCount: allDimensionsCountData?.creative,
            dateRange: dateRangeFromStore,
            timezone: timezone,
          }}
          modelCreativesTableProps={{
            data: modelTableData,
            isFetching: isModelTableLoading,
            totalCount: modelledCreativesCount,
            dimensionId: dimensionId as number,
            dateRange: dateRangeFromStore,
            timezone: timezone,
          }}
        />
      </Box>
    </ClickAwayListener>
  )
}
