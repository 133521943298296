import { datadogRum } from 'iqm-framework'
import { getActionName } from '../../utils'

const actions = {
  editCampaign: {
    action: getActionName('editCampaign'),
    text: 'click on BidModel-Campaign-Edit',
  },
  viewCampaignDetailsSection: {
    action: getActionName('viewCampaignDetailsSection'),
    text: 'View Details > View Section',
  },
}

export const editCampaign = () => {
  datadogRum.addAction(actions.editCampaign.text, {
    action: actions.editCampaign.action,
  })
}

interface TViewCampaignDetailsParams {
  section: string
  source: 'sidebar' | 'block'
}

export const viewCampaignDetailsSection = (
  info: TViewCampaignDetailsParams
) => {
  datadogRum.addAction(`${actions.viewCampaignDetailsSection.text}`, {
    action: actions.viewCampaignDetailsSection.action,
    info,
  })
}
