import * as React from 'react'
import { AxiosError } from 'axios'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
} from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'
import { SortingState } from '@applift/datagrid'

import { WithResponse } from '../models/Response'
import { getListKey } from '../api/QueryKeys'
import { getExchangeList } from '../api/exchange'
import { downloadListFunc } from '../api/creative'

type List = WithResponse<any>

export const useExchangeList = (
  searchField: string,
  dimensionId: number,
  campaignId: string,
  pageSize = 10,
  dateRange: { startDate: string; endDate: string },
  timezoneId: number,
  sorting?: SortingState,
  isExcluded?: 0 | 1,
  noOfEntries = 50,
  options?: {
    onSuccess?: UseInfiniteQueryOptions<List>['onSuccess']
    onError?: UseInfiniteQueryOptions<List>['onError']
    enabled?: UseInfiniteQueryOptions<List>['enabled']
  }
): UseInfiniteQueryResult<List> => {
  const sortBy = React.useMemo(
    () => (sorting && sorting?.length > 0 ? sorting[0]?.id : ''),
    [sorting]
  )
  const order = React.useMemo(() => {
    if (sortBy) {
      return sorting && sorting?.length > 0 && sorting[0]?.desc ? 'DESC' : 'ASC'
    }
    return undefined
  }, [sorting, sortBy])
  const startDate = dateRange?.startDate
  const endDate = dateRange?.endDate
  const timeZoneId = timezoneId

  const queryData = useInfiniteQuery(
    getListKey.keys({
      scope: 'getExchangeList',
      pageSize,
      searchField,
      campaignId,
      endDate,
      startDate,
      order,
      sortBy,
      timeZoneId,
      isExcluded,
      noOfEntries,
      dimensionId,
    }),
    getExchangeList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce((prev, one) => {
          const datalength = one.data?.length
          if (datalength) {
            return prev + datalength
          }
          return prev
        }, 0)
        if (
          lastPage?.total?.totalCount !== undefined &&
          totalRecordsFetched < lastPage?.total?.totalCount
        ) {
          return pages.length + 1
        }
        return null
      },
      onSuccess: options?.onSuccess,
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      enabled: options?.enabled ?? true,
      cacheTime: 0,
    }
  )
  return queryData
}

export const useDownloadExchangeList = (options?: {
  onSuccess?: (res: any, payload: any) => void
  onError?: (props: any) => void
  onSettled?: (props: any) => void
}) => {
  const mutationResult = useMutation(downloadListFunc, {
    mutationKey: ['downloadExchangeList'],
    onSuccess: options?.onSuccess,
    onError: e => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      })
      if (options?.onError) {
        options.onError(e)
      }
    },
    onSettled: options?.onSettled,
  })

  return mutationResult
}
