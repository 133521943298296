import { Container } from '@applift/factor'

import { ExchangeGridWrapper } from './component/ExchangeGrid/ExchangeGridWrapper'

import styles from './styles.module.scss'

export const ExchangePage = () => {
  return (
    <Container className={styles.container}>
      <ExchangeGridWrapper />
    </Container>
  )
}
