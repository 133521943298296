import { StateCreator } from 'zustand'
import { StoreFilter } from '../../models/Store'
import { AppSlice } from '../../models/App'

export const createAppStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  AppSlice
> = set => ({
  app: {
    isSidebarOpen: true,
    toggleSidebarMenu: value =>
      set(
        prev => ({
          ...prev,
          app: {
            ...prev.app,
            isSidebarOpen:
              typeof value === 'boolean' ? value : !prev.app.isSidebarOpen,
          },
        }),
        false,
        'toggleSidebarMenu'
      ),
  },
})
