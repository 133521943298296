import * as React from 'react'
import { Col, Row, Button, Box } from '@applift/factor'
import {
  SelectIOCampaignProps,
  SelectIOCampaignList,
  SelectIOCampaignApiRefType,
} from '@applift/platform'

import { CampaignStatusDropDown } from '../../components/CampaignStatusDropDown'
import { CampaignDetails } from '../../components/CampaignDetails'
import { useStore } from '../../store'
import { DateRangeWrapper } from '../../components/DateRangeWrapper'
import { useCampaignDetails, useTimezone } from '../../hooks'
import { getCurrentView } from '../../routes'
import { CAMPAIGN_TYPE } from '../../constants/campaigns'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import { RUM_ACTION_ATTRIBUTE } from '../../services/RUMLogger/constants'
import { CampaignObject } from '../../models/Campaign'

export interface SubNavbarProps {
  initialCampaignData?: SelectIOCampaignProps['initialData']
}

export const SubNavbar = ({ initialCampaignData }: SubNavbarProps) => {
  const {
    filter: { setCampaignId, status, campaignId, setDateRange, setTimezone },
    modelling: { isDirty, toggleDiscardDialog },
  } = useStore()
  const { data: campaignDetails } = useCampaignDetails({
    id: campaignId,
  })
  const apiRef = React.useRef<SelectIOCampaignApiRefType>()
  const [showDetails, setShowDetails] = React.useState(false)
  const location = useLocation()
  const history = useHistory()

  const { data: timezoneData } = useTimezone()

  return (
    <>
      <Row xs={{ gutterSize: 8 }}>
        <Col xs={6}>
          <Row xs={{ gutterSize: 8 }}>
            <Col xs={4}>
              <CampaignStatusDropDown apiRef={apiRef} />
            </Col>
            <Col xs={5}>
              <SelectIOCampaignList
                useCase="basic"
                ref={apiRef}
                TextFieldProps={{ fullWidth: true }}
                params={{
                  campaignTypeIds: [CAMPAIGN_TYPE.ADVANCED],
                  status: [status],
                  sortBy: '-ioId,-campaignId',
                }}
                slotProps={{
                  PaperProps: {
                    style: { maxWidth: 500 },
                  },
                }}
                initialData={initialCampaignData}
                prepopulate
                onChange={(data, reason) => {
                  if (reason === 'apiAction') {
                    // to prevent infinite loop caused by apiRef.current?.setSelection(`${campaignId}`)
                    return
                  }
                  if (isDirty) {
                    toggleDiscardDialog(true)
                    apiRef.current?.setSelection(`${campaignId}`)
                    return
                  }
                  if (data) {
                    const result = Object.values(data)[0]!
                    const view = getCurrentView(location.pathname)
                    if (result) {
                      const campTimezone = timezoneData?.filter(one => {
                        return one.id === result.timezoneId
                      })[0]
                      setCampaignId(Number(result?.value))
                      if (campTimezone) {
                        setTimezone(campTimezone)
                      }
                      setDateRange({
                        start:
                          moment(result.startTime * 1000)
                            .tz(campTimezone?.value || 'UTC')
                            .startOf('day')
                            .unix() * 1000,
                        end:
                          moment
                            .tz(campTimezone?.value || 'UTC')
                            .endOf('day')
                            .unix() * 1000,
                      })
                      history.push(`${view}/${result.value}`)
                    } else {
                      setCampaignId(null)
                      history.push(`${view}/`)
                    }
                  } else {
                    setCampaignId(null)
                  }
                }}
              />
            </Col>
            {!!campaignId && (
              <Col xs={3}>
                {/* show only when campaign selected */}
                <Box id="discard_enable_field">
                  <Button
                    variant="text"
                    onClick={() => setShowDetails(true)}
                    discard-prone={true}
                    {...{
                      [RUM_ACTION_ATTRIBUTE]: 'Bid Model View Campaign Details',
                    }}
                  >
                    View Details
                  </Button>
                </Box>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Box id="discard_enable_field">
            <DateRangeWrapper campaignDetails={campaignDetails!} />
          </Box>
        </Col>
      </Row>
      <CampaignDetails
        campaignDetails={campaignDetails as CampaignObject}
        isDialogOpen={showDetails}
        setIsDialogOpen={setShowDetails}
      />
    </>
  )
}
