import { datadogRum } from 'iqm-framework'
import { upperFirst } from 'lodash'
import { getActionNamespace } from '../../utils'
import { OptimizationType } from '../../constants'

const getActionId = (id: string) =>
  `${getActionNamespace()}/advancedModelling/${id}`

interface AddOrRemoveModelling {
  campaignId: number | null
  ids: number[]
  dimension: OptimizationType
}

export const addToModelling = (params: AddOrRemoveModelling) => {
  const { campaignId, ids, dimension } = params

  datadogRum.addAction('Add to Modelling', {
    action: getActionId('addToModelling'),
    info: {
      campaignId,
      ids,
      dimension,
    },
  })
}

export const removeFromModelling = (params: AddOrRemoveModelling) => {
  const { campaignId, ids, dimension } = params

  datadogRum.addAction('Remove from Modelling', {
    action: getActionId('removeFromModelling'),
    info: {
      campaignId,
      ids,
      dimension,
    },
  })
}

interface SetModellingValue {
  campaignId: number | null
  ids: number[]
  value: number
  editType: 'inline' | 'bulk'
  dimension: OptimizationType
}

export const setPriority = (params: SetModellingValue) => {
  const { dimension, ...rest } = params

  datadogRum.addAction(`Set ${upperFirst(dimension)} Priority`, {
    action: getActionId('setPriority'),
    info: {
      ...rest,
      dimension,
    },
  })
}

export const setSpendRatio = (params: SetModellingValue) => {
  const { dimension, ...rest } = params

  datadogRum.addAction(`Set ${upperFirst(dimension)} Spend Ratio`, {
    action: getActionId('setSpendRatio'),
    info: {
      ...rest,
      dimension,
    },
  })
}

export const setBidModifier = (params: SetModellingValue) => {
  const { dimension, ...rest } = params

  datadogRum.addAction(`Set ${upperFirst(dimension)} Bid Modifier`, {
    action: getActionId('setBidModifier'),
    info: {
      ...rest,
      dimension,
    },
  })
}
