import * as React from 'react'
import { IQMRouter, ApiService, getOwId } from 'iqm-framework'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { User } from '../models/Auth'
import { createIqmInstance } from '../api/instance'
import { PageLayoutMain } from '../layout'
import { useStore } from '../store'
import { queryClient } from '../cache'
import { CssBaseline, ThemeProvider, SnackbarProvider } from '@applift/factor'
import { getLoggedInUserType } from '../utils'

export const BASE_INFO_NAME = 'authorizedUser'

export const App = () => {
  const [authorized, logout] = useStore(state => [
    state.auth.authorized,
    state.auth.logout,
  ])
  const [canContinue, setCanContinue] = React.useState(false)
  const [theme, setTheme] = React.useState('blue')
  const onSuccessLogin = async (data: User) => {
    authorized(data)
    createIqmInstance(data.apiToken)
    try {
      // This is a fix to get around localstorage limitation, here we defer rendering until we've setup fresh value into localstorage and redux
      // to avoid potential race condition that is occuring. If this fails we will continue with rendering since it should not be a show stopper
      const owID = getOwId()
      // @ts-ignore
      const profile = await ApiService.fetchUserProfile(data, owID)
      const userData = { ...data, ...profile }
      authorized(userData)
      localStorage.setItem(BASE_INFO_NAME, JSON.stringify(userData))
      setTimeout(() => {
        if (['SUPER'].indexOf(getLoggedInUserType()) >= 0) {
          setTheme('blue')
        } else {
          setTheme('green')
        }
        setCanContinue(true)
      })
    } catch (e) {
      setCanContinue(true)
    }
  }

  const onLogout = () => logout()

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider domRoot={document.body}>
        <CssBaseline />
        {/*@ts-ignore */}
        <ThemeProvider variant={theme} style={{ height: '100%' }}>
          <IQMRouter
            documentTitle="BidModel"
            successLoginRedirectPath="/bidmodel"
            onSuccessLogin={onSuccessLogin}
            onLogout={onLogout}
          >
            {canContinue ? <PageLayoutMain /> : null}
          </IQMRouter>
        </ThemeProvider>
      </SnackbarProvider>
      <ReactQueryDevtools
        initialIsOpen={false}
        panelPosition="right"
        position="top-right"
      />
    </QueryClientProvider>
  )
}
