import { SortingState } from '@applift/datagrid'
import { AxiosError, AxiosResponse } from 'axios'

import { WithRecordDimensionResponse, WithResponse } from '../models/Response'
import { getInstance } from './instance'

export interface DimensionsObject {
  name: string
  id: number
  label: string
  order: number
  modellingEnabled: boolean
}

export interface Dimensions extends DimensionsObject {
  subdimensions?: DimensionsObject[]
}

const transformDimensionData = (data: Dimensions[]) => {
  const keyIdPair: { [key: string]: number } = data.reduce(
    (acc, item) => {
      acc[item.name] = item.id
      if (item.subdimensions) {
        item.subdimensions.forEach(subItem => {
          acc[subItem.name] = subItem.id
        })
      }
      return acc
    },
    {} as { [key: string]: number }
  )
  return {
    dimensionData: data,
    keyIdPair,
  }
}

export const getDimensions = async () => {
  try {
    const response: AxiosResponse<WithRecordDimensionResponse<Dimensions[]>> =
      await getInstance().get('/v3/bm/static/dimensions')
    return transformDimensionData(response.data.data.dimensions)
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data?.error)
  }
}

export const downloadModelledDimensions = async (data: {
  campaignId: number
  dimensionId: number
  startDate?: number
  endDate?: number
  timezoneId?: number
  searchField?: string
  fileType: string
  sortBy?: string
  total_count?: number
  columns: { label: string; value: string }[]
  download?: boolean
  isExcluded?: 0 | 1
  calledApp?: string
}) => {
  const {
    campaignId,
    dimensionId,
    startDate,
    endDate,
    timezoneId,
    searchField,
    fileType,
    sortBy,
    total_count,
    columns,
    download,
    isExcluded,
    calledApp,
  } = data
  try {
    const response: AxiosResponse<WithResponse<{ url: string }>> =
      await getInstance().post(
        `/v3/bm/campaigns/${campaignId}/bid-models/dimensions/${dimensionId}`,
        {
          ...(startDate ? { startDate } : {}),
          ...(endDate ? { endDate } : {}),
          ...(timezoneId ? { timezoneId } : {}),
          ...(startDate ? { startDate } : {}),
          ...(searchField ? { searchField } : {}),
          ...(sortBy ? { sortBy } : {}),
          total_count,
          fileType,
          columns,
          calledApp: calledApp ?? 'bidModel',
          download,
          ...(typeof isExcluded === 'number' ? { isExcluded } : {}),
        }
      )

    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export interface ModelledListPayload {
  searchField?: string
  campaignId: number | string
  dateRange?: {
    endDate?: number
    startDate?: number
  }
  sorting?: SortingState
  timeZoneId?: number
  pageSize?: number
  isExcluded?: number
  dimensionId: number
  skipReporting?: boolean
  pageNo?: number
}

export const getModelledList = async (queryParams: ModelledListPayload) => {
  const {
    searchField,
    campaignId,
    dateRange,
    sorting,
    timeZoneId,
    pageSize,
    isExcluded,
    dimensionId,
    skipReporting,
    pageNo,
  } = queryParams || {}
  try {
    const sortBy = sorting && sorting?.length > 0 ? sorting[0]?.id : ''

    const params = {
      ...(dateRange?.startDate ? { startDate: dateRange.startDate } : {}),
      ...(dateRange?.endDate ? { endDate: dateRange.endDate } : {}),
      ...(timeZoneId ? { timeZoneId: timeZoneId } : {}),
      ...(searchField ? { searchField } : {}),
      ...(sortBy && (sorting?.length ?? 0) > 0
        ? { sortBy: `${sorting?.[0]?.desc ? '-' : '+'}${sortBy}` }
        : {}),
      ...(typeof pageSize === 'number' ? { pageSize } : {}),
      ...(pageNo ? { pageNo } : {}),
      ...(typeof isExcluded === 'number'
        ? { isExcluded: String(isExcluded) }
        : {}),
      ...(typeof skipReporting === 'boolean' ? { skipReporting } : {}),
    }

    const response: AxiosResponse<WithResponse<any>> = await getInstance().post(
      `/v3/bm/campaigns/${campaignId}/bid-models/dimensions/${dimensionId}`,
      params
    )
    return response
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}

export interface AllDimensionListPayload {
  searchField?: string
  campaignId: number | string
  dateRange?: {
    endDate?: number
    startDate?: number
  }
  pageSize?: number
  sorting?: SortingState
  timeZoneId: number
  pageNo?: number
  isExcluded?: number
  dimensionId: number
  skipReporting?: boolean
}

export const getAllDimensionList = async (
  queryParams: AllDimensionListPayload
) => {
  const {
    searchField,
    campaignId,
    dateRange,
    sorting,
    timeZoneId,
    pageNo,
    pageSize,
    isExcluded,
    dimensionId,
    skipReporting,
  } = queryParams || {}
  try {
    const sortBy = sorting && sorting?.length > 0 ? sorting[0]?.id : ''
    const params = {
      ...(pageSize ? { pageSize: String(pageNo) } : {}),
      ...(dateRange?.startDate
        ? { startDate: String(dateRange.startDate) }
        : {}),
      ...(dateRange?.endDate ? { endDate: String(dateRange.endDate) } : {}),
      ...(timeZoneId ? { timeZoneId: String(timeZoneId) } : {}),
      ...(searchField ? { searchField } : {}),
      ...(sortBy && (sorting?.length ?? 0) > 0
        ? { sortBy: `${sorting?.[0]?.desc ? '-' : '+'}${sortBy}` }
        : {}),
      ...(pageNo ? { pageNo: String(pageNo) } : {}),
    }

    const paramString = new URLSearchParams(params).toString()
    const response: AxiosResponse<WithResponse<any>> = await getInstance().post(
      `/v3/bm/campaigns/${campaignId}/reports/${dimensionId}?${paramString}`,
      {
        ...(typeof isExcluded === 'number'
          ? { isExcluded: String(isExcluded) }
          : {}),
        ...(typeof skipReporting === 'boolean' ? { skipReporting } : {}),
      }
    )
    return response
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}
