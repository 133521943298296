import { capitalize } from 'lodash'
import { ColumnDef } from '@applift/datagrid'
import { Box } from '@applift/factor'
import { CheckCircle } from '@applift/icons'

import {
  PercentCell,
  CurrencyCell,
  CreativePreviewCell,
  HourCell,
  FormattedNumberCell,
  CreativeDetailCell,
  RawDataCell,
  ImageCell,
} from '../../../../components/CellTypes'
import { CreativeListActionCell, CreativeNameCell } from '../CellTypes'
import { CampaignObject } from '../../../../models/Campaign'
import { VIDEO_CREATIVE_ID } from '../../../../constants/creative'
import { CreativeList } from '../../../../models/Creative'
import { StatusIcon } from '../../../../components/StatusIcon/index'
import { AllowBlockTooltip } from '../../../../components/AllowBlockTooltip'

export const colDef: ColumnDef<CreativeList>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'ID',
    enableResizing: false,
    meta: {
      description: 'Creative ID',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props =>
      // @ts-ignore
      props.table.options.meta?.currentApp === 'bidModel' ? (
        <CreativeDetailCell data={props.row.original} />
      ) : (
        <RawDataCell
          value={props.row.original.id.toString()}
          textTypographyProps={{
            sx: {
              textColor: props.row.original.isExcluded
                ? 'danger-500'
                : 'neutral-1000',
            },
          }}
        />
      ),
    size: 80,
  },
  {
    accessorKey: 'creativeThumbnailSource',
    id: 'creativeThumbnailSource',
    header: 'Preview',
    size: 150,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description: 'Creative Preview Thumbnail',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props =>
      // @ts-ignore
      !props.table.options.meta?.disableActions &&
      // @ts-ignore
      props.table.options.meta?.currentApp === 'bidModel' ? (
        <CreativePreviewCell data={props.row.original} />
      ) : (
        <ImageCell src={props.row.original.creativeThumbnailSource} />
      ),
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Creative Name',
    enableResizing: false,
    meta: {
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => (
      <>
        <CreativeNameCell
          creativeType={props.row.original.creativeType}
          textTypographyProps={{
            sx: {
              textColor: props.row.original.isExcluded
                ? 'danger-500'
                : 'neutral-1000',
            },
          }}
          label={props.row.original.name}
        />
      </>
    ),
    size: 300,
  },
  {
    accessorKey: 'creativeStatus',
    id: 'creativeStatus',
    header: 'Status',
    size: 150,
    enableSorting: false,
    enableResizing: false,
    meta: {
      description: 'Status',
      excludeColumnFromColumnVisibility: true,
    },
    cell: props => {
      const status =
        props.row.original.creativeStatus?.toLowerCase() === 'approved'
          ? 'Ready'
          : capitalize(props.row.original.creativeStatus)
      return status.length ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 4,
            gap: 2,
          }}
        >
          <StatusIcon status={status} iconProps={{ sx: { fontSize: 16 } }} />
          <RawDataCell
            value={status}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        </Box>
      ) : (
        <>-</>
      )
    },
  },
  {
    accessorKey: 'modelled',
    id: 'modelled',
    header: 'Modelling',
    size: 150,
    enableSorting: true,
    enableResizing: false,
    meta: {
      description:
        'The modelling column represent the status of the modelling on a creative.',
      excludeColumnFromColumnVisibility: false,
    },
    cell: props => {
      const isModelled = props.row.original.modelled
      return isModelled ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 4,
            gap: 2,
          }}
        >
          <CheckCircle fontSize={24} color="success" />
          <RawDataCell
            value="Added"
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        </Box>
      ) : (
        <>—</>
      )
    },
  },
  //
  {
    id: 'performance',
    header: 'Performance',
    columns: [
      {
        accessorKey: 'impressions',
        id: 'impressions',
        header: 'Impressions',
        enableResizing: false,
        meta: {
          align: 'right',
          description: "Number of times ad displayed on someone's screen",
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 130,
      },
      {
        accessorKey: 'clicks',
        id: 'clicks',
        header: 'Clicks',
        enableResizing: false,
        meta: {
          align: 'right',
          description: 'Number of times someone clicks your ad',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
      },
      {
        accessorKey: 'winRate',
        id: 'winRate',
        header: 'Win Rate',
        enableResizing: false,
        meta: {
          headerTitle: 'Win Rate(%)',
          align: 'right',
          description: 'Percentage of Impressions won per the Bids made',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
      },
      {
        accessorKey: 'CTR',
        id: 'CTR',
        header: 'CTR',
        meta: {
          align: 'right',
          headerTitle: 'CTR(%)',
          description:
            'Click-through rate is the percentage of Clicks received per the Impressions served',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'spentDistribution',
        id: 'spentDistribution',
        header: 'Spent Distribution(%)',
        meta: {
          align: 'right',
          description: 'Spent Distribution(%)',
        },
        cell: props => (
          <PercentCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <PercentCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'cost',
    header: 'Cost',
    columns: [
      {
        accessorKey: 'eCPM',
        id: 'eCPM',
        header: 'eCPM',
        meta: {
          align: 'right',
          headerTitle: 'eCPM($)',
          description:
            'Effective Cost Per Mile is the amount you pay per one thousand Impressions',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'eCPC',
        id: 'eCPC',
        header: 'eCPC',
        meta: {
          align: 'right',
          headerTitle: 'eCPC($)',
          description:
            'Effective Cost Per Click is the amount spent divided by the number of clicks',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'spending',
    header: 'Spending',
    columns: [
      {
        accessorKey: 'mediaSpent',
        id: 'mediaSpent',
        header: 'Media Cost',
        meta: {
          align: 'right',
          headerTitle: 'Media Cost($)',
          description: 'Amount spent to win Impressions',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'dataCost',
        id: 'dataCost',
        header: 'Data Cost',
        meta: {
          align: 'right',
          headerTitle: 'Data Cost($)',
          description:
            'Amount spent to serve Impressions to Audience you matched or purchased',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'prebidCost',
        id: 'prebidCost',
        header: 'Pre-bid Cost',
        meta: {
          align: 'right',
          headerTitle: 'Pre-bid Cost($)',
          description: 'Pre-bid Cost',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'spent',
        id: 'spent',
        header: 'Total Spent',
        meta: {
          align: 'right',
          headerTitle: 'Total Cost($)',
          description:
            'Total spent is the amount spent by the campaign(s) for the selected timeframe, that includes the media cost, data cost and pre-bid cost',
        },
        cell: props => (
          <CurrencyCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <CurrencyCell value={props.renderValue()} isFooterCell />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
  {
    id: 'conversions',
    header: 'Conversions',
    columns: [
      {
        accessorKey: 'conversionValue',
        id: 'conversionValue',
        header: 'Total Attributed Conversion Value',
        meta: {
          align: 'right',
          description: 'Total Attributed Conversion Value',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'conversionCount',
        id: 'conversionCount',
        header: 'Total Attributed Conversions',
        meta: {
          align: 'right',
          description: 'Total Attributed Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'returnOnAdSpend',
        id: 'returnOnAdSpend',
        header: 'Return On Ad Spend',
        meta: {
          align: 'right',
          description: 'Return On Ad Spend',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedClickThroughConversions',
        id: 'attributedClickThroughConversions',
        header: 'Attributed Click Through Conversions',
        meta: {
          align: 'right',
          description: 'Attributed Click Through Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedViewThroughConversions',
        id: 'attributedViewThroughConversions',
        header: 'Attributed View Through Conversions',
        meta: {
          align: 'right',
          description: 'Attributed View Through Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'costPerAttributedConversions',
        id: 'costPerAttributedConversions',
        header: 'Cost Per Attributed Conversions',
        meta: {
          align: 'right',
          description: 'Cost Per Attributed Conversions',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
      {
        accessorKey: 'attributedConversionsRate',
        id: 'attributedConversionsRate',
        header: 'Attributed Conversions Rate',
        meta: {
          align: 'right',
          description: 'Attributed Conversions Rate',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <FormattedNumberCell value={props.renderValue()} isFooterCell />
        ),
        size: 200,
        maxSize: 300,
        minSize: 150,
      },
    ],
  },
  {
    accessorKey: 'Allowlist/Blocklist',
    id: 'Allowlist/Blocklist',
    meta: {
      description: <AllowBlockTooltip listType="creatives" />,
      excludeColumnFromColumnVisibility: true,
      // @ts-ignore
      excludeColumnFromDownload: true,
    },
    cell: props => {
      return (
        <CreativeListActionCell
          action={props.onAction}
          rowData={props.row?.original}
          meta={props.table.options.meta}
        />
      )
    },
    size: 140,
    enableResizing: false,
    enableSorting: false,
  },
]

export const videoColDef: ColumnDef<any>[] = [
  {
    id: 'video',
    header: 'Video',
    columns: [
      {
        accessorKey: 'startCount',
        id: 'startCount',
        header: 'Video Start',
        meta: {
          align: 'right',
          description: 'Number of video ads that start to play',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'firstCount',
        id: 'firstCount',
        header: 'Video 25%',
        meta: {
          align: 'right',
          description:
            'Number of video ads that play through 25% of their duration',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        enableResizing: false,
        size: 100,
      },
      {
        accessorKey: 'midCount',
        id: 'midCount',
        header: 'Video 50%',
        meta: {
          align: 'right',
          description:
            'Number of video ads that play through 50% of their duration',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'thirdCount',
        id: 'thirdCount',
        header: 'Video 75%',
        meta: {
          align: 'right',
          description:
            'Number of video ads that play through 75% of their duration',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        size: 100,
        enableResizing: false,
      },
      {
        accessorKey: 'completeCount',
        id: 'completeCount',
        header: 'Video 100%',
        meta: {
          align: 'right',
          description: 'Number of video ads that play through to completion',
        },
        cell: props => (
          <FormattedNumberCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          <FormattedNumberCell
            isFooterCell
            // @ts-ignore
            value={props?.renderValue() as number}
          />
        ),
        size: 110,
        enableResizing: false,
      },
      {
        accessorKey: 'audioVideoViewed',
        id: 'audioVideoViewed',
        header: 'Hours Viewed',
        meta: {
          align: 'right',
          description: 'Hours Viewed',
        },
        cell: props => (
          <HourCell
            value={props.renderValue()}
            textTypographyProps={{
              sx: {
                textColor: props.row.original.isExcluded
                  ? 'danger-500'
                  : 'neutral-1000',
              },
            }}
          />
        ),
        footer: props => (
          // @ts-ignore
          <HourCell isFooterCell value={props?.renderValue() as number} />
        ),
        size: 100,
        enableResizing: false,
      },
    ],
  },
]

export const videoPerformanceColDef: ColumnDef<CreativeList>[] = [
  {
    accessorKey: 'VCR',
    id: 'VCR',
    header: 'VCR(%)',
    meta: {
      align: 'right',
      description:
        'Video Completion Rate is the percentage of video ads that play through to completion',
    },
    cell: (props: {
      renderValue: () => number
      row: { original: { isExcluded: boolean } }
    }) => (
      <PercentCell
        value={props.renderValue()}
        textTypographyProps={{
          sx: {
            textColor: props.row.original.isExcluded
              ? 'danger-500'
              : 'neutral-1000',
          },
        }}
      />
    ),
    footer: props => <PercentCell value={props?.renderValue?.() as number} />,
    size: 100,
    enableResizing: false,
  },
]

export const addVideoColDef = <T,>(
  creativeType: CampaignObject['creativeType'],
  columnDefinition: ColumnDef<T>[]
) => {
  if (creativeType === VIDEO_CREATIVE_ID) {
    const vDef = columnDefinition.map(def => {
      if (def.id === 'performance') {
        const newDef = JSON.parse(JSON.stringify(def))
        // @ts-ignore
        newDef.columns = [...def.columns, ...videoPerformanceColDef]
        return newDef
      }
      return def
    })
    return [...vDef, ...videoColDef]
  }
  return columnDefinition
}
