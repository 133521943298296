import * as React from 'react'
import {
  Row,
  Col,
  TextField,
  IconButton,
  Button,
  Chip,
  Tooltip,
  Box,
} from '@applift/factor'
import { Search, Close, Add } from '@applift/icons'
import {
  RowSelectionState,
  ColumnVisibility,
  VisibilityState,
  SortingState,
  ColumnDef,
  GridActionCellParams,
} from '@applift/datagrid'

import { useDownloadCreativeList } from '../../../../hooks'
import { AllowBlockFilter } from '../../../../components/AllowBlockFilter'
import { CreativeList } from '../../../../models/Creative'
import { ACTION } from './action'
import { DateRange } from '../../../../models/Filter'
import { Timezone } from '../../../../models/Timezone'
import { DownloadTableAction } from '../../../../components/DownloadTableAction'
import { RUMLogger } from '../../../../services/RUMLogger'
import { OptimizationType } from '../../../../services/RUMLogger/constants'

interface ActionPanelProps {
  colDef: ColumnDef<CreativeList>[]
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  selectedItems: CreativeList[]
  isTableLoading: boolean
  columnVisibility: VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  allowBlockFilterValue: 'all' | 'allowed' | 'blocked'
  setAllowBlockFilterValue: React.Dispatch<
    React.SetStateAction<'all' | 'allowed' | 'blocked'>
  >
  defaultData: [any[], VisibilityState]
  sorting: SortingState
  defaultDeselectedColumns: VisibilityState
  totalRecords: number
  onAction: (params: GridActionCellParams<any, any>) => void
  loadingIncludeExclude: string[]
  dimensionId: number
  modelledCreativeCount?: number
  validCampaign: boolean
  budgetTypeId: number
  dateRange?: DateRange | null
  timezone?: Timezone | null
  campaignId: number | null
  showColumnSelector: boolean
  currentApp: 'bidModel' | 'campaign'
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    colDef,
    search,
    setSearch,
    rowSelection,
    selectedItems,
    setRowSelection,
    isTableLoading,
    columnVisibility,
    setColumnVisibility,
    defaultData,
    sorting,
    defaultDeselectedColumns,
    allowBlockFilterValue,
    setAllowBlockFilterValue,
    totalRecords,
    onAction,
    loadingIncludeExclude,
    dimensionId,
    modelledCreativeCount,
    validCampaign,
    budgetTypeId,
    campaignId: selectedCampaignId,
    dateRange: selectedDateRange,
    timezone: selectedTimezone,
    showColumnSelector,
    currentApp,
  } = props

  const [currentDownloadFileType, setCurrentDownloadFileType] = React.useState<
    'xlsx' | 'csv' | null
  >(null)

  const selectedItemsCount = React.useMemo(
    () => Object.keys(rowSelection).length,
    [rowSelection]
  )

  const chipLabel = React.useMemo(() => {
    return `${selectedItemsCount} out of ${totalRecords} selected`
  }, [selectedItemsCount, totalRecords])

  const isOnlyExcludedSelected = React.useMemo(
    () => selectedItems.every(item => item.isExcluded === 1),
    [selectedItems]
  )

  const isOnlyIncludedSelected = React.useMemo(
    () => selectedItems.every(item => item.isExcluded === 0),
    [selectedItems]
  )

  const isDeletedCreativeSelected = React.useMemo(() => {
    return selectedItems.some(data => data.creativeStatus === 'Deleted')
  }, [selectedItems])

  const isRejectedCreativeSelected = React.useMemo(() => {
    return selectedItems.some(data => data.creativeStatus === 'Rejected')
  }, [selectedItems])

  const blockBtnTooltipMsg = React.useMemo(() => {
    return ''
  }, [])

  const unblockButtonTooltip = React.useMemo(() => {
    return ''
  }, [])

  const setColumnVisibilityWrapper = (value: any) => {
    setColumnVisibility(value)
  }

  const addToModelling = () => {
    const ids = Object.keys(rowSelection).map(id => Number.parseInt(id, 10))

    if (selectedCampaignId) {
      RUMLogger.AdvancedModelling.addToModelling({
        ids,
        campaignId: selectedCampaignId,
        dimension: OptimizationType.CREATIVE,
      })
    }

    onAction?.({
      actionName: ACTION.ADD_TO_MODELLING,
      metaData: {
        id: ids.join(','),
        params: {
          campaignId: selectedCampaignId,
        },
        data: selectedItems,
      },
    })
  }

  const nonModeledSelectedRow = React.useMemo(
    () => selectedItems.filter(row => !row.modelled).length,
    [selectedItems]
  )

  const onlyModelledCreativeSelected = React.useMemo(
    () => selectedItems.length && selectedItems.every(row => row.modelled),
    [selectedItems]
  )

  const modelledReachedLimit = React.useMemo(() => {
    const modelledCount = modelledCreativeCount || 0
    return modelledCount + nonModeledSelectedRow > 100
  }, [modelledCreativeCount, nonModeledSelectedRow])

  const isAddToModelllingDisabled = React.useMemo(() => {
    if (!validCampaign && modelledCreativeCount === 0) {
      return true
    }
    return Boolean(
      !isOnlyIncludedSelected ||
        isDeletedCreativeSelected ||
        isRejectedCreativeSelected ||
        modelledReachedLimit ||
        onlyModelledCreativeSelected
    )
  }, [
    isOnlyIncludedSelected,
    isDeletedCreativeSelected,
    isRejectedCreativeSelected,
    modelledReachedLimit,
    validCampaign,
    modelledCreativeCount,
    onlyModelledCreativeSelected,
  ])

  const addToModellingBtnTooltipMsg = React.useMemo(() => {
    if (!validCampaign && modelledCreativeCount === 0) {
      return budgetTypeId === 1
        ? 'Advanced modelling is only available for  campaigns with a daily budget exceeding $100 and a duration of at least 3 days.'
        : 'Advanced modelling is only available for campaigns with daily impressions exceeding 1000 and a duration of at least 3 days.'
    }
    if (
      isDeletedCreativeSelected ||
      isRejectedCreativeSelected ||
      !isOnlyIncludedSelected
    ) {
      return `Dimensions from Blocklist or Rejected / Deleted status can't be modelled`
    }
    if (modelledReachedLimit) {
      return 'The campaign has reached the maximum limit of 100 model terms'
    }
    return ''
  }, [
    isOnlyIncludedSelected,
    isDeletedCreativeSelected,
    isRejectedCreativeSelected,
    modelledReachedLimit,
    validCampaign,
    budgetTypeId,
    modelledCreativeCount,
  ])

  const includeExclude = (isExcluded: 0 | 1) => {
    const ids = Object.keys(rowSelection).join(',')
    onAction?.({
      actionName: ACTION.TOGGLE_INCLUDE_EXCLUDE_BULK,
      metaData: {
        id: ids,
        params: {
          campaignId: selectedCampaignId,
          ids: ids,
          isExcluded: isExcluded,
        },
      },
    })
  }

  const onDownloadSuccess = (res: any) => {
    const downloadUrl = res.data?.url
    if (downloadUrl) {
      window.open(downloadUrl)
    }
  }

  const onDownloadSettled = () => {
    setCurrentDownloadFileType(null)
  }

  const downloadMutation = useDownloadCreativeList({
    onSuccess: onDownloadSuccess,
    onSettled: onDownloadSettled,
  })

  const colList: { label: string; value: string }[] = React.useMemo(() => {
    const list: { label: string; value: string }[] = []
    colDef.forEach(data => {
      // @ts-ignore
      if (data?.columns?.length) {
        // @ts-ignore
        data?.columns?.forEach((row: any) => {
          const title =
            typeof row.header === 'string' ? row.header : row.meta.headerTitle
          if (!row?.meta?.excludeColumnFromDownload) {
            // @ts-ignore
            list.push({ label: title, value: row.accessorKey })
          }
        })
      } else {
        // @ts-ignore
        if (!data?.meta?.excludeColumnFromDownload) {
          // @ts-ignore
          list.push({ label: data.header, value: data.accessorKey })
        }
      }
    })
    return list
  }, [colDef])

  const column = React.useMemo(() => {
    return colList.filter(col => {
      if (
        typeof columnVisibility[col?.value] === 'boolean' &&
        columnVisibility[col?.value]
      ) {
        return true
      }
      if (
        Object.prototype.hasOwnProperty.call(
          defaultDeselectedColumns,
          col?.value
        )
      ) {
        return false
      }
      return true
    })
  }, [columnVisibility, colList, defaultDeselectedColumns])

  const downloadfile = (fileType: 'xlsx' | 'csv') => {
    downloadMutation.mutate({
      campaignId: selectedCampaignId as number,
      startDate: selectedDateRange?.start as number,
      endDate: selectedDateRange?.end as number,
      timezoneId: selectedTimezone?.id as number,
      sortBy: `${sorting[0]?.desc ? '-' : '+'}${sorting[0]?.id}`,
      searchField: search,
      total_count: totalRecords,
      fileType,
      columns: column,
      download: true,
      calledApp: currentApp,
      dimensionId,
      ...(allowBlockFilterValue !== 'all'
        ? { isExcluded: allowBlockFilterValue === 'allowed' ? 0 : 1 }
        : {}),
    })
  }

  return (
    <>
      <Row
        xs={{ gutterSize: 0, gutterDirection: 'x' }}
        sx={{ px: 24, alignItems: 'center' }}
      >
        {selectedItemsCount > 0 && !isTableLoading && (
          <Col xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              size="small"
              label={chipLabel}
              color="secondary"
              onDelete={() => {
                setRowSelection({})
              }}
            />
            {
              <Tooltip
                arrow
                placement="top"
                title={addToModellingBtnTooltipMsg}
              >
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={isAddToModelllingDisabled}
                    startIcon={<Add />}
                    sx={{ marginLeft: 8 }}
                    onClick={() => {
                      addToModelling()
                    }}
                  >
                    Add to Modelling
                  </Button>
                </Box>
              </Tooltip>
            }
            {isOnlyIncludedSelected && (
              <Tooltip arrow placement="top" title={blockBtnTooltipMsg}>
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={Boolean(loadingIncludeExclude.length)}
                    sx={{ marginLeft: 8 }}
                    onClick={() => {
                      includeExclude(1)
                    }}
                  >
                    Block
                  </Button>
                </Box>
              </Tooltip>
            )}
            {isOnlyExcludedSelected && (
              <Tooltip arrow placement="top" title={unblockButtonTooltip}>
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ marginLeft: 8 }}
                    onClick={() => {
                      includeExclude(0)
                    }}
                    disabled={Boolean(loadingIncludeExclude.length)}
                  >
                    Unblock
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Col>
        )}
        <Col
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            ml: 'auto',
            my: 16,
          }}
        >
          <TextField
            type="text"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value)
            }}
            placeholder="Search by ID, Name"
            variant="outlinedDash"
            sx={{ ml: 8 }}
            InputProps={{
              startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
              endAdornment:
                search?.length > 0 ? (
                  <IconButton
                    onClick={() => {
                      setSearch('')
                    }}
                    size="small"
                  >
                    <Close fontSize={16} />
                  </IconButton>
                ) : null,
            }}
          />
          <DownloadTableAction
            downloadfile={downloadfile}
            isDownloading={downloadMutation.isLoading}
            currentDownloadFileType={currentDownloadFileType}
            setCurrentDownloadFileType={setCurrentDownloadFileType}
          />
          <AllowBlockFilter
            selectedValue={allowBlockFilterValue}
            setSelectedValue={setAllowBlockFilterValue}
          />
          {showColumnSelector && (
            <ColumnVisibility
              data={defaultData[0]}
              onChange={setColumnVisibilityWrapper}
              value={columnVisibility}
              slotProps={{
                SelectAdvancePopoverProps: {
                  slotProps: {
                    PopoverProps: {
                      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                      transformOrigin: { vertical: 'top', horizontal: 'right' },
                    },
                  },
                },
                IconButtonProps: { color: 'secondary' },
              }}
            />
          )}
        </Col>
      </Row>
    </>
  )
}
