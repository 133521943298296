import { useMutation, useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { WithResponse } from '../models/Response'
import { downloadModelledDimensions, getDimensions } from '../api/dimensions'

export const getDimensionsKey = {
  keys: (args: { scope: string }) => [args],
}

export const useDimensions = () => {
  const data = useQuery(
    getDimensionsKey.keys({ scope: 'getDimensions' }),
    getDimensions,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e?.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      keepPreviousData: true,
      cacheTime: 5000,
    }
  )
  return data
}

export const useDownloadModelledDimensionList = (options?: {
  onSuccess?: (res: any, payload: any) => void
  onError?: (props: any) => void
  onSettled?: (props: any) => void
}) => {
  const mutationResult = useMutation(downloadModelledDimensions, {
    mutationKey: ['downloadModelledDimensionList'],
    onSuccess: options?.onSuccess,
    onError: e => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      })
      if (options?.onError) {
        options.onError(e)
      }
    },
    onSettled: options?.onSettled,
  })

  return mutationResult
}
