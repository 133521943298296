import { QueryFunctionContext } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '../models/Response'
import { getInstance } from './instance'
import { getCreativeInfoKey } from './QueryKeys'
import {
  CreativeInfo,
  CreativeListResponse,
  IncludeExcludeRequestData,
  IncludeExcludeSuccessResponse,
  IncludeExcludeErrorResponse,
  CreativeModelledListResponse,
  CreativeList,
  BidModelData,
  CreativeSpentRequest,
  CreativeSpentResponse,
  CreativeCtaDataType,
} from '../models/Creative'
import { getModelledList, getAllDimensionList } from './dimensions'
import { transformCreativeInfoData } from '../utils/transform'

export const includeExcludeFunc = (requestData: IncludeExcludeRequestData) => {
  const { campaignId, ids, isExcluded, dimensionId } = requestData
  const apiUrl = `/v3/bm/campaigns/${campaignId}/include-exclude/dimensions/${dimensionId}`

  const func = async (): Promise<
    IncludeExcludeSuccessResponse | IncludeExcludeErrorResponse
  > => {
    try {
      const response: AxiosResponse<any> = await getInstance().post(apiUrl, {
        ids,
        isExcluded,
      })
      return {
        ...response.data,
        message: response.data?.data,
        axiosResponse: response,
      }
    } catch (e) {
      return Promise.reject({ ...(e as AxiosError), axiosError: e })
    }
  }

  // Necessary for RUM logging
  func.apiUrl = apiUrl
  return func
}

export const downloadListFunc = async (data: {
  campaignId: number
  dimensionId: number
  startDate: number
  endDate: number
  timezoneId: number
  searchField: string
  fileType: string
  sortBy: string
  total_count: number
  columns: { label: string; value: string }[]
  download: boolean
  isExcluded?: 0 | 1
  calledApp?: string
}) => {
  const {
    campaignId,
    dimensionId,
    startDate,
    endDate,
    timezoneId,
    searchField,
    fileType,
    sortBy,
    total_count,
    columns,
    download,
    isExcluded,
    calledApp,
  } = data

  try {
    const response: AxiosResponse<WithResponse<{ url: string }>> =
      await getInstance().post(
        `/v3/bm/campaigns/${campaignId}/reports/${dimensionId}?${
          startDate ? `startDate=${startDate}&` : ''
        }${endDate ? `endDate=${endDate}&` : ''}${
          timezoneId ? `timeZoneId=${timezoneId}&` : ''
        }
       searchField=${searchField}&sortBy=${sortBy}`,
        {
          total_count,
          fileType,
          columns,
          calledApp,
          download,
          ...(typeof isExcluded === 'number' ? { isExcluded } : {}),
        }
      )

    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getCreativeInfo = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getCreativeInfoKey)['keys']>>) => {
  const { creativeId } = queryKey[0]!
  try {
    const creativeDetailResponse = await getInstance().get(
      `/v3/crt/creatives/${creativeId}`
    )

    const creativeCtaListResponse = await getInstance().get(
      '/v3/crt/master/cta/list'
    )

    const [creativeDetails, creativeCtaList]: [
      AxiosResponse<WithResponse<CreativeInfo>>,
      AxiosResponse<WithResponse<{ ctaDetailsList: CreativeCtaDataType[] }>>,
    ] = await Promise.all([creativeDetailResponse, creativeCtaListResponse])

    return transformCreativeInfoData(
      creativeDetails?.data?.data,
      creativeCtaList?.data?.data?.ctaDetailsList
    )
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response || e)
  }
}

interface CreativeModelledListAPIResponse {
  data: (CreativeList & { bidModelData?: BidModelData })[]
  total: CreativeList
  filteredRecords: number
  totalRecords: number
  campaignBidDetails: {
    maxBid: number
    baseBid: number
  }
}

export const getCreativeSpent = async (requestData: CreativeSpentRequest) => {
  const { campaignId, dimensionId } = requestData
  try {
    const response: AxiosResponse<WithResponse<CreativeSpentResponse>> =
      await getInstance().get(
        `/v3/bm/campaigns/${campaignId}/dimension/${dimensionId}/spent`
      )
    return response?.data?.data?.entityWiseSpent || {}
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data.errorObjects[0])
  }
}
export const getModelledCreativesList = async ({
  queryKey,
  meta,
}: QueryFunctionContext<ReturnType<any>>) => {
  try {
    const response: AxiosResponse<
      WithResponse<CreativeModelledListAPIResponse>
    > = await getModelledList(queryKey[0])

    const { campaignId } = queryKey[0]

    const spentData = await getCreativeSpent({
      campaignId: campaignId,
      dimensionId: 1,
    })

    const campaignBudget = meta?.campaignBudget as number

    const modelData = {
      ...response.data.data,
      data: response.data.data?.data.length
        ? response.data.data?.data.map(item => {
            const spent = spentData[`${item.id}`]

            const updatedItem = {
              ...item.bidModelData,
              bidModelDataId: item.bidModelData?.id,
              spentPercentage:
                campaignBudget && spent
                  ? Math.ceil((spent / campaignBudget) * 100)
                  : 0,
              ...item,
            }
            if (spent) {
              delete spentData[`${item.id}`]
            }
            delete updatedItem.bidModelData
            return updatedItem
          })
        : response.data.data?.data,
      total: {
        ...response.data?.data?.total,
        // TODO: fix this total count  after backend discussion
        totalCount: response.data.data?.data.length || 0,
      },
    } as CreativeModelledListResponse

    const otherSpent = Object.values(spentData).reduce(
      (acc, curr) => acc + curr,
      0
    )
    return {
      ...modelData,
      data: modelData.data.map(row => {
        if (row.id === -1) {
          return {
            ...row,
            spentPercentage:
              campaignBudget && otherSpent
                ? Math.ceil((otherSpent / campaignBudget) * 100)
                : 0,
          }
        }
        return row
      }),
    }
  } catch (e) {
    return Promise.reject(e as AxiosError)
  }
}

export const getAllCreativeDimensionList = async ({
  queryKey,
  meta,
}: QueryFunctionContext<ReturnType<any>>) => {
  try {
    const response: AxiosResponse<WithResponse<CreativeListResponse>> =
      await getAllDimensionList(queryKey[0])

    const { campaignId } = queryKey[0]

    const spentData = await getCreativeSpent({
      campaignId: campaignId,
      dimensionId: 1,
    })

    const campaignBudget = meta?.campaignBudget as number

    return {
      ...response.data.data,
      data: response.data.data?.data.map(data => {
        const spent = spentData[`${data.id}`]
        return {
          ...data,
          spentPercentage:
            campaignBudget && spent
              ? Math.ceil((spent / campaignBudget) * 100)
              : 0,
        }
      }) as CreativeList[],
      total: response.data.data?.total as CreativeList,
    }
  } catch (e) {
    return Promise.reject(e as AxiosError)
  }
}

const UpdateAction = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  EXCLUDE_DIMENSION_ENTITY: 'EXCLUDE_DIMENSION_ENTITY',
} as const

interface BidModelDataInterface {
  priority?: number | null
  bidMultiplier?: number
  spendRatio?: number
  spendRatioTypeId?: number
  id?: number
}

interface DimensionEntity {
  dimensionId: number
  entityId: number
}

export interface IBidModelRequest {
  action: keyof typeof UpdateAction
  bidModelData?: BidModelDataInterface
  dimensionEntityMappings?: DimensionEntity[]
  bidModelDataIds?: number[]
}
export interface ExcludeDimensionEntityRequest {
  dimensionId: number
  ids: string
  isExcluded: number
}

export interface IUpdateRequest {
  baseBid?: number
  bidModelRequests?: IBidModelRequest[]
  excludeDimensionEntityMappings?: ExcludeDimensionEntityRequest[]
}

export const updateCreativeModelling = async (req: {
  campaignId: number
  params: IUpdateRequest
}) => {
  try {
    const { campaignId, params } = req
    const response: AxiosResponse<any> = await getInstance().put(
      `/v3/bm/campaigns/${campaignId}/bid-models`,
      params
    )
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
