import {
  ExcludeDimensionEntityRequest,
  IBidModelRequest,
  IUpdateRequest,
} from '../api/creative'
import {
  DEFAULT_BID_DATA_FLEX,
  DEFAULT_BID_DATA_OTHER_FLEX,
  SPENT_RATIO_METHOD,
} from '../constants/modelledTable'
import {
  CreativeModelledList,
  CreativeModelledListResponse,
} from '../models/Creative'

export function getRowFromData<T extends { id: number }>(
  data: T[],
  id: number
) {
  const row = data?.filter(row => row.id === id)
  if (row?.length) {
    return row[0]
  }
  return false
}

export function findObjectDiff<T>(obj1: T, obj2: T) {
  const diffKeys = []

  for (const key in obj1) {
    if (Object.hasOwnProperty.call(obj1, key)) {
      if (obj1[key] !== obj2[key]) {
        diffKeys.push(key)
      }
    }
  }

  return diffKeys
}

const ALLOWED_UPDATE_FIELDS = [
  'priority',
  'spendRatio',
  'spendRatioTypeId',
  'bidMultiplier',
  'isExcluded',
]

export const getUpdateParam = (
  initData: CreativeModelledListResponse,
  currState: CreativeModelledListResponse
): IUpdateRequest => {
  const bidModelRequests: IBidModelRequest[] = []

  let excludeDimensionEntityMappings: ExcludeDimensionEntityRequest[] = []
  const includedListUpdate: number[] = []
  const excludedListUpdate: number[] = []

  // need to compare current and the initial state
  const initRowIds = initData?.data?.map?.(row => row.id)
  const currentRowIds = currState?.data?.map(row => row.id)

  // check for adding new row
  const newAddedRow: CreativeModelledList[] = []

  currState?.data?.forEach(row => {
    if (!initRowIds?.includes(row.id)) {
      newAddedRow.push(row)
    }
  })
  if (newAddedRow.length) {
    newAddedRow.forEach(row => {
      const bidModelData = {
        priority:
          row.spendRatioTypeId === SPENT_RATIO_METHOD.FLEXIBLE
            ? row.priority
            : null,
        bidMultiplier: row.bidMultiplier,
        spendRatio: row.spendRatio,
        spendRatioTypeId: row.spendRatioTypeId,
      }
      bidModelRequests.push({
        action: 'ADD',
        dimensionEntityMappings: [{ dimensionId: 1, entityId: row.id }],
        bidModelData: bidModelData,
      })
      if (row.isExcluded === 1) {
        excludedListUpdate.push(row.id)
      }
    })
  }
  // check for removed row
  const removedRow = initData?.data?.filter(
    row => !currentRowIds?.includes(row.id)
  )
  if (removedRow?.length) {
    const removedRowIds = removedRow.map(row => row.bidModelDataId as number)

    bidModelRequests.push({
      action: 'DELETE',
      bidModelDataIds: removedRowIds,
    })
  }

  // check for updated value
  currState?.data?.forEach(row => {
    const originalRow = getRowFromData(
      initData?.data as CreativeModelledList[],
      row.id
    )
    if (originalRow) {
      const diffkey = findObjectDiff(row, originalRow)
      const filteredDiffkey = diffkey.filter(key =>
        ALLOWED_UPDATE_FIELDS.includes(key)
      )
      if (filteredDiffkey.includes('isExcluded') && row.isExcluded === 0) {
        includedListUpdate.push(row.id)
      }
      if (filteredDiffkey.includes('isExcluded') && row.isExcluded === 1) {
        excludedListUpdate.push(row.id)
      }
      const updatedModelData = {}

      filteredDiffkey.forEach(key => {
        if (key !== 'isExcluded') {
          // @ts-ignore
          updatedModelData[key] = row[key]
        }
      })
      if (Object.keys(updatedModelData).length) {
        bidModelRequests.push({
          action: 'UPDATE',
          bidModelData: {
            id: row.bidModelDataId || originalRow.bidImpressions,
            priority:
              row.spendRatioTypeId === SPENT_RATIO_METHOD.FLEXIBLE
                ? row.priority
                : null,
            bidMultiplier: row.bidMultiplier,
            spendRatio: row.spendRatio,
            spendRatioTypeId: row.spendRatioTypeId,
          },
        })
      }
    }
  })

  // request for including excluding
  if (includedListUpdate.length || excludedListUpdate.length) {
    excludeDimensionEntityMappings = [
      ...(includedListUpdate.length
        ? [
            {
              dimensionId: 1,
              isExcluded: 0,
              ids: includedListUpdate.join(','),
            },
          ]
        : []),
      ...(excludedListUpdate.length
        ? [
            {
              dimensionId: 1,
              isExcluded: 1,
              ids: excludedListUpdate.join(','),
            },
          ]
        : []),
    ]
    // })
  }

  let updatedBaseBid = undefined
  if (
    initData?.campaignBidDetails?.baseBid !==
    currState?.campaignBidDetails?.baseBid
  ) {
    updatedBaseBid = currState?.campaignBidDetails?.baseBid
  }

  return {
    ...(bidModelRequests.length ? { bidModelRequests: bidModelRequests } : {}),
    ...(updatedBaseBid ? { baseBid: updatedBaseBid } : {}),
    ...(excludeDimensionEntityMappings.length
      ? { excludeDimensionEntityMappings: excludeDimensionEntityMappings }
      : {}),
  }
}

export const getResetParam = (ModelledData: CreativeModelledListResponse) => {
  const request: IBidModelRequest[] = []

  ModelledData?.data?.forEach(row => {
    if (row.id === -1) {
      request.push({
        action: 'UPDATE',
        bidModelData: {
          id: row.bidModelDataId,
          ...DEFAULT_BID_DATA_OTHER_FLEX,
        },
      })
    } else {
      request.push({
        action: 'UPDATE',
        bidModelData: {
          id: row.bidModelDataId,
          ...DEFAULT_BID_DATA_FLEX,
        },
      })
    }
  })
  return request
}
