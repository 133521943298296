import * as React from 'react'
import { Row, Col, sx } from '@applift/factor'
import {
  DataGrid,
  RowSelectionState,
  ColumnPinningState,
  VisibilityState,
  GridActionCellParams,
  getSortedRowModel,
} from '@applift/datagrid'
import { SearchData, SelectObject } from '@applift/illustrations'
import { ClipboardRemove } from '@applift/icons'

import { NoResultsOverlay } from '../../../../components/GridOverLay'
import { CAMPAIGN_STATUSES } from '../../../../constants/campaigns'
import { CreativeModelledListResponse } from '../../../../models/Creative'
import { SPENT_RATIO_METHOD } from '../../../../constants/modelledTable'
import { RUMLogger } from '../../../../services/RUMLogger'
import { OptimizationType } from '../../../../services/RUMLogger/constants'

export interface ModelledCreativeGridProps {
  apiRef: any
  data: any[]
  columnDef: any[]
  rowIdKey: string
  pageSize: number
  overlay?: 'error' | 'noRows' | 'noResult' | undefined
  footerData: any
  loading: boolean
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  onFetchRows?: () => void
  columnVisibility: VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  defaultDeselectedColumns: VisibilityState
  onAction: (params: GridActionCellParams<any, any>) => void
  loadingIncludeExclude: string[]
  setModifiedModelledData?: React.Dispatch<
    React.SetStateAction<CreativeModelledListResponse>
  >
  isViewOnly?: boolean
  campaignStatus: string
  currentApp: 'bidModel' | 'campaign' | 'customers'
  baseBidInputRef?: React.RefObject<HTMLInputElement>
  baseBidPrice?: number | null
  maxBid?: number
  campaignId: number | null
}

export const ModelledCreativeGrid = (props: ModelledCreativeGridProps) => {
  const {
    apiRef,
    data,
    columnDef,
    rowIdKey,
    pageSize,
    footerData,
    loading,
    rowSelection,
    overlay,
    setRowSelection,
    columnVisibility,
    defaultDeselectedColumns,
    setColumnVisibility,
    onAction,
    loadingIncludeExclude,
    setModifiedModelledData,
    isViewOnly = false,
    campaignStatus,
    currentApp,
    baseBidPrice,
    baseBidInputRef,
    campaignId,
  } = props

  const [columnPinning] = React.useState<ColumnPinningState>(
    isViewOnly
      ? {}
      : {
          left: ['__check__', 'id'],
          right: ['Allowlist/Blocklist'],
        }
  )

  const [columnOrder] = React.useState<string[]>([
    '__check__',
    'id',
    'creativeThumbnailSource',
    'name',
    'creativeStatus',
    'priority',
    'spendRatio',
    'spendRatioTypeId',
    'bidMultiplier',
    'impressions',
    'clicks',
    'winRate',
    'CTR',
    'spentDistribution',
    'VCR',
    'eCPM',
    'eCPC',
    'mediaSpent',
    'dataCost',
    'prebidCost',
    'spent',
    'conversionValue',
    'conversionCount',
    'returnOnAdSpend',
    'attributedClickThroughConversions',
    'attributedViewThroughConversions',
    'costPerAttributedConversions',
    'attributedConversionsRate',
    'startCount',
    'firstCount',
    'midCount',
    'thirdCount',
    'completeCount',
    'audioVideoViewed',
  ])

  const setRowSelectionWrapper = React.useCallback(
    (_value: any) => {
      let value = _value
      if (typeof _value === 'function') {
        value = _value()
      }
      setRowSelection({ ...value })
    },

    [setRowSelection]
  )
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    apiRef.current.subscribeEvent('viewportInnerSizeChange', (params: any) => {
      setHeight(params.height)
    })
  }, [apiRef])

  const isCampaignExpiredOrDeleted = [
    CAMPAIGN_STATUSES.EXPIRED,
    CAMPAIGN_STATUSES.DELETED,
  ].includes(campaignStatus)

  return (
    <>
      <Row sx={{ flexGrow: 1 }}>
        <Col xs={12}>
          <DataGrid
            onCellClick={params => {
              // enabling edit mode on single click
              if (params.cellMode === 'view') {
                apiRef.current.startCellEditMode({
                  rowId: params.rowId,
                  field: params.field,
                })
              }
            }}
            onCellEditStart={(props, event) => {
              if (props.field === 'bidMultiplier' && !baseBidPrice) {
                baseBidInputRef?.current?.focus()
                event.defaultFactorPrevented = true
              }
            }}
            apiRef={apiRef}
            processRowUpdate={async (newRow, oldRow) => {
              if (newRow.priority !== oldRow.priority) {
                RUMLogger.AdvancedModelling.setPriority({
                  campaignId,
                  ids: [newRow.id],
                  value: newRow.priority,
                  editType: 'inline',
                  dimension: OptimizationType.CREATIVE,
                })
              }

              if (newRow.spendRatio !== oldRow.spendRatio) {
                RUMLogger.AdvancedModelling.setSpendRatio({
                  campaignId,
                  ids: [newRow.id],
                  value: newRow.spendRatio,
                  editType: 'inline',
                  dimension: OptimizationType.CREATIVE,
                })
              }

              if (newRow.bidMultiplier !== oldRow.bidMultiplier) {
                RUMLogger.AdvancedModelling.setBidModifier({
                  campaignId,
                  ids: [newRow.id],
                  value: newRow.bidMultiplier,
                  editType: 'inline',
                  dimension: OptimizationType.CREATIVE,
                })
              }

              setModifiedModelledData?.(prev => ({
                ...prev,
                total: prev.total,
                data: prev.data.map(item =>
                  item.id === newRow.id ? newRow : item
                ),
              }))
              return newRow
            }}
            isCellEditable={({ rowId, field }) => {
              const row = apiRef.current.tableInstance.getRow(rowId).original
              return !(
                isViewOnly ||
                isCampaignExpiredOrDeleted ||
                ['rejected', 'deleted'].includes(
                  row.creativeStatus?.toLowerCase()
                ) ||
                (field === 'priority' &&
                  row.spendRatioTypeId === SPENT_RATIO_METHOD.STRICT)
              )
            }}
            footerPlacement={data?.length ? 'top' : undefined}
            footerData={{
              ...footerData,
              tableData: data,
              isViewOnly: isViewOnly || isCampaignExpiredOrDeleted,
            }}
            data={data}
            columns={columnDef}
            getRowId={row => {
              if (row && typeof row[rowIdKey] === 'number') {
                return `${row[rowIdKey]}`
              }
              return row[rowIdKey]
            }}
            state={{
              rowSelection,
              columnOrder,
              columnPinning,
              columnVisibility: {
                ...defaultDeselectedColumns,
                ...columnVisibility,
              },
            }}
            pageSize={pageSize}
            hideHeader
            hideFooter
            rowHeight={80}
            checkboxSelection={!isCampaignExpiredOrDeleted && !isViewOnly}
            overscrollBehaviorX="contain"
            overlay={overlay}
            meta={{
              loadingIncludeExclude: loadingIncludeExclude,
              disableActions: isCampaignExpiredOrDeleted || isViewOnly,
              currentApp,
              isModelledTab: true,
              campaignId,
            }}
            components={{
              NoResultsOverlay,
              NoRowsOverlay: NoResultsOverlay,
              ErrorOverlay: NoResultsOverlay,
            }}
            componentsProps={{
              noResultsOverlay: {
                text: 'No results found',
                subText: 'We can’t find any items matching your search.',
                illustration:
                  height <= 250 ? (
                    <ClipboardRemove
                      sx={{ textColor: 'neutral-400', mb: 8 }}
                      fontSize={36}
                    />
                  ) : (
                    <SearchData
                      sx={{
                        textColor: 'primary-500',
                        width: height <= 400 ? 50 : 100,
                        height: 'auto',
                        mb: height <= 400 ? 24 : 40,
                      }}
                    />
                  ),
              },
              // @ts-ignore
              noRowsOverlay: {
                // @ts-ignore
                text: 'Select creatives to start modelling',
                illustration: (
                  <SelectObject
                    sx={{
                      textColor: 'primary-500',
                      width: height <= 400 ? 50 : 100,
                      height: 'auto',
                      mb: height <= 400 ? 24 : 40,
                    }}
                  />
                ),
              },
              // @ts-ignore
              errorOverlay: {
                // @ts-ignore
                text: 'Select creatives to start modelling',
                subText: '',
                illustration: (
                  <SelectObject
                    sx={{
                      textColor: 'primary-500',
                      width: height <= 400 ? 50 : 100,
                      height: 'auto',
                      mb: height <= 400 ? 24 : 40,
                    }}
                  />
                ),
              },
            }}
            showColumnRightBorder
            showCellRightBorder
            disableRowSelectionOnClick
            rowCount={data.length}
            classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
            loading={loading}
            onAction={onAction}
            onRowSelectionChange={setRowSelectionWrapper}
            onColumnVisibilityChange={setColumnVisibility}
            getSortedRowModel={getSortedRowModel()}
          />
        </Col>
      </Row>
    </>
  )
}
