import { enqueueSnackbar } from '@applift/factor'

export const toInitCap = (value: string) =>
  !!value && `${value[0]?.toLocaleUpperCase()}${value.slice(1)}`

export const downloadBlob = async (blob: any, fileName: any) => {
  const link = window.document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFile = async (fileUrl: any, fileName: any) => {
  try {
    const file = await fetch(fileUrl)
    const blob = await file.blob()
    downloadBlob(blob, fileName)
  } catch (e) {
    console.error('error :', e)
  }
}

export const isObject = (object: any) => {
  return object != null && typeof object === 'object'
}

export const copyText = (text: string, successText: string) => {
  navigator.clipboard.writeText(text).then(() => {
    enqueueSnackbar(successText, {
      variant: 'success',
    })
  })
}

export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  )

export const findParentWithId = (elem, idToFound: string) => {
  if (!elem) {
    return false
  }
  let parent = elem.parentElement
  while (parent) {
    const id = parent.getAttribute('id')
    if (id === idToFound) {
      return true
    }
    parent = parent.parentElement
  }
  return false
}
