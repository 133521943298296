import React from 'react'
import { Col, Paper, Row } from '@applift/factor'
import {
  GridActionCellParams,
  RowSelectionState,
  VisibilityState,
  constructListFromColumnDef,
  useGridApiRef,
} from '@applift/datagrid'

import { useCampaignDetails } from '../../../../hooks'
import { DEFAULT_PAGE_SIZE } from '../../../../constants/creative'
import { ModelledCreativeGrid } from './ModelledCreativeGrid'
import {
  modelledCreativesColDef,
  addVideoColDef,
} from './modelledCreativesColDef'
import { ActionPanel } from './ActionPanel'
import {
  BidModelData,
  CreativeList,
  CreativeModelledList,
  CreativeModelledListResponse,
} from '../../../../models/Creative'
import { ACTION, ActionNameType } from './action'
import {
  BidModifierDialog,
  PriorityDialog,
  SpendRatioDialog,
} from '../../../../components/Dialogs'
import {
  DEFAULT_MODELLING_COL_VISIBILITY,
  SPENT_RATIO_METHOD,
} from '../../../../constants/modelledTable'
import { SpendRatioSwitchConfirmationDialog } from '../../../../components/Dialogs/index'
import { DateRange } from '../../../../models/Filter'
import { Timezone } from '../../../../models/Timezone'
import { CAMPAIGN_STATUSES } from '../../../../constants/campaigns'
import { OptimizationType } from '../../../../services/RUMLogger/constants'
import { RUMLogger } from '../../../../services/RUMLogger'

interface ModelledCreativeGridWrapperProps {
  isFetching: boolean
  campaignId: number | null
  dimensionId: number
  isViewOnly?: boolean
  columnsToDisplay?: string[]
  modifiedModelledData: CreativeModelledListResponse
  setModifiedModelledData?: React.Dispatch<
    React.SetStateAction<CreativeModelledListResponse>
  >
  campaignStatus: string
  dateRange?: DateRange | null
  timezone?: Timezone | null
  currentApp: 'bidModel' | 'campaign' | 'customers'
}

const SR_SWITCH_CONFIRMATION = 'doNotShowSpendRatioSwitchConfirmation'

export const ModelledCreativeGridWrapper = (
  props: ModelledCreativeGridWrapperProps
) => {
  const {
    isFetching,
    campaignId,
    dimensionId,
    columnsToDisplay,
    isViewOnly = false,
    modifiedModelledData,
    setModifiedModelledData,
    campaignStatus,
    dateRange,
    timezone,
    currentApp,
  } = props

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [dialogToShow, setDialogToShow] = React.useState<
    null | 'bidModifier' | 'spendRatio' | 'priority'
  >(null)
  const [search, setSearch] = React.useState('')
  const [allowBlockFilterValue, setAllowBlockFilterValue] = React.useState<
    'all' | 'allowed' | 'blocked'
  >('all')
  const [showConfirmationDialog, toggleShowConfirmationDialog] =
    React.useState<boolean>(false)

  const baseBidInputRef = React.useRef<HTMLInputElement>(null)

  const actionRef = React.useRef<GridActionCellParams<
    | 'TOGGLE_INCLUDE_EXCLUDE_INLINE'
    | 'TOGGLE_INCLUDE_EXCLUDE_BULK'
    | 'TOGGLE_SPEND_RATIO_METHOD'
    | 'REMOVE_MODELLING'
    | 'ADD_TO_MODELLING',
    {
      data?: CreativeList | undefined
      id?: string | undefined
      params?: any
      spendRatioType?: string | undefined
    }
  > | null>(null)

  const { data: campaignDetails } = useCampaignDetails({
    id: campaignId,
  })

  const pendingOrDraftCampaignColumns = React.useMemo(
    () => [
      'id',
      'creativeThumbnailSource',
      'name',
      'creativeStatus',
      'priority',
      'spendRatio',
      'spendRatioTypeId',
      'bidMultiplier',
      'Allowlist/Blocklist',
    ],
    []
  )

  const columnDefinition = React.useMemo(() => {
    const columnsToDisplayInEditMode = [
      CAMPAIGN_STATUSES.DRAFT,
      CAMPAIGN_STATUSES.PENDING,
      CAMPAIGN_STATUSES.REJECTED,
    ].includes(campaignDetails?.status?.toLowerCase() ?? '')
      ? modelledCreativesColDef.filter(column =>
          pendingOrDraftCampaignColumns.includes(column.id as string)
        )
      : addVideoColDef(
          Number(campaignDetails?.creativeType),
          modelledCreativesColDef
        )
    return columnsToDisplay
      ? modelledCreativesColDef.filter(column =>
          columnsToDisplay.includes(column.id as string)
        )
      : columnsToDisplayInEditMode
  }, [columnsToDisplay, campaignDetails, pendingOrDraftCampaignColumns])

  const defaultData = React.useMemo(
    () => constructListFromColumnDef(columnDefinition),
    [columnDefinition]
  )

  const [columnVisibility, setColumnVisibility] = React.useState(
    DEFAULT_MODELLING_COL_VISIBILITY as VisibilityState
  )
  const defaultDeselectedColumns = React.useState(() =>
    Object.keys(defaultData[1]).reduce((prev, one) => {
      // eslint-disable-next-line
      prev[one] = false
      return prev
    }, {} as VisibilityState)
  )[0]

  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    { data?: CreativeList; id?: string; params?: any; spendRatioType?: string }
  > | null>(null)

  const updateModifiedModelledData = (
    idsToUpdate: number[],
    data: number,
    keyToUpdate:
      | 'bidMultiplier'
      | 'spendRatio'
      | 'priority'
      | 'spendRatioTypeId'
      | 'isExcluded',
    isIncExcUpdate?: boolean,
    updateAll?: boolean,
    isSRMethodSwitch?: boolean
  ) => {
    if (isSRMethodSwitch) {
      const newSRMethod =
        SPENT_RATIO_METHOD.FLEXIBLE === data ? 'Flexible' : 'Strict'

      const newModifierValues: Partial<BidModelData> = {}
      newModifierValues.priority = newSRMethod === 'Flexible' ? 1 : 0
      newModifierValues.spendRatioTypeId =
        newSRMethod === 'Flexible'
          ? SPENT_RATIO_METHOD.FLEXIBLE
          : SPENT_RATIO_METHOD.STRICT

      const othersRowNewModifierValue: Partial<BidModelData> =
        newSRMethod === 'Flexible'
          ? { priority: 10, spendRatioTypeId: SPENT_RATIO_METHOD.FLEXIBLE }
          : { priority: 0, spendRatioTypeId: SPENT_RATIO_METHOD.STRICT }

      setModifiedModelledData?.(prev => ({
        ...prev,
        total: prev.total,
        data: prev.data.map(item => ({
          ...item,
          ...(item.id === -1 ? othersRowNewModifierValue : newModifierValues),
          spendRatio:
            newSRMethod === 'Flexible' ? 100 : item.spentPercentage ?? 0,
          bidMultiplier: 1.0,
        })),
      }))
      return
    }
    const updateEveryRecord =
      updateAll || idsToUpdate.length === modifiedModelledData?.data.length
    setModifiedModelledData?.(prev => ({
      ...prev,
      total: prev.total,
      data: prev.data.map(item => {
        if (idsToUpdate?.includes(item.id) || updateEveryRecord) {
          return {
            ...item,
            [keyToUpdate]: data,
            ...(isIncExcUpdate ? { isExcluded: data } : {}),
          }
        }
        return item
      }),
    }))
    if (dialogToShow) {
      setDialogToShow(null)
    }
    setRowSelection({})
  }

  const switchMethod = (donotAskSelected?: boolean) => {
    if (donotAskSelected) {
      localStorage.setItem(SR_SWITCH_CONFIRMATION, 'true')
    }
    toggleShowConfirmationDialog(false)
    const actionRowIds = actionRef.current?.metaData?.id
      ?.split(',')
      .map(id => Number(id))
    updateModifiedModelledData(
      actionRowIds as number[],
      Number(actionRef.current?.metaData?.spendRatioType),
      'spendRatioTypeId',
      false,
      true,
      true
    )
    actionRef.current = null
  }

  React.useEffect(() => {
    switch (action?.actionName) {
      case ACTION.TOGGLE_INCLUDE_EXCLUDE_INLINE:
      case ACTION.TOGGLE_INCLUDE_EXCLUDE_BULK: {
        const actionRowIds = action?.metaData?.id
          ?.split(',')
          .map(id => Number(id))
        if (actionRowIds?.length) {
          updateModifiedModelledData(
            actionRowIds as number[],
            Number(action?.metaData?.params.isExcluded),
            'isExcluded',
            true
          )
        }

        break
      }
      case ACTION.TOGGLE_SPEND_RATIO_METHOD: {
        actionRef.current = action
        if (
          localStorage.getItem(SR_SWITCH_CONFIRMATION) !== 'true' &&
          Number(action?.metaData?.spendRatioType) ===
            SPENT_RATIO_METHOD.STRICT &&
          currentApp === 'bidModel'
        ) {
          toggleShowConfirmationDialog(true)
        } else {
          switchMethod()
        }
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, currentApp])

  const totalObject = modifiedModelledData?.total

  const doesBelongToAppliedFilter = React.useCallback(
    (modelledItem: CreativeModelledList) => {
      if (allowBlockFilterValue === 'all') {
        return true
      }
      return allowBlockFilterValue === 'allowed'
        ? !modelledItem.isExcluded
        : modelledItem.isExcluded
    },
    [allowBlockFilterValue]
  )

  const filteredData = React.useMemo(() => {
    return {
      total: modifiedModelledData?.total,
      data: modifiedModelledData?.data.filter(
        item =>
          (item?.name
            ?.trim()
            ?.toLowerCase()
            ?.includes(search.trim().toLowerCase()) ||
            item.id.toString().includes(search)) &&
          doesBelongToAppliedFilter(item)
      ),
    }
  }, [modifiedModelledData, search, doesBelongToAppliedFilter])

  const flatData = React.useMemo(() => {
    return filteredData?.data || []
  }, [filteredData])

  const filteredRecords = React.useMemo(
    () => modifiedModelledData?.total.totalCount || 0,
    [modifiedModelledData]
  )

  const setSearchWrapper = (value = '') => {
    setRowSelection({})
    setSearch(value)
  }

  const selectedIds = Object.keys(rowSelection).map(id => Number(id))

  const selectedItems = React.useMemo(
    () =>
      flatData.length
        ? flatData.filter((data: { id: number }) =>
            selectedIds.includes(data?.id as number)
          )
        : [],
    [selectedIds, flatData]
  )

  const overlay = React.useMemo(() => {
    if (!campaignId) {
      return 'error'
    }
    if (!flatData?.length && !isFetching && search) {
      return 'noResult'
    }
    if (!flatData?.length && !isFetching) {
      return 'noRows'
    }
    return undefined
  }, [flatData?.length, isFetching, search, campaignId])

  const renderDialog = () => {
    switch (dialogToShow) {
      case 'bidModifier':
        return (
          <BidModifierDialog
            closeDialog={() => setDialogToShow(null)}
            onCompletion={data => {
              RUMLogger.AdvancedModelling.setBidModifier({
                campaignId,
                ids: selectedIds,
                value: data,
                editType: 'bulk',
                dimension: OptimizationType.CREATIVE,
              })

              updateModifiedModelledData(selectedIds, data, 'bidMultiplier')
            }}
            itemType="creatives"
            rumSegment={OptimizationType.CREATIVE}
          />
        )
      case 'priority':
        return (
          <PriorityDialog
            closeDialog={() => setDialogToShow(null)}
            onCompletion={data => {
              RUMLogger.AdvancedModelling.setPriority({
                campaignId,
                ids: selectedIds,
                value: data,
                editType: 'bulk',
                dimension: OptimizationType.CREATIVE,
              })

              updateModifiedModelledData(selectedIds, data, 'priority')
            }}
            itemType="creatives"
            rumSegment={OptimizationType.CREATIVE}
          />
        )
      case 'spendRatio':
        return (
          <SpendRatioDialog
            closeDialog={() => setDialogToShow(null)}
            onCompletion={data => {
              RUMLogger.AdvancedModelling.setSpendRatio({
                campaignId,
                ids: selectedIds,
                value: data,
                editType: 'bulk',
                dimension: OptimizationType.CREATIVE,
              })

              updateModifiedModelledData(selectedIds, data, 'spendRatio')
            }}
            itemType="creatives"
            rumSegment={OptimizationType.CREATIVE}
          />
        )
      default:
        return null
    }
  }

  const baseBidPrice = modifiedModelledData?.campaignBidDetails?.baseBid
  const maxBid = modifiedModelledData?.campaignBidDetails?.maxBid || 0

  const apiRef = useGridApiRef()

  return (
    <>
      <Row sx={{ height: 100 }}>
        <Col xs={12}>
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 100,
            }}
          >
            <ActionPanel
              apiRef={apiRef}
              currentApp={currentApp}
              colDef={columnDefinition}
              baseBidInputRef={baseBidInputRef}
              baseBidPrice={baseBidPrice}
              setDialogToShow={setDialogToShow}
              search={search}
              setSearch={setSearchWrapper}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              selectedItems={selectedItems as any[]}
              isTableLoading={isFetching}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              defaultData={defaultData}
              defaultDeselectedColumns={defaultDeselectedColumns}
              allowBlockFilterValue={allowBlockFilterValue}
              setAllowBlockFilterValue={setAllowBlockFilterValue}
              totalRecords={filteredRecords}
              onAction={setAction}
              loadingIncludeExclude={[]}
              dimensionId={dimensionId as number}
              setModifiedModelledData={setModifiedModelledData}
              isViewOnly={isViewOnly}
              modifiedModelledData={modifiedModelledData}
              selectedDateRange={dateRange}
              selectedTimezone={timezone}
              selectedCampaignId={campaignId}
              showColumnSelector={
                ![
                  CAMPAIGN_STATUSES.DRAFT,
                  CAMPAIGN_STATUSES.PENDING,
                  CAMPAIGN_STATUSES.REJECTED,
                ].includes(campaignStatus) && currentApp === 'bidModel'
              }
              maxBid={maxBid}
              campaignStatus={campaignStatus}
            />
            <ModelledCreativeGrid
              apiRef={apiRef}
              footerData={{
                ...totalObject,
              }}
              baseBidInputRef={baseBidInputRef}
              baseBidPrice={baseBidPrice}
              data={isFetching ? [] : flatData}
              columnDef={columnDefinition}
              rowIdKey="id"
              pageSize={DEFAULT_PAGE_SIZE}
              overlay={overlay}
              loading={isFetching}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              defaultDeselectedColumns={defaultDeselectedColumns}
              onAction={setAction}
              loadingIncludeExclude={[]}
              setModifiedModelledData={setModifiedModelledData}
              isViewOnly={isViewOnly}
              campaignStatus={campaignStatus}
              currentApp={currentApp}
              maxBid={maxBid}
              campaignId={campaignId}
            />
          </Paper>
        </Col>
      </Row>
      {renderDialog()}
      {showConfirmationDialog && (
        <SpendRatioSwitchConfirmationDialog
          onConfirmation={switchMethod}
          closeDialog={() => toggleShowConfirmationDialog(false)}
          dimensionName="creatives"
        />
      )}
    </>
  )
}
